import { Observable } from "rxjs";
import { Set, List, fromJS } from "immutable";
import fp from "lodash/fp";
import { takeWhileInclusive } from "./StreamUtils";
import DataListFilter from "./DataListFilter";

export const mapResponseStream = fp.curry((payloadMapper, stream) =>
  stream
    .catch(error => Observable.of({ error }))
    .map(
      fp.flow(
        fp.update("pending", Boolean),
        fp.update("progress", fp.toFinite),
        fp.update("payload", payloadMapper),
        fromJS,
      ),
    ),
);

export const mapArrayResponseStream = mapResponseStream(
  fp.flow(fp.get("data"), fp.toArray),
);

export const mapObjectResponseStream = mapResponseStream(
  fp.flow(fp.get("data"), fp.toPlainObject),
);

export const mapListResponseStream = mapResponseStream(
  fp.flow(
    fp.get("data"),
    fp.toPlainObject,
    fp.update("list", fp.toArray),
    fp.update("total", fp.toFinite),
  ),
);

export const loadAllValues = fp.curry((getList, baseFilter) =>
  Observable.of({
    page: 0,
    total: null,
    list: List(),
  })
    .expand(({ list, page }) =>
      Observable.defer(() => getList(baseFilter.setPage(page)))
        .takeLast(1)
        .let(mapListResponseStream)
        .map(x => ({
          page: page + 1,
          total: x.getIn(["payload", "total"]),
          list: list.concat(x.getIn(["payload", "list"])),
        })),
    )
    .let(
      takeWhileInclusive(x => !fp.isFinite(x.total) || x.total > x.list.size),
    )
    .map(fp.flow(fp.pick(["total", "list"]), fromJS)),
);

const splitByChunks = (elements, chunkSize: number) => {
  const chunks = [];
  let items = elements;

  while (items.size > chunkSize) {
    chunks.push(items.take(chunkSize));

    items = items.skip(chunkSize);
  }

  if (items.size > 0) {
    chunks.push(items);
  }

  return chunks;
};

const stringToSet = fp.flow(fp.split(","), fp.map(fp.trim), x => Set(x));

export const loadListWithLongSearchQuery = fp.curry(
  (getList, baseFilter: DataListFilter) =>
    Observable.from(
      splitByChunks(stringToSet(baseFilter.getSearch()), baseFilter.getSize()),
    ).concatMap((x: Set) =>
      getList(baseFilter.setSearch(x.join(","))).let(mapListResponseStream),
    ),
);
