import React from "react";
import _ from "lodash";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";

const TOP = "_top";
const SELF = "_self";
const BLANK = "_blank";
const PARENT = "_parent";

const enhancer = compose(
  useSheet({
    root: {
      display: "inline-block",
    },
  }),
);

ButtonForm.propTypes = {
  classes: PropTypes.object,

  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,

  className: PropTypes.string,
  target: PropTypes.oneOf([TOP, SELF, BLANK, PARENT]),
};

ButtonForm.defaultProps = {
  target: BLANK,
};

function ButtonForm(props) {
  const { classes } = props;
  const inputs = [];

  _.forEach(props.params, (item, idx) => {
    if (item) {
      inputs.push(<input type="hidden" name={idx} value={item} key={idx} />);
    }
  });

  return (
    <form
      method="post"
      action={props.url}
      target={props.target}
      className={cx(classes.root, props.className)}
    >
      {inputs}
      <Button primary={true} type="submit">
        {props.label}
      </Button>
    </form>
  );
}

export default enhancer(ButtonForm);
