import React from "react";
import { Map } from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MultiLineCell from "../data-list/MultiLineCell";
import { getMessages } from "../../reducers/LocalizationReducer";
import AvatarWithName from "../../../client/components/avatars/AvatarWithName";
import _ from "lodash";
import FlexBox from "../ui-core/FlexBox";

const enhancer = compose(connect((state) => ({ i18n: getMessages(state) })));

OrderListServiceType.propTypes = {
  order: PropTypes.object,
  i18n: PropTypes.instanceOf(Map),
};

function OrderListServiceType({ order, i18n }) {
  return (
    <FlexBox style={{ margin: "0 .5rem" }}>
      <AvatarWithName
        withNotification={true}
        name={_.get(order, "package.delivery_label", "-")}
        imagePath={_.get(order, "package.courier_type_icon")}
      >
        <MultiLineCell
          firstLine={
            <strong>{_.get(order, "package.delivery_label", "-")}</strong>
          }
          secondLine={i18n.get("with_notification")}
        />
      </AvatarWithName>
    </FlexBox>
  );
}

export default enhancer(OrderListServiceType);
