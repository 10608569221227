import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router";
import { ReportProblem } from "@material-ui/icons";
import TooltipOverlay from "../ui-core/TooltipOverlay";
import MultiLineCell from "../data-list/MultiLineCell";
import { isPickUpAddress } from "../../helpers/OrderHelper";
import { getIsRTL, getMessages } from "../../reducers/LocalizationReducer";
import AvatarWithName from "../../../client/components/avatars/AvatarWithName";
import IconPickUp from "./assets/pickup_icon.svg";
import IconDelivery from "./assets/delivery_icon.svg";
import IconInfoOutline from "./assets/ic_info_outline.svg";

const NA = "N/A";

const styles = {
  avatar: {
    position: "absolute",
    right: "0px",
    top: "21%",
    marginTop: "-6px",
  },
  avatar1: {
    position: "absolute",
    right: "6px",
    top: "52%",
    marginTop: "6px",
  },
};

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
    isRTL: getIsRTL(state),
  })),
  useSheet({
    root: { width: "300px", overflow: "hidden", display: "flex" },
    hoverable: {
      cursor: "pointer",
      textDecoration: "underline",
      width: "12px",
      height: "12px",
      marginRight: "9px",
    },
    avatar: {
      container: { paddingLeft: 0, paddingRight: "20px" },
      nameBox: { position: "relative" },
    },
    nav_icon: {
      position: "absolute",
      left: "0",
      bottom: "5px",
      marginLeft: "-20px",
    },
    address_icon: {
      position: "absolute",
      bottom: 8,
      left: 3,
      width: "12px",
      height: "12px",
      backgroundColor: "white",
      borderRadius: "6px",
    },

    multiLineClass: {
      root: { marginRight: "40px", maxWidth: "100%" },
      line: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      },
    },
  }),
);

OrderListAddress.propTypes = {
  classes: PropTypes.object,
  element: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  order: PropTypes.object,
  notificationHref: PropTypes.string,
  showPickUpAddress: PropTypes.bool,
  i18n: PropTypes.instanceOf(Map),
  isRTL: PropTypes.bool,
};

OrderListAddress.defaultProps = { element: "span" };

function OrderListAddress({ classes, order, i18n, ...props }) {
  const isPickup = isPickUpAddress(order);
  // const addressFromTo = getAddressByOrderStatus(order.get("status"));
  // const isBadAddress =
  //   order.getIn(["locations", 0, "bad_address"]) ||
  //   order.getIn(["locations", 1, "bad_address"]);

  const addressFromTo = props.showPickUpAddress ? 0 : 1;
  const isBadAddress = order.getIn(["locations", 1, "bad_address"]);
  const isUnknownNeighborhood = order.getIn([
    "locations",
    1,
    "unknown_neighborhood",
  ]);

  return (
    <div className={classes.root}>
      <AvatarWithName
        name={
          order.getIn(
            addressFromTo ? ["package", "from_city"] : ["package", "to_city"],
            order.getIn(["locations", addressFromTo, "address"]),
          ) || order.getIn(["locations", addressFromTo, "address_city"])
        }
        classes={classes.avatar}
      >
        <TooltipOverlay
          key="badAddress"
          label={
            addressFromTo ? i18n.get("to", "To") : i18n.get("from", "From")
          }
          style={{
            position: "absolute",
            left: !props.isRTL ? "0" : "auto",
            right: props.isRTL ? "0" : "auto",
            bottom: "9px",
            marginLeft: !props.isRTL ? "0" : "30px",
            marginRight: props.isRTL ? "0" : "30px",
            padding: "2px",
          }}
        >
          {addressFromTo ? (
            <img src={IconPickUp} className={classes.address_icon} alt="" />
          ) : (
            <img src={IconDelivery} className={classes.address_icon} alt="" />
          )}
          {/* <Avatar
            size={12}
            src={addressFromTo ? IconPickUp : IconDelivery}
            backgroundColor={white}
          /> */}
        </TooltipOverlay>

        <MultiLineCell
          style={{
            paddingRight: props.isRTL ? "0" : "40px",
            paddingLeft: !props.isRTL ? "0" : "40px",
          }}
          firstLine={
            <div>
              <strong>
                {order.getIn(["locations", addressFromTo, "contact_name"])} /{" "}
                {order.getIn(["locations", addressFromTo, "phone"])}
              </strong>
            </div>
          }
          secondLine={
            <div>
              {addressFromTo
                ? order.getIn(["package", "to_city"])
                : order.getIn(["package", "from_city"])}
            </div>
          }
          thirdLine={
            <TooltipOverlay
              label={order.getIn(["locations", addressFromTo, "address"]) || NA}
            >
              <span className={classes.hoverable}>
                {order.getIn(["locations", addressFromTo, "address"])}
              </span>
            </TooltipOverlay>
          }
        />
      </AvatarWithName>
      {isPickup > -1 && (
        <Link to={props.notificationHref}>
          <TooltipOverlay
            key="badAddress"
            label={i18n.get("address_changed", "Address Changed")}
            style={styles.avatar}
          >
            <img src={IconInfoOutline} className={classes.hoverable} alt="" />
            {/* <Avatar
              size={12}
              backgroundColor="transparent"
              color={danger1}
              src={IconInfoOutline}
              className={classes.hoverable}
            /> */}
          </TooltipOverlay>
        </Link>
      )}
      {Boolean(isBadAddress || isUnknownNeighborhood) && (
        <TooltipOverlay
          key="badAddress"
          label={i18n.get("bad_address", "Bad Address")}
          style={styles.avatar1}
        >
          <ReportProblem color="secondary" fontSize="small" />
        </TooltipOverlay>
      )}
    </div>
  );
}

export default enhancer(OrderListAddress);
