import React from "react";
// import fp from "lodash/fp";
import { compose, setDisplayName } from "recompose";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import Tabs from "./Tabs";

// const isLeftClickEvent = event =>
//   Boolean((event && event.button === 0) || fp.isUndefined(event.button));

// const isModifiedEvent = event =>
//   Boolean(
//     (event && event.metaKey) || event.altKey || event.ctrlKey || event.shiftKey,
//   );

// const preventLeftClick = event =>
//   isLeftClickEvent(event) && !isModifiedEvent(event) && event.preventDefault();

const enhancer = compose(withRouter, setDisplayName("NavTabs"));

NavTabs.propTypes = {
  routes: PropTypes.array,
  router: PropTypes.object,
  params: PropTypes.object,
  location: PropTypes.object,

  width: PropTypes.number,
  tabs: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

function NavTabs({ tabs, router, value, params, location, routes, ...props }) {
  return (
    <Tabs
      {...props}
      value={router.createHref(value)}
      tabs={tabs.map(({ to, value: tabValue, ...tabProps }) => ({
        ...tabProps,
        value: router.createHref(tabValue || to),
        path: to ? router.createHref(to) : router.createHref(tabValue),
      }))}
    />
  );
}

export default enhancer(NavTabs);
