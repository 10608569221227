import sprintf from "sprintf";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "./BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const CITY_ITEM_URL = `${API_ROOT_URL}/city/%s`;
const CITY_LIST_URL = `${API_ROOT_URL}/cities`;
const COUNTRY_LIST_URL = `${API_ROOT_URL}/country/list`;
const COUNTRY_FOR_SIGN_UP = `https://ipapi.co/json/`;

const COUNTRY_CENTER_URL = `${API_ROOT_URL}/admin/country/%s/center`;

export const getCountryCenter = id =>
  api.getStream(sprintf(COUNTRY_CENTER_URL, id));

const parseResponse = fp.flow(fp.get("data"), fp.toPlainObject, fromJS);
const parseArrayResponse = fp.flow(fp.get("data"), fp.toArray, fromJS);

const cityCache = new ObservableCache({
  loader: () => api.get(CITY_LIST_URL).then(parseResponse),
});

const cityCacheOrdered = new ObservableCache({
  loader: () => api.get(CITY_LIST_URL).then(parseArrayResponse),
});

const countryCache = new ObservableCache({
  loader: () => api.get(COUNTRY_LIST_URL).then(parseResponse),
});

const itemCache = new ObservableCache({
  loader: id =>
    api.get(sprintf(CITY_ITEM_URL, id)).then(fp.flow(fp.get("data"), fromJS)),
});

export const getCountries = () => countryCache.get();
export const getCities = (domestic = true) => cityCache.get(domestic);
export const getOrderedCities = (domestic = true) =>
  cityCacheOrdered.get(domestic);
export const getOrderedCities2 = () => api.get(CITY_LIST_URL);

export const getCachedCity = id => itemCache.get(id);
export const getCityPredictions = (request: DataListFilter) =>
  api.getStream(CITY_LIST_URL, { params: request.getDefinedValues() });
export const getCountrySignUp = () =>
  fetch(COUNTRY_FOR_SIGN_UP).then(data => data && data.json());
