import fp from "lodash/fp";
import DataListFilter from "./DataListFilter";
import {
  types,
  mapSchema,
  queryTypes,
} from "../../shared/helpers/ObjectMapper";

export const toCommunicationFilter = fp.compose(
  DataListFilter.create,
  mapSchema({
    eventType: queryTypes.stringSeq,
    eventTarget: queryTypes.stringSeq,
    courier_type: queryTypes.stringSeq,
    notification_warehouse_type: queryTypes.stringSeq,

    entityType: queryTypes.stringSeq,
    entityID: types.ID,
    toJurisdictionId: types.ID,

    last_mile_driver_id: types.ID,
    customer_id: types.ID,
    driver_id: types.ID,
    company_id: types.ID,
    supplier_id: types.ID,
    warehouse_id: types.ID,
    destination_warehouse_id: types.ID,
    from_city_id: types.ID,
    to_city_id: types.ID,

    include_exception: types.boolean,
    is_uae: types.boolean,
    include_dw: types.boolean,
    simple: types.boolean,
    requires_attention: types.boolean,
    scheduled_for_cancellation: types.boolean,
    auto_cancelled: types.boolean,

    eventCourierType: types.string,
    transportationType: types.string,
  }),
);
