import { Observable } from "rxjs";
import React from "react";
import { fromJS, List, Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream, withHandlers } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { FieldArray, formValues, reduxForm } from "redux-form";
import {
  Button,
  Card,
  CardContent,
  Divider,
  ListItem,
  ListItemText,
  ListSubheader,
} from "@material-ui/core";
import { connect } from "react-redux";
import CommunicatioSettingsBreadcrumb from "./CommunicatioSettingsBreadcrumb";
import FormCheckbox from "../form/FormCheckbox";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormCitySelectField from "../form/FormCitySelectField";
import FormCourierTypeSelectField from "../form/FormCourierTypeSelectField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { mapArrayResponseStream } from "../../helpers/ApiUtils";
import { isEqualData, isEqualWithoutFunctions } from "../../helpers/DataUtils";
import { createNotFalsyValidator } from "../../helpers/FormUtils";
import { formatText } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import PrivateTypes from "../../constants/AdminPrivacyTypes";
import CommunicationCustomTextTypes from "../../constants/CommunicationCustomTextTypes";
import { getCachedCommunicationsMeta } from "../../../client/api/shared/CommunicationApi";
import TransportationType from "../../constants/TransportationType";
import { validateString } from "../../helpers/ValidateUtils";
import { notificationWarehouseType } from "./CommunicationSettingsTable";

const SMS = "SMS";
const PUSH = "PUSH";
const ORDER_NOTE = "ORDER_NOTE";

const mapEvents = fp.flow(
  list =>
    list
      .toOrderedMap()
      .mapEntries(([, v]) => [v.get("description"), v.get("name")]),
  options => ({ keys: options.keySeq(), options }),
  fromJS,
);

// const EventCourierTypes = OrderedSet.of(
//   DEFAULT,
//   SAVER,
//   NEXT_DAY,
//   SAME_DAY,
//   URGENT,
// );

const MessageCustomData = props => (
  <FlexBox gutter={8} flex={true} direction="column">
    <FlexBox flex={true} direction="column">
      {props.fields.map((customData, index) => (
        <FlexBox gutter={8} flex={true} key={index}>
          <FlexBox flex={true} direction="column">
            <FormSelectField
              name={`${customData}.text`}
              options={CommunicationCustomTextTypes}
              formatOption={option =>
                props.getLocalisationMessage(option, formatText(option))
              }
              fullWidth={true}
              label={`${props.getLocalisationMessage("text", "Text")} *`}
              validate={createNotFalsyValidator(
                props.getLocalisationMessage("select_text", "Select Text"),
              )}
            />
          </FlexBox>

          <FlexBox flex={true} direction="column">
            <FormTextField
              fullWidth={true}
              label={`${props.getLocalisationMessage("value", "Value")} *`}
              name={`${customData}.value`}
              validate={createNotFalsyValidator(
                props.getLocalisationMessage("enter_value", "Enter Value"),
              )}
            />
          </FlexBox>

          <FlexBox direction="column" justify="center">
            <Button onClick={() => props.fields.remove(index)}>
              {props.getLocalisationMessage("remove", "Remove")}
            </Button>
          </FlexBox>
        </FlexBox>
      ))}
    </FlexBox>
    <FlexBox>
      <Button onClick={() => props.fields.push({})}>
        {props.getLocalisationMessage("add_custom_field", "Add Custom Field")}
      </Button>
    </FlexBox>
  </FlexBox>
);

MessageCustomData.propTypes = {
  getLocalisationMessage: PropTypes.func,
  fields: PropTypes.object,
};

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    card: { flex: "1 1 0%", overflowY: "auto" },
    attributesList: { overflowY: "auto", width: "300px", height: "490px" },
    attributesShortList: { height: "240px" },
    message: { fontSize: "18px" },
  }),
  withHandlers({
    onSubmit: props => values =>
      props.onSubmit({
        ...values,
        toJurisdictionId:
          values.toJurisdictionId === 0 ? null : values.toJurisdictionId,
        eventCourierType: values.eventCourierType
          ? values.eventCourierType
          : "default",
      }),
  }),
  reduxForm({
    form: "CommunicationSettingsForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      name:
        validateString(values.name) &&
        props.getLocalisationMessage("enter_name", "Enter Name"),
      type:
        !values.type &&
        props.getLocalisationMessage("select_event_type", "Select Event Type"),
      subject:
        validateString(values.subject) &&
        props.getLocalisationMessage("enter_subject", "Enter Subject"),
      content:
        validateString(values.content) &&
        props.getLocalisationMessage("enter_message", "Enter Message"),
      target:
        !values.target &&
        props.getLocalisationMessage(
          "select_event_target",
          "Select Event Target",
        ),
      messageType:
        !values.messageType &&
        props.getLocalisationMessage(
          "select_message_type",
          "Select Message Type",
        ),
    }),
  }),
  formValues("content", "messageType"),
  mapPropsStream(propsStream => {
    const communicationMetaResponseStream = propsStream
      .distinctUntilChanged(isEqualData)
      .switchMap(() =>
        getCachedCommunicationsMeta().catch(error => Observable.of({ error })),
      )
      .distinctUntilChanged(isEqualData);

    const dateDeclaredMethodsStream = propsStream
      .distinctUntilChanged(isEqualData)
      .filter(props => props.getDateDeclaredMethods)
      .switchMap(props =>
        props.getDateDeclaredMethods().catch(error => Observable.of({ error })),
      )
      .startWith({})
      .let(mapArrayResponseStream)
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        communicationMetaResponseStream,
        dateDeclaredMethodsStream,
        (props, communicationMetaResponse, dateDeclaredMethods) => ({
          ...props,
          eventTargets: mapEvents(communicationMetaResponse.get("targets")),
          eventTypes: mapEvents(communicationMetaResponse.get("eventTypes")),
          messageAttributes: communicationMetaResponse.get("attributes"),
          messageTypes: mapEvents(
            communicationMetaResponse.get("messageTypes"),
          ),
          declaredMethods: dateDeclaredMethods.get("payload"),
          eventActions: mapEvents(communicationMetaResponse.get("actions")),
        }),
      )
      .distinctUntilChanged(isEqualWithoutFunctions);
  }),
);

CommunicationSettingsForm.propTypes = {
  classes: PropTypes.object,

  eventTypes: PropTypes.instanceOf(Map),
  messageTypes: PropTypes.instanceOf(Map),
  eventTargets: PropTypes.instanceOf(Map),
  eventActions: PropTypes.instanceOf(Map),
  messageAttributes: PropTypes.instanceOf(List),

  onBackClick: PropTypes.func,

  breadcrumbTitle: PropTypes.string,

  change: PropTypes.func,
  submitting: PropTypes.bool,

  onDeleteClick: PropTypes.func,

  content: PropTypes.string,
  messageType: PropTypes.string,

  handleSubmit: PropTypes.func,

  isLoading: PropTypes.bool,

  getDateDeclaredMethods: PropTypes.func,
  declaredMethods: PropTypes.instanceOf(List),
  getLocalisationMessage: PropTypes.func,
};

function CommunicationSettingsForm(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <FlexBox flex={true} direction="column">
      <CommunicatioSettingsBreadcrumb
        title={props.breadcrumbTitle}
        onBackClick={props.onBackClick}
      />
      <Card className={classes.card}>
        <CardContent>
          <PageLoading isLoading={props.isLoading || props.submitting} />

          <FlexBox flex={true} container={8}>
            <FlexBox flex={true} gutter={8}>
              <FlexBox flex={true} direction="column">
                <form
                  autoComplete="off"
                  id="CommunicationSettingsForm"
                  onSubmit={props.handleSubmit}
                >
                  <FlexBox gutter={8} flex={true}>
                    <FlexBox>
                      <FormSelectField
                        name="type"
                        disabled={true}
                        fullWidth={true}
                        label={`${getLocalisationMessage(
                          "event_type",
                          "Event Type",
                        )} *`}
                        options={props.eventTypes.get("keys")}
                        formatOption={v =>
                          (v && getLocalisationMessage(v.toLowerCase())) ||
                          props.eventTypes.getIn(["options", v])
                        }
                      />
                    </FlexBox>

                    <FlexBox>
                      <FormSelectField
                        name="target"
                        disabled={true}
                        fullWidth={true}
                        label={`${getLocalisationMessage(
                          "event_target",
                          "Event Target",
                        )} *`}
                        options={props.eventTargets.get("keys")}
                        formatOption={v =>
                          (v && getLocalisationMessage(v.toLowerCase())) ||
                          props.eventTargets.getIn(["options", v])
                        }
                      />
                    </FlexBox>

                    <FlexBox>
                      <FormSelectField
                        name="messageType"
                        disabled={true}
                        fullWidth={true}
                        label={`${getLocalisationMessage(
                          "message_type",
                          "Message Type",
                        )} *`}
                        options={props.messageTypes.get("keys")}
                        formatOption={v =>
                          (v && getLocalisationMessage(v.toLowerCase())) ||
                          props.messageTypes.getIn(["options", v])
                        }
                      />
                    </FlexBox>

                    <FlexBox>
                      <FormCourierTypeSelectField
                        disabled={true}
                        autoWidth={true}
                        optional={true}
                        name="eventCourierType"
                        label={getLocalisationMessage(
                          "per_service_type",
                          "Per Service Type",
                        )}
                      />
                    </FlexBox>

                    <FlexBox>
                      <FormCitySelectField
                        name="toJurisdictionId"
                        autoWidth={true}
                        domestic={true}
                        disabled={true}
                        label={getLocalisationMessage("city", "City")}
                      />
                    </FlexBox>
                    <FlexBox>
                      <FormSelectField
                        style={{ minWidth: "180px" }}
                        disabled={true}
                        name="transportationType"
                        fullWidth={true}
                        options={TransportationType}
                        formatOption={x =>
                          getLocalisationMessage(x.toLowerCase(), formatText(x))
                        }
                        label={getLocalisationMessage("transportation_type")}
                      />
                    </FlexBox>

                    <FlexBox>
                      <FormSelectField
                        style={{ minWidth: "180px" }}
                        disabled={true}
                        name="notificationWarehouseType"
                        fullWidth={true}
                        options={notificationWarehouseType}
                        formatOption={x =>
                          getLocalisationMessage(x.toLowerCase(), formatText(x))
                        }
                        label={getLocalisationMessage(
                          "warehouse_type",
                          "Warehouse Type",
                        )}
                      />
                    </FlexBox>
                  </FlexBox>

                  <FlexBox gutter={8} flex={true}>
                    <FlexBox flex={true} direction="column">
                      <FormTextField
                        name="name"
                        fullWidth={true}
                        label={`${getLocalisationMessage("name", "Name")} *`}
                      />
                    </FlexBox>

                    {Boolean(props.messageType !== ORDER_NOTE) && (
                      <FlexBox flex={true} direction="column">
                        <FormTextField
                          name="subject"
                          fullWidth={true}
                          label={`${getLocalisationMessage(
                            "subject",
                            "Subject",
                          )} *`}
                        />
                      </FlexBox>
                    )}
                  </FlexBox>

                  <FieldArray
                    name="customData"
                    component={MessageCustomData}
                    props={{ getLocalisationMessage }}
                  />

                  {props.messageType === ORDER_NOTE && (
                    <FlexBox gutter={8} flex={true}>
                      <FlexBox>
                        <FormSelectField
                          name="privacy"
                          fullWidth={true}
                          label={getLocalisationMessage("privacy", "Privacy")}
                          formatOption={x =>
                            getLocalisationMessage(
                              x.toLocaleLowerCase(),
                              formatText,
                            )
                          }
                          options={PrivateTypes}
                        />
                      </FlexBox>
                    </FlexBox>
                  )}
                  <FlexBox gutter={8} flex={true}>
                    <FlexBox flex={true} direction="column">
                      <FormTextField
                        name="content"
                        rows={5}
                        rowsMax={5}
                        multiLine={true}
                        fullWidth={true}
                        label={`${getLocalisationMessage(
                          "message",
                          "Message",
                        )} *`}
                      />

                      {false && props.messageType === SMS && (
                        <FormCheckbox
                          name="duplicateOfPush"
                          label={getLocalisationMessage(
                            "is_duplicate_of_push",
                            "Is Duplicate Of Push",
                          )}
                        />
                      )}

                      {props.messageType === PUSH && (
                        <FormCheckbox
                          name="showPopUp"
                          label={getLocalisationMessage(
                            "show_pop_up",
                            "Show pop up",
                          )}
                        />
                      )}
                    </FlexBox>
                  </FlexBox>
                  <br />

                  <FlexBox gutter={8} flex={true}>
                    <FlexBox>
                      <Button type="submit">
                        {getLocalisationMessage("submit", "Submit")}
                      </Button>
                    </FlexBox>

                    {Boolean(props.onDeleteClick) && (
                      <FlexBox>
                        <Button
                          type="button"
                          secondary={true}
                          onClick={props.onDeleteClick}
                        >
                          {getLocalisationMessage("delete", "Delete")}
                        </Button>
                      </FlexBox>
                    )}
                  </FlexBox>
                </form>
              </FlexBox>

              <FlexBox direction="column">
                <FlexBox flex={true} gutter={8} direction="column">
                  <FlexBox flex={true} direction="column">
                    <ListSubheader>
                      {getLocalisationMessage("variables", "Variables")}
                    </ListSubheader>

                    <div
                      className={cx(classes.attributesList, {
                        [classes.attributesShortList]:
                          props.declaredMethods.size > 0,
                      })}
                    >
                      {props.messageAttributes.map((item, key) => (
                        <div key={key}>
                          <ListItem
                            onClick={() =>
                              props.change(
                                "content",
                                `${props.content || ""} ${item} `,
                              )
                            }
                          >
                            <ListItemText primary={item} />
                          </ListItem>

                          <Divider />
                        </div>
                      ))}
                    </div>
                  </FlexBox>

                  {props.declaredMethods.size > 0 && (
                    <FlexBox flex={true} direction="column">
                      <ListSubheader>
                        {getLocalisationMessage("date_methods", "Date Methods")}
                      </ListSubheader>

                      <div
                        className={cx(
                          classes.attributesList,
                          classes.attributesShortList,
                        )}
                      >
                        {props.declaredMethods.map((item, key) => (
                          <div key={key}>
                            <ListItem
                              onClick={() =>
                                props.change(
                                  "content",
                                  `${props.content || ""} ${item} `,
                                )
                              }
                            >
                              <ListItemText primary={item} />
                            </ListItem>

                            <Divider />
                          </div>
                        ))}
                      </div>
                    </FlexBox>
                  )}
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </CardContent>
      </Card>
    </FlexBox>
  );
}

export default enhancer(CommunicationSettingsForm);
