import React from "react";
import { fromJS } from "immutable";
import PropTypes from "prop-types";
import FormAbstractChips from "./FormAbstractChips";
import DataListFilter from "../../helpers/DataListFilter";
import { ACTIVE } from "../../constants/OverallStatus";
import { getCachedJM, getJMPredictions } from "../../api/shared/CountryV2Api";

const baseFilter = new DataListFilter({ page: 0, size: 20, status: ACTIVE });

const FormJMChips = (props) => (
  <FormAbstractChips
    {...props}
    getValue={(value) =>
      getCachedJM(value).map((item) =>
        fromJS({ id: item.get("id"), name: item.get("name") }),
      )
    }
    getPredictions={(searchText) =>
      getJMPredictions(baseFilter.setSearch(searchText))
    }
  />
);
FormJMChips.propTypes = {
  fullWidth: PropTypes.bool,
  autoWidth: PropTypes.bool,
  canAutoPosition: PropTypes.bool,
  maxHeight: PropTypes.number,
  openOnFocus: PropTypes.bool,
  clearOnBlur: PropTypes.bool,
  maxItems: PropTypes.number,
  maxSearchResults: PropTypes.number,
  onGetValue: PropTypes.func,
  validate: PropTypes.func,
  hintText: PropTypes.node,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["standard", "outlined", "filled"]),
  size: PropTypes.oneOf(["medium", "small"]),
};

FormJMChips.defaultProps = {
  variant: "outlined",
  size: "small",
};
export default FormJMChips;
