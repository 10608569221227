import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import { formatDateTime } from "../../helpers/FormatUtils";
import { getIsRTL } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    isRTL: getIsRTL(state),
  })),
  withTheme,
  useSheet({
    root: {
      position: "relative",
      "&:before": {
        display: "block",
        position: "absolute",
        width: "5px",
        height: "5px",
        content: '""',
        borderRadius: "50%",
        backgroundColor: props => props.theme.palette.accent1Color,
        boxShadow: props => `0 0 0 3px ${props.theme.palette.primary1Color}`,
      },
    },
    vertical: {
      "&:before": {
        top: "8px",
        left: ({ isRTL }) => (!isRTL ? "-16px" : "auto"),
        right: ({ isRTL }) => (isRTL ? "-16px" : "auto"),
      },
    },
    horizontal: {
      minWidth: "128px",
      marginTop: "12px",
      textAlign: "center",
      paddingLeft: "4px",
      paddingRight: "4px",
      borderTop: props => `2px solid ${props.theme.palette.accent1Color}`,
      "&:before": {
        zIndex: 1,
        left: "50%",
        top: "-18px",
        marginLeft: ({ isRTL }) => (!isRTL ? "0" : "-2px"),
        marginRight: ({ isRTL }) => (isRTL ? "0" : "-2px"),
      },
      "&:after": {
        zIndex: 0,
        content: '""',
        display: "block",
        background: props => props.theme.palette.accent1Color,
        width: "2px",
        height: "12px",
        position: "absolute",
        left: "50%",
        top: "-12px",
        marginLeft: ({ isRTL }) => (!isRTL ? "0" : "-1px"),
        marginRight: ({ isRTL }) => (isRTL ? "0" : "-1px"),
      },
    },
    title: {
      lineHeight: 1.5,
      fontSize: "15px",
    },
    titleVertical: {
      marginBottom: "8px",
    },
    titleHorizontal: {},
    date: {
      fontSize: "12px",
    },
    dateVertical: {
      top: -1,
      left: ({ isRTL }) => (!isRTL ? "-123px" : "auto"),
      right: ({ isRTL }) => (isRTL ? "-123px" : "auto"),
      display: "block",
      position: "absolute",
      minWidth: "96px",
      textAlign: ({ isRTL }) => (!isRTL ? "right" : "left"),
    },
    dateHorizontal: {
      fontWeight: "bold",
    },
  }),
);

TimelineEvent.propTypes = {
  classes: PropTypes.object,
  horizontal: PropTypes.bool,
  date: PropTypes.oneOfType([PropTypes.node, PropTypes.instanceOf(Date)]),
  title: PropTypes.node,
  children: PropTypes.node,
};

function TimelineEvent(props) {
  return (
    <li
      className={cx(props.classes.root, {
        [props.classes.vertical]: !props.horizontal,
        [props.classes.horizontal]: props.horizontal,
      })}
    >
      <span
        className={cx(props.classes.date, {
          [props.classes.dateVertical]: !props.horizontal,
          [props.classes.dateHorizontal]: props.horizontal,
        })}
      >
        {formatDateTime(props.date, props.date)}
      </span>

      {props.title && (
        <h3
          className={cx(props.classes.title, {
            [props.classes.titleVertical]: !props.horizontal,
            [props.classes.titleHorizontal]: props.horizontal,
          })}
        >
          {props.title}
        </h3>
      )}

      {props.children && <div>{props.children}</div>}
    </li>
  );
}

export default enhancer(TimelineEvent);
