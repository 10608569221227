import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Toolbar, IconButton, Typography } from "@material-ui/core";
import { withRouter } from "react-router";
import { ArrowBack } from "@material-ui/icons";

const enhancer = compose(
  withRouter,
  useSheet({
    root: { overflowX: "auto", "& svg": { fill: "#5555" } },
    backButton: { alignSelf: "center" },
  }),
);

CommunicatioSettingsBreadcrumb.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string.isRequired,
  onBackClick: PropTypes.func,
  children: PropTypes.node,
};

CommunicatioSettingsBreadcrumb.defaultProps = {
  onBackClick() {
    history.back();
  },
};

function CommunicatioSettingsBreadcrumb(props) {
  const { classes } = props;

  return (
    <Toolbar className={classes.root}>
      <IconButton className={classes.backButton} onClick={props.onBackClick}>
        <ArrowBack />
      </IconButton>
      <Typography
        variant="caption"
        style={{
          fontSize: "20px",
        }}
      >
        {props.title}
      </Typography>
      {props.children}
    </Toolbar>
  );
}

export default enhancer(CommunicatioSettingsBreadcrumb);
