import React from "react";
// import _ from "lodash";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Switch, FormControlLabel } from "@material-ui/core";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import { getIsRTL } from "../../reducers/LocalizationReducer";
// import { accent1, accent4 } from "../../../shared/theme/main-theme";

Toggle.propTypes = {
  value: PropTypes.any,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  isRTL: PropTypes.bool,
  classes: PropTypes.object,
  label: PropTypes.string,
};
Toggle.defaultProps = { onBlur: fp.noop, onFocus: fp.noop };

// const getStyles = props => ({
//   labelStyle: props.accent
//     ? _.assign(
//         {
//           color: props.theme.palette.appBarTextColor,
//           marginLeft: props.isRTL ? "8px" : 0,
//         },
//         props.labelStyle,
//       )
//     : props.labelStyle,
//   thumbSwitchedStyle: props.accent
//     ? { backgroundColor: accent1, ...props.thumbSwitchedStyle }
//     : props.thumbSwitchedStyle,
//   trackSwitchedStyle: props.accent
//     ? { backgroundColor: accent4, ...props.trackSwitchedStyle }
//     : props.trackSwitchedStyle,
// });

const cleanupProps = fp.omit([
  "accent",
  "value",
  "onChange",
  "invalid",
  "touched",
  "initialValue",
  "autofill",
  "onUpdate",
  "valid",
  "dirty",
  "pristine",
  "active",
  "visited",
  "autofilled",
  "sheet",
  "classes",
  "isRTL",
  "dispatch",
]);

const enhancer = compose(
  connect(state => ({
    isRTL: getIsRTL(state),
  })),
  withTheme,
  useSheet({
    toggleRoot: {
      "& label + div": props => ({
        marginLeft: !props.isRTL ? "0" : "8px",
        marginRight: props.isRTL ? "0" : "8px",
      }),
    },
  }),
);

function Toggle(props) {
  const inputProps = cleanupProps(props);
  // const styles = getStyles(props);

  return (
    <FormControlLabel
      control={
        <Switch
          {...inputProps}
          checked={props.value}
          onBlur={fp.flow(fp.noop, props.onBlur)}
          onFocus={fp.flow(fp.noop, props.onFocus)}
          onChange={event => props.onChange(event.target.checked)}
        />
      }
      style={{ color: inputProps.theme.appBar.textColor }}
      label={props.label}
      labelPlacement="start"
    />
  );
}

export default enhancer(Toggle);
