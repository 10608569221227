import React from "react";
import PropTypes from "prop-types";
import Navigate from "./Navigate";

Redirect.displayName = "Redirect";

Redirect.defaultProps = { when: true, push: false };

Redirect.propTypes = {
  push: PropTypes.bool,
  when: PropTypes.bool,
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

export default function Redirect(props) {
  return props.when ? <Navigate to={props.to} push={props.push} /> : null;
}
