import { Observable } from "rxjs";
import { fromJS, List, Map, OrderedMap } from "immutable";
import fp from "lodash/fp";
import { createEventHandler } from "recompose";
import { isEqualData, isImmutable } from "./DataUtils";
import { toOrderFilter } from "./OrderFilterMapper";
import { parseString } from "../helpers/SerializeUtils";

export const mapOrderListPropsStream =
  (getOrderList, project) => (propsStream) => {
    const { stream: onRowSelectStream, handler: onRowSelect } =
      createEventHandler();
    const { stream: onRequestRefreshStream, handler: onRequestRefresh } =
      createEventHandler();

    const selectedItemsStream = onRowSelectStream
      .distinctUntilChanged(isEqualData)
      .startWith(OrderedMap());
    const filterStream = propsStream
      .map(fp.flow(fp.get("location.query"), toOrderFilter))
      .distinctUntilChanged(isEqualData);

    const batchGetOrderListCount = (filters) =>
      Observable.from(
        filters
          .map((filter, key) =>
            getOrderList(filter)
              .catch((error) => Observable.of({ error }))
              .map(fp.get("payload.data.total"))
              .takeLast(1)
              .delay(1000)
              .map((value) => ({ key, value })),
          )
          .toArray(),
      )
        .concatAll()
        .scan((acc, response) => acc.set(response.key, response.value), Map())
        .startWith(Map());

    const responseStream = filterStream
      .switchMap((filter) =>
        getOrderList(filter)
          .repeatWhen(() => onRequestRefreshStream)
          .catch((error) => Observable.of({ error })),
      )
      .map(
        fp.flow(
          (response) => fromJS(response),
          (response) =>
            fromJS({
              error: response.get("error", null),
              isLoading: response.get("pending", false),
              total: response.getIn(["payload", "data", "total"], 0),
              list: response.getIn(["payload", "data", "list"], List()),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData)
      .do(() => onRowSelect(OrderedMap()));

    return propsStream
      .distinctUntilChanged(isEqualData)
      .combineLatest(
        filterStream,
        responseStream,
        selectedItemsStream,
        (props, filter, response, selectedItems) =>
          project(
            props,
            filter,
            response,
            onRowSelect,
            selectedItems,
            onRequestRefresh,
            batchGetOrderListCount,
          ),
      );
  };

export function formatDateValuesForDownloadCSV(filter, v) {
  let csvParams = Map();
  const tempFilter = fromJS(
    isImmutable(filter) ? filter.getValues() : { ...filter, csv_columns: v },
  );
  tempFilter.forEach((value, key) => {
    switch (key) {
      case "from_date_time":
        csvParams = csvParams.set("from_date", value);
        break;
      case "to_date_time":
        csvParams = csvParams.set("to_date", value);
        break;
      case "from_promise_date_time":
        csvParams = csvParams.set("from_promise_date", value);
        break;
      case "to_promise_date_time":
        csvParams = csvParams.set("to_promise_date", value);
        break;
      case "from_deadline_time":
        csvParams = csvParams.set("from_deadline", value);
        break;
      case "to_deadline_time":
        csvParams = csvParams.set("to_deadline", value);
        break;
      case "status_from_date_time":
        csvParams = csvParams.set("status_from_date", value);
        break;
      case "status_to_date_time":
        csvParams = csvParams.set("status_to_date", value);
        break;
      case "historyStatuses":
        csvParams = csvParams.set("history_statuses", parseString(value));
        break;
      default:
        csvParams = csvParams.set(key, value);
        break;
    }
  });
  return csvParams;
}
