import { Observable } from "rxjs";
import React, { useRef } from "react";
import Immutable from "immutable";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Paper } from "@material-ui/core";
import { connect } from "react-redux";
import { FitBounds } from "react-google-map-components";
import { FitBounds as LFitBounds, Route } from "react-leflet-map-components";
import BrandMarker from "../../maps/BrandMarker";
import BrandMarkerCar from "../../maps/BrandMarkerCar";
import PolylineWrapper from "../../maps/PolylineWrapper";
import GoogleMapWrapper from "../../maps/GoogleMapWrapper";
import LeafletMapWrapper from "../../maps-leaflet/LeafletMapWrapper";
import { mapObjectResponseStream } from "../../../helpers/ApiUtils";
import { isEqualData } from "../../../helpers/DataUtils";
import { isValidCoordinates } from "../../../helpers/ValidateUtils";
import { ON_HIS_WAY, OUT_FOR_DELIVERY } from "../../../constants/OrderStatusCodes";
import { getMapProvider, isMapKeyAvailable } from "../../../../shared/reducers/AppReducer";
import {
  GOOGLE_MAP_PROVIDER,
  MAP_PROVIDERS,
  YANDEX_MAP_PROVIDER,
} from "../../../../shared/constants/MapsControllerConstants";
import CarGreen from "../../maps/assets/car_green.png";
import MarkerAccent from "../../maps/assets/marker-accent.png";
import MarkerPrimary from "../../maps/assets/marker-primary.png";
import { Placemark } from "react-yandex-maps";
import YandexMapWrapper from "../../maps/YandexMapWrapper";

const enhancer = compose(
  useSheet({ root: { margin: "0 6px 6px", height: "320px" } }),
  connect(state => ({
    mapProvider: getMapProvider(state),
    isMapKeyAvailable: isMapKeyAvailable(state),
  })),
  mapPropsStream(propsStream => {
    const driverPositionStream = propsStream
      .distinctUntilKeyChanged("order", isEqualData)
      .switchMap(props => {
        const { order } = props;

        if (!order.hasIn(["driver", "id"])) {
          return Observable.of(Immutable.Map());
        }

        return props
          .getDriverLocation(order.getIn(["driver", "id"]))
          .takeLast(1)
          .let(mapObjectResponseStream)
          .map(x => x.get("payload"))
          .repeatWhen(stream =>
            stream.delay(
              order.get("status") === ON_HIS_WAY ||
                order.get("status") === OUT_FOR_DELIVERY
                ? 5 * 1000
                : 5 * 60 * 1000,
            ),
          );
      })
      .startWith(Immutable.Map())
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(driverPositionStream, (props, driverPosition) => ({
        ...props,
        driverPosition,
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

OrderDetailsDialogMap.propTypes = {
  classes: PropTypes.object,
  driverPosition: PropTypes.instanceOf(Immutable.Map),
  mapProvider: PropTypes.oneOf(MAP_PROVIDERS),
  getDriverLocation: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Immutable.Map).isRequired,
  isMapKeyAvailable: PropTypes.bool,
};

function OrderDetailsDialogMap(props) {
  const { classes } = props;
  const map = useRef(null);

  const isYandexMap = props.mapProvider === YANDEX_MAP_PROVIDER;
  const isGoogleMap = props.mapProvider === GOOGLE_MAP_PROVIDER;

  const pickupPosition = {
    lat: props.order.getIn(["sender_data", "lat"], 0),
    lng: props.order.getIn(["sender_data", "lon"], 0),
  };

  const dropoffPosition = {
    lat: props.order.getIn(["recipient_data", "lat"], 0),
    lng: props.order.getIn(["recipient_data", "lon"], 0),
  };

  const driverPosition = {
    lat: props.driverPosition.get("lat"),
    lng: props.driverPosition.get("lon"),
  };

  const driverPath = isValidCoordinates(driverPosition)
    ? [
        {
          lat: driverPosition.lat,
          lng: driverPosition.lng,
          icon: {
            url: CarGreen,
            options: {
              anchor: { x: 16, y: 16 },
              size: { width: 32, height: 32 },
            },
          },
        },
      ]
    : [];

  const addRoute = (ymaps) => {
    const pointA = [pickupPosition.lat, pickupPosition.lng];
    const pointB = [dropoffPosition.lat, dropoffPosition.lng];
    const pointC = [driverPosition.lat, driverPosition.lng];
    let referencePoints = [];
    if (isValidCoordinates(pickupPosition) && isValidCoordinates(dropoffPosition) && isValidCoordinates(driverPosition)) {
      referencePoints = [pointA, pointB, pointC];
    } else if (isValidCoordinates(pickupPosition) && isValidCoordinates(dropoffPosition)) {
      referencePoints = [pointA, pointB];
    }

    const multiRoute = new ymaps.multiRouter.MultiRoute(
      {
        referencePoints,
      },
      {
        wayPointStartIconLayout: "default#image",
        wayPointStartIconColor: "",

        wayPointFinishIconLayout: "default#image",
        wayPointFinishIconImageHref: "",

        boundsAutoApply: true,
      },
    );

    map.current.geoObjects.add(multiRoute);
  };

  return (
    <Paper className={classes.root}>
      {
        isYandexMap ? (
            <YandexMapWrapper
              onLoad={addRoute}
              instanceRef={map}
              modules={["multiRouter.MultiRoute"]}
            >
              {isValidCoordinates(driverPosition) && (
                <Placemark
                  geometry={[driverPosition.lat, driverPosition.lng]}
                  options={{
                    iconLayout: "default#image",
                    iconImageHref: CarGreen,
                  }}
                />
              )}
            </YandexMapWrapper>
          )
          : isGoogleMap ? (
        <GoogleMapWrapper>
          <BrandMarker position={pickupPosition} />
          <BrandMarker accent={false} position={dropoffPosition} />

          <PolylineWrapper
            origin={pickupPosition}
            destination={dropoffPosition}
          />

          {!isValidCoordinates(driverPosition) ? (
            <FitBounds latLngBounds={[pickupPosition, dropoffPosition]} />
          ) : (
            <div>
              <BrandMarkerCar position={driverPosition} />

              <FitBounds
                latLngBounds={[pickupPosition, dropoffPosition, driverPosition]}
              />
            </div>
          )}
        </GoogleMapWrapper>
      ) : (
        <LeafletMapWrapper zoom={9}>
          <Route
            strokeColor="#FF0000"
            strokeWeight={3}
            strokeOpacity={0.8}
            origin={{
              lat: pickupPosition.lat,
              lng: pickupPosition.lng,
              icon: {
                url: MarkerPrimary,
                options: {
                  anchor: { x: 16, y: 16 },
                  size: { width: 32, height: 32 },
                },
              },
            }}
            destination={{
              lat: dropoffPosition.lat,
              lng: dropoffPosition.lng,
              icon: {
                url: MarkerAccent,
                options: {
                  anchor: { x: 16, y: 16 },
                  size: { width: 32, height: 32 },
                },
              },
            }}
            path={driverPath}
          />

          {isValidCoordinates(driverPosition) ? (
            <LFitBounds
              latLngBounds={[driverPosition, pickupPosition, dropoffPosition]}
            />
          ) : (
            <LFitBounds latLngBounds={[pickupPosition, dropoffPosition]} />
          )}
        </LeafletMapWrapper>
      )}
    </Paper>
  );
}

export default enhancer(OrderDetailsDialogMap);
