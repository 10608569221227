import React from "react";
import useSheet from "react-jss";
import cx from "classnames";
import PropTypes from "prop-types";
import Text, { MUTED, PRIMARY } from "./Text";

const enhancer = useSheet({
  root: { display: "block" },
  title: { fontWeight: 500, color: "rgba(0, 0, 0, 0.54)" },
  content: { marginLeft: "24px" },
});

DescriptionTerm.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.node.isRequired,
  children: PropTypes.node,
  fallbackValue: PropTypes.node,
  containerClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  contentClassName: PropTypes.string,
};

function DescriptionTerm(props) {
  return (
    <div className={cx(props.classes.root, props.containerClassName)}>
      <div className={cx(props.classes.title, props.titleClassName)}>
        {props.title}
      </div>
      <Text
        type={PRIMARY}
        element="div"
        className={cx(props.classes.content, props.contentClassName)}
        fallbackType={MUTED}
        fallbackValue={props.fallbackValue}
      >
        {props.children}
      </Text>
    </div>
  );
}

export default enhancer(DescriptionTerm);
