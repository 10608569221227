import React from "react";
import fp from "lodash/fp";
import PropTypes from "prop-types";

export default class NavigationPrompt extends React.Component {
  static propTypes = {
    when: PropTypes.bool,
    beforeUnload: PropTypes.bool,
    message: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
  };

  static defaultProps = { when: true, beforeUnload: true };

  static contextTypes = {
    router: PropTypes.shape({
      listenBefore: PropTypes.func.isRequired,
    }).isRequired,
  };

  componentDidMount() {
    window.addEventListener("beforeunload", this.handleUnload);
    this.unlisten = this.context.router.listenBefore(this.handleRouteChange);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleUnload);

    if (this.unlisten) {
      this.unlisten();
      this.unlisten = null;
    }
  }

  getMessage = location =>
    fp.isFunction(this.props.message)
      ? this.props.message(location)
      : this.props.message;

  handleUnload = event => {
    if (this.props.when && this.props.beforeUnload) {
      event.preventDefault();

      // eslint-disable-next-line no-param-reassign
      event.returnValue = this.getMessage(
        this.context.router.getCurrentLocation(),
      );

      return event.returnValue;
    }

    return undefined;
  };

  handleRouteChange = location =>
    !this.props.when ? undefined : this.getMessage(location);

  render() {
    return null;
  }
}
