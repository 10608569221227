import React from "react";
import fp from "lodash/fp";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { TextFieldComponent } from "./FormTextField";
import LayerProvider from "../portals/LayerProvider";

const getFileName = fp.flow(fp.get("name"), fp.trim);

FileFieldComponent.propTypes = {
  input: PropTypes.object,
  accept: PropTypes.string,
  onClick: PropTypes.func,
};
FileFieldComponent.defaultProps = { onClick: fp.noop };

function FileFieldComponent({ input, accept, onClick, ...custom }) {
  let inputNode;

  const focusInput = () => {
    if (inputNode) {
      inputNode.value = null;
      inputNode.click();
    }
  };

  return (
    <LayerProvider
      layer={
        <input
          value=""
          type="file"
          tabIndex={-1}
          accept={accept}
          multiple={false}
          ref={x => {
            inputNode = x;
          }}
          style={{ display: "none" }}
          onChange={fp.flow(fp.get(["target", "files", 0]), input.onChange)}
        />
      }
    >
      <TextFieldComponent
        {...custom}
        type="text"
        readOnly={true}
        onClick={fp.flow(onClick, focusInput)}
        input={{
          ...input,
          onChange: fp.noop,
          value: getFileName(input.value),
          onBlur: fp.flow(fp.noop, input.onBlur),
          onFocus: fp.flow(fp.noop, input.onFocus),
        }}
      />
    </LayerProvider>
  );
}

FormFileField.propTypes = {
  validate: PropTypes.func,
  name: PropTypes.string.isRequired,

  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,

  accept: PropTypes.string,
  multiple: PropTypes.bool,
  fullWidth: PropTypes.bool,

  hintText: PropTypes.node,
  label: PropTypes.node,
};

export default function FormFileField(props) {
  return <Field {...props} component={FileFieldComponent} />;
}
