import React from "react";
import { List, Map, OrderedMap } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, defaultProps, withHandlers } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { Checkbox, LinearProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { AutoSizer, Column, SortDirection, Table } from "react-virtualized";
import {
  ArrowDownward as NavigationArrowDownward,
  ArrowUpward as NavigationArrowUpward,
} from "@material-ui/icons";
import { grey, red } from "@material-ui/core/colors";
import DataListFooter from "./DataListFooter";
import DataListHeader from "./DataListHeader";
import DataListFilter, {
  DEFAULT_MAX_PAGE_SIZE,
} from "../../helpers/DataListFilter";
import { getIsRTL, getMessage } from "../../reducers/LocalizationReducer";

const JustifyContent = { start: "start", end: "end", center: "center" };
const JustifyContentClasses = {
  start: "justifyStart",
  end: "justifyEnd",
  center: "justifyCenter",
};

DataListColumn.propTypes = {
  label: PropTypes.node.isRequired,
  width: PropTypes.number.isRequired,
  dataKey: PropTypes.string.isRequired,
  disableSort: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Callback responsible for returning a cell's data, given its :dataKey
   * ({ columnData: any, dataKey: string, rowData: any }): any
   */
  cellDataGetter: PropTypes.func,
  /**
   * Callback responsible for rendering a cell's contents.
   * ({ cellData: any, columnData: any, dataKey: string, rowData: any, rowIndex: number }): node
   */
  cellRenderer: PropTypes.func.isRequired,

  headerRenderer: PropTypes.func,

  headerClassName: PropTypes.string,
  justifyContent: PropTypes.oneOf([
    JustifyContent.start,
    JustifyContent.center,
    JustifyContent.end,
  ]),
};

DataListColumn.defaultProps = {
  cellDataGetter: fp.get("rowData"),
  headerRenderer: fp.get("label"),
};

export function DataListColumn() {
  return null;
}

DataListCheckbox.displayName = "DataListCheckbox";

DataListCheckbox.propTypes = {
  allRowsSelected: PropTypes.func.isRequired,
  onAllRowsSelect: PropTypes.func.isRequired,
  rowSelected: PropTypes.func.isRequired,
  onRowSelect: PropTypes.func.isRequired,
};

export function DataListCheckbox() {
  return null;
}

const enhancer = compose(
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    isRTL: getIsRTL(state),
  })),
  useSheet({
    root: {
      flex: "1 1 0%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "white",
    },
    withHeader: { minHeight: `${64 + 52 + 56 * 5}px` },
    withFooter: {
      minHeight: (props) =>
        props.classNameWithFooter ? " 360px" : `${52 + 56 * 5 + 56}px`,
    },
    withHeaderAndFooter: {
      minHeight: (props) =>
        props.classNameDataList ? "200px" : `${64 + 52 + 56 * 5 + 56}px`,
    },
    withoutHeaderAndFooter: { minHeight: `${52 + 56 * 5}px` },

    wrapper: {
      flex: "1 1 0%",
      width: "100%",
      overflowX: "auto",
      overflowY: "hidden",
    },
    progress: { borderRadius: 0 },
    grid: { outline: "none" },

    baseRow: {
      display: "flex",
      lineHeight: 1.4,
      paddingLeft: "4px",
      paddingRight: "4px",
      flexDirection: "row",
      borderBottom: `1px solid ${grey[300]}`,
      transition: `all 300ms ease-out`,
    },

    headerRow: {
      fontSize: "13px",
      extend: "baseRow",
      backgroundColor: "white",
    },

    row: {
      extend: "baseRow",
      fontSize: "12px",
      color: "#555",
      fontWeight: "normal",
      right: (props) => (props.isRTL ? "0" : "auto"),
    },

    rowOdd: { important: false, backgroundColor: "white" },
    rowEven: { important: false, backgroundColor: grey[100] },
    rowAttention: { important: false, backgroundColor: red[300] },
    rowHoverable: {
      important: false,
      "&:hover": { backgroundColor: grey[200] },
      transition: `background-color 300ms ease-out`,
    },

    baseColumn: {
      position: "relative",
      flex: "1 1 0%",
      display: "flex",
      outline: "none",
      alignItems: "center",
      paddingLeft: "6px",
      paddingRight: "6px",
    },

    headerColumn: { extend: "baseColumn" },
    sortableHeaderColumn: { cursor: "pointer" },

    headerContainer: { display: "flex", alignItems: "center" },
    headerLabel: {
      flex: "1 1 0%",
      color: "#5559",
      transition: `all 300ms ease-out`,
    },
    sortableHeaderLabel: { fontSize: "12px", color: "#555" },
    headerIcon: { width: "16px", height: "16px", marginRight: "2px" },

    rowColumn: { extend: "baseColumn" },

    checkboxColumn: { padding: "16px" },

    justifyStart: { textAlign: "left", justifyContent: "flex-start" },
    justifyEnd: { textAlign: "right", justifyContent: "flex-end" },
    justifyCenter: { textAlign: "center", justifyContent: "center" },
  }),
  defaultProps({ getRowClassName: fp.constant("") }),
  withHandlers({
    getRowClassName: (props) => (row) => {
      const rowContent = props.list ? props.list.get(row.index, Map()) : Map();
      const isRequiredAttention = rowContent.get("requires_attention", false);

      return cx(
        props.getRowClassName(row),
        row.index === -1
          ? props.classes.headerRow
          : cx(
              props.classes.row,
              props.classes.rowHoverable,
              row.index % 2 ? props.classes.rowEven : props.classes.rowOdd,
              isRequiredAttention && props.classes.rowAttention,
            ),
      );
    },
  }),
);

DataList.propTypes = {
  classes: PropTypes.object,

  children: PropTypes.node,
  withHeader: PropTypes.bool,
  withFooter: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  rowGetter: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
  overscanRowCount: PropTypes.number.isRequired,
  list: PropTypes.instanceOf(List),
  onHeaderClick: PropTypes.func,
  onRowClick: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  onRowMouseOut: PropTypes.func,
  onRowMouseOver: PropTypes.func,
  onRowsRendered: PropTypes.func,
  rowHeight: PropTypes.number,
  headerHeight: PropTypes.number,
  className: PropTypes.string,
  classNameDataList: PropTypes.bool,
  classNameWithFooter: PropTypes.bool,
  gridClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  getRowClassName: PropTypes.func,
  noRowsRenderer: PropTypes.func,
  minWidth: PropTypes.number,
  selectedRowCount: PropTypes.number,
  chipTextHint: PropTypes.string,

  altHeader: PropTypes.node,
  cardActionIcons: PropTypes.node,
  maxSearchItems: PropTypes.number,
  maxPageSize: PropTypes.number,

  totalCount: PropTypes.number,
  footerContent: PropTypes.node,
  onFilterChange: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
  searchTypes: PropTypes.instanceOf(OrderedMap),
  getLocalisationMessage: PropTypes.func.isRequired,
  isRTL: PropTypes.bool,
  disableOthersValue: PropTypes.string,
  disableOthersType: PropTypes.string,
  isDisableOption: PropTypes.bool,
  withSwitch: PropTypes.bool,
};

DataList.defaultProps = {
  minWidth: 0,
  rowHeight: 56,
  headerHeight: 56,
  maxPageSize: DEFAULT_MAX_PAGE_SIZE,
  withHeader: true,
  withFooter: true,
};

function DataList(props) {
  const { classes, getLocalisationMessage } = props;

  const headerClassName = cx(props.headerClassName, classes.headerColumn);

  let actualMinWidth = 8;

  const columns = React.Children.map(props.children, (child) => {
    if (!React.isValidElement(child)) {
      return null;
    }

    if (child.type.displayName === DataListCheckbox.displayName) {
      actualMinWidth += 56;

      return (
        <Column
          width={56}
          minWidth={56}
          label={getLocalisationMessage("selection", "Selection")}
          dataKey="selection"
          disableSort={true}
          style={{ minWidth: `${56}px` }}
          headerClassName={cx(
            child.props.headerClassName,
            classes.checkboxColumn,
          )}
          headerRenderer={() => (
            <Checkbox
              disabled={!props.rowCount}
              checked={child.props.allRowsSelected()}
              onChange={(event, selected) =>
                child.props.onAllRowsSelect(selected)
              }
            />
          )}
          className={cx(child.props.className, classes.checkboxColumn)}
          cellDataGetter={fp.get("rowData")}
          cellRenderer={(row) => (
            <Checkbox
              disabled={
                props.isDisableOption &&
                props.disableOthersValue &&
                props.disableOthersValue !==
                  row.rowData.get(props.disableOthersType)
              }
              checked={child.props.rowSelected(row)}
              onChange={(event, selected) =>
                child.props.onRowSelect({ selected, ...row })
              }
            />
          )}
        />
      );
    }

    actualMinWidth += fp.toFinite(child.props.width);

    const disableSort = props.filter ? child.props.disableSort : true;

    return (
      <Column
        {...child.props}
        disableSort={disableSort}
        style={{ minWidth: `${child.props.width}px` }}
        minWidth={child.props.width}
        className={cx(
          classes.rowColumn,
          classes[JustifyContentClasses[child.props.justifyContent]],
          child.props.className,
        )}
        headerClassName={cx(
          headerClassName,
          { [classes.sortableHeaderColumn]: !disableSort },
          classes[JustifyContentClasses[child.props.justifyContent]],
          child.props.headerClassName,
        )}
        headerRenderer={(options) => (
          <div className={classes.headerContainer}>
            {options.sortBy === options.dataKey &&
              options.sortDirection === SortDirection.ASC && (
                <NavigationArrowDownward className={classes.headerIcon} />
              )}
            {options.sortBy === options.dataKey &&
              options.sortDirection === SortDirection.DESC && (
                <NavigationArrowUpward className={classes.headerIcon} />
              )}
            <div
              className={cx(classes.headerLabel, {
                [classes.sortableHeaderLabel]:
                  options.sortBy === options.dataKey,
              })}
            >
              {child.props.headerRenderer(options)}
            </div>
          </div>
        )}
      />
    );
  });

  const withHeader = Boolean(
    props.withHeader &&
      (props.altHeader ||
        props.cardActionIcons ||
        (props.filter && props.maxSearchItems > 0)),
  );

  const withFooter =
    props.withFooter && Boolean(props.filter || props.footerContent);

  return (
    <div
      className={cx(classes.root, {
        [classes.withHeader]: withHeader && !withFooter,
        [classes.withFooter]: !withHeader && withFooter,
        [classes.withHeaderAndFooter]: withHeader && withFooter,
        [classes.withoutHeaderAndFooter]: !withHeader && !withFooter,
      })}
    >
      {withHeader && (
        <DataListHeader
          filter={props.filter}
          withSwitch={props.withSwitch}
          searchTypes={props.searchTypes}
          chipTextHint={props.chipTextHint}
          actionIcons={props.cardActionIcons}
          onFilterChange={props.onFilterChange}
          maxSearchItems={props.maxSearchItems}
          altHeader={props.altHeader}
          selectedRowCount={props.selectedRowCount}
        />
      )}

      <div className={classes.wrapper}>
        <AutoSizer>
          {(size) => (
            <Table
              onHeaderClick={props.onHeaderClick}
              onRowClick={props.onRowClick}
              onRowDoubleClick={props.onRowDoubleClick}
              onRowMouseOut={props.onRowMouseOut}
              onRowMouseOver={props.onRowMouseOver}
              onRowsRendered={props.onRowsRendered}
              scrollToIndex={0}
              sort={(options) =>
                props.onFilterChange(props.filter.toggleOrderBy(options.sortBy))
              }
              sortBy={props.filter && props.filter.getOrderBy()}
              sortDirection={
                props.filter && props.filter.isOrderByAsc()
                  ? SortDirection.ASC
                  : SortDirection.DESC
              }
              height={size.height}
              width={Math.max(size.width, props.minWidth, actualMinWidth)}
              rowCount={props.rowCount}
              rowHeight={props.rowHeight}
              headerHeight={props.headerHeight}
              overscanRowCount={props.overscanRowCount}
              rowGetter={props.rowGetter}
              style={{ minWidth: `${props.minWidth}px` }}
              className={cx(classes.table, props.className)}
              gridClassName={cx(classes.grid, props.gridClassName)}
              gridStyle={{ direction: props.isRTL ? "rtl" : "ltr" }}
              rowClassName={props.getRowClassName}
              disableHeader={false}
              noRowsRenderer={(...args) =>
                props.noRowsRenderer
                  ? props.noRowsRenderer(...args)
                  : Boolean(props.isLoading) && (
                      <LinearProgress
                        mode="indeterminate"
                        color="secondary"
                        className={classes.progress}
                      />
                    )
              }
            >
              {columns}
            </Table>
          )}
        </AutoSizer>
      </div>
      {withFooter && (
        <DataListFooter
          filter={props.filter}
          totalCount={props.totalCount}
          onFilterChange={props.onFilterChange}
          maxPageSize={props.maxPageSize}
        >
          {props.footerContent}
        </DataListFooter>
      )}
    </div>
  );
}

export default enhancer(DataList);
