import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { withTheme } from "@material-ui/core/styles";

const enhancer = compose(
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  reduxForm({ form: "FormDialog" }),
  withTheme,
);

FormDialog.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  title: PropTypes.node,
  children: PropTypes.node,
  form: PropTypes.string,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func,
  theme: PropTypes.object,
  className: PropTypes.string,
  getLocalisationMessage: PropTypes.func,
};

function FormDialog(props) {
  return (
    <Dialog
      open={props.open}
      className={props.className}
      onClose={props.onRequestClose}
      maxWidth="md"
      fullWidth={true}
      onSubmit={props.handleSubmit}
      PaperProps={{
        style: {
          maxWidth: "320px",
        },
      }}
    >
      <DialogTitle
        style={{
          color: props.theme.palette.appBarTextColor,
          backgroundColor: props.theme.palette.primary.main,
        }}
      >
        {props.title}
      </DialogTitle>
      <PageLoading isLoading={props.submitting} />
      <DialogContent style={{ color: "gray" }}>
        <form id={props.form} onSubmit={props.handleSubmit}>
          {props.children}
        </form>
      </DialogContent>
      <DialogActions>
        <div>
          {props.onRequestClose && (
            <Button onClick={props.onRequestClose}>
              {" "}
              {props.getLocalisationMessage("no")}
            </Button>
          )}

          <Button onClick={props.handleSubmit}>
            {" "}
            {props.getLocalisationMessage("yes")}{" "}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(FormDialog);
