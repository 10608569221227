import React from "react";
import { Collection, OrderedSet } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream, withState } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormCheckbox from "../form/FormCheckbox";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormNoteReasonSelectField from "../form/FormNoteReasonSelectField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import {
  formatInquiriesTypes,
  formatReasonTypesLocalised,
} from "../../helpers/OrderHelper";
import { isCustomMarketplace } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import ReasonTypes from "../../constants/ReasonTypes";
import {
  CHAT_CALL_FROM_CLIENT,
  SOUQ_CUSTOMER_SUPPORT_EMAIL,
  WING_SUPPORT_EMAIL,
} from "../../constants/InquiriesTypes";
import { PUBLIC } from "../../constants/NotePrivacyTypes";
import { GENERIC } from "../../constants/NoteTypeCategory";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  connect(state => ({
    isCustom: isCustomMarketplace(state),
  })),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .map(fp.pick(["privacyTypes", "noteTypeCategory", "initialValues"]))
      .distinctUntilChanged(isEqualData)
      .map(props => ({
        ...props.initialValues,
        privacy: props.privacyTypes ? props.privacyTypes.first() : PUBLIC,
        category: props.noteTypeCategory
          ? props.noteTypeCategory.first()
          : GENERIC,
      }));

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,
        initialValues,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  reduxForm({
    form: "OrderNoteDialog",
    enableReinitialize: true,
    validate: (values, props) => ({
      content:
        !values.content &&
        props.getLocalisationMessage(
          "enter_note_content",
          "Enter note content",
        ),
      privacy:
        Boolean(
          !values.privacy ||
            (props.privacyTypes && !props.privacyTypes.has(values.privacy)),
        ) &&
        props.getLocalisationMessage(
          "select_privacy_type",
          "Select Privacy Type",
        ),
      reason:
        !values.reason &&
        props.getLocalisationMessage("select_reason", "Select Reason"),
      inquiries:
        !values.reason &&
        props.getLocalisationMessage(
          "select_source_of_inquiries",
          "Select Source of Inquiries",
        ),
    }),
  }),
  withState("state", "setState", {
    showRequiresAttention: false,
    showScheduledForCancellation: false,
  }),
);

OrderNoteDialog.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  className: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  privacyTypes: PropTypes.instanceOf(Collection),
  withRequiresAttention: PropTypes.bool,
  isCustomMarketplace: PropTypes.bool,
  withScheduledForCancellation: PropTypes.bool,
  reasonTypes: PropTypes.instanceOf(OrderedSet),
  inquiriesTypes: PropTypes.instanceOf(OrderedSet),
  getLocalisationMessage: PropTypes.func,
  state: PropTypes.object,
  setState: PropTypes.func,
};

function OrderNoteDialog(props) {
  const { getLocalisationMessage, state } = props;
  return (
    <Dialog
      open={props.open}
      PaperProps={{
        style: {
          minWidth: 400,
          maxWidth: 700,
        },
      }}
    >
      <PageLoading isLoading={props.submitting} />
      <DialogContent>
        <FlexBox direction="column" gutter={8} element={<form />}>
          <FlexBox>
            <FlexBox gutter={8} flex={true}>
              <FlexBox flex={true}>
                <FormTextField
                  name="content"
                  rows={4}
                  multiLine={true}
                  fullWidth={true}
                  label={`${getLocalisationMessage("content", "Content")} *`}
                />
              </FlexBox>

              {props.inquiriesTypes && (
                <FlexBox>
                  <FormSelectField
                    name="inquiries"
                    autoWidth={true}
                    options={
                      !props.isCustomMarketplace
                        ? props.inquiriesTypes
                            .delete(SOUQ_CUSTOMER_SUPPORT_EMAIL)
                            .delete(CHAT_CALL_FROM_CLIENT)
                            .delete(WING_SUPPORT_EMAIL)
                        : props.inquiriesTypes
                    }
                    formatOption={x =>
                      getLocalisationMessage(x, formatInquiriesTypes(x))
                    }
                    label={`${getLocalisationMessage(
                      "source_of_inquiries",
                      "Source Of Inquiries",
                    )} *`}
                  />
                </FlexBox>
              )}
            </FlexBox>
          </FlexBox>
          {props.privacyTypes && (
            <FlexBox>
              <FormSelectField
                name="privacy"
                autoWidth={true}
                fullWidth={true}
                label={`${getLocalisationMessage("privacy", "Privacy")} *`}
                formatOption={v => getLocalisationMessage(v) || formatText(v)}
                options={props.privacyTypes}
              />
            </FlexBox>
          )}

          {props.reasonTypes && (
            <FlexBox>
              <FormNoteReasonSelectField
                name="reason"
                autoWidth={true}
                fullWidth={true}
                label={`${getLocalisationMessage("reason", "Reason")} *`}
                formatOption={o =>
                  formatReasonTypesLocalised(o, getLocalisationMessage, "N/A")
                }
                options={ReasonTypes}
              />
            </FlexBox>
          )}

          {props.withRequiresAttention && (
            <FlexBox>
              <FormCheckbox
                onClick={() =>
                  props.setState(
                    fp.set(
                      "showRequiresAttention",
                      !state.showRequiresAttention,
                    ),
                  )
                }
                disabled={state.showScheduledForCancellation}
                name="requiresAttention"
                label={getLocalisationMessage(
                  "requires_attention",
                  "Requires Attention",
                )}
              />
            </FlexBox>
          )}

          {props.withScheduledForCancellation && (
            <FlexBox>
              <FormCheckbox
                onClick={() =>
                  props.setState(
                    fp.set(
                      "showScheduledForCancellation",
                      !state.showScheduledForCancellation,
                    ),
                  )
                }
                disabled={state.showRequiresAttention}
                name="scheduledForCancellation"
                label={getLocalisationMessage(
                  "scheduled_for_cancellation",
                  "Scheduled For Cancellation",
                )}
              />
            </FlexBox>
          )}
        </FlexBox>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onRequestClose}>
          {getLocalisationMessage("dismiss", "Dismiss")}
        </Button>
        <Button onClick={props.handleSubmit}>
          {getLocalisationMessage("submit", "Submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(OrderNoteDialog);
