import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Marker, MarkerIcon } from "react-google-map-components";
import MarkerAccent from "./assets/marker-accent.png";
import MarkerPrimary from "./assets/marker-primary.png";

const enhancer = compose();

BrandMarker.propTypes = {
  size: PropTypes.number,
  accent: PropTypes.bool,

  position: PropTypes.object.isRequired,
};

BrandMarker.defaultProps = {
  size: 32,
  accent: true,
};

function BrandMarker(props) {
  const { size, accent, ...markerProps } = props;

  return (
    <Marker
      {...markerProps}
      icon={
        <MarkerIcon
          anchor={{ y: size, x: size / 2 }}
          size={{ width: 128, height: 128 }}
          scaledSize={{ width: size, height: size }}
          url={accent ? MarkerAccent : MarkerPrimary}
        />
      }
    />
  );
}

export default enhancer(BrandMarker);
