import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Avatar } from "@material-ui/core";
import { connect } from "react-redux";
import { getIsRTL } from "../../reducers/LocalizationReducer";
import {
  ADVANCE_BOOK,
  CARD,
  CASH,
  COMBINED,
  CREDIT_BALANCE,
  NON_PAYMENT,
} from "../../constants/OrderPaymentTypes";
import cash from "./assets/cash.svg";
import creditCard from "./assets/card.svg";
import creditBalance from "./assets/credit.svg";
import combined from "./assets/combined.svg";
import advBook from "./assets/advbook.svg";
import nonPayment from "./assets/bezoplati.svg";

export const paymentTypeIcons = {
  [CASH]: cash,
  [CREDIT_BALANCE]: creditBalance,
  [CARD]: creditCard,
  [COMBINED]: combined,
  [ADVANCE_BOOK]: advBook,
  [NON_PAYMENT]: nonPayment,
};

const enhancer = compose(
  connect(state => ({
    isRTL: getIsRTL(state),
  })),
  useSheet({
    container: {
      width: "200px",
      display: "table",
      paddingLeft: props => (!props.isRTL ? "0" : "1em"),
      paddingRight: props => (props.isRTL ? "0" : "1em"),
      alignItems: "center",
    },
    avatar: {
      width: "36px",
      borderRadius: "50%",
      textAlign: "center",
      display: "table-cell",
      textTransform: "uppercase",
      color: "white",
      verticalAlign: "middle",
    },
    nameBox: {
      display: "table-cell",
      verticalAlign: "middle",
      textAlign: props => (!props.isRTL ? "left" : "right"),
      paddingLeft: props => (!props.isRTL ? "1em" : "0"),
      paddingRight: props => (props.isRTL ? "1em" : "0"),
      fontSize: "1em",
    },
  }),
);

PaymentTypeAvatarAndInfo.propTypes = {
  sheet: PropTypes.object,
  paymentType: PropTypes.oneOf([
    CASH,
    NON_PAYMENT,
    CARD,
    COMBINED,
    ADVANCE_BOOK,
    CREDIT_BALANCE,
  ]).isRequired,
  avatarSize: PropTypes.number,
  children: PropTypes.node,
  style: PropTypes.object,
};

PaymentTypeAvatarAndInfo.defaultProps = {
  avatarSize: 36,
};

function PaymentTypeAvatarAndInfo({
  sheet: { classes },
  paymentType,
  avatarSize,
  children,
  style,
}) {
  return (
    <div className={classes.container} style={style}>
      <div className={classes.avatar}>
        <Avatar src={paymentTypeIcons[paymentType]} size={avatarSize} />
      </div>
      <div className={classes.nameBox}>{children}</div>
    </div>
  );
}

export default enhancer(PaymentTypeAvatarAndInfo);
