import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Marker, MarkerIcon } from "react-google-map-components";
import CarRed from "./assets/car_red.png";
import CarGreen from "./assets/car_green.png";

const enhancer = compose();

BrandMarkerCar.propTypes = {
  size: PropTypes.number,
  accent: PropTypes.bool,

  position: PropTypes.object.isRequired,
};

BrandMarkerCar.defaultProps = {
  size: 32,
  accent: true,
};

function BrandMarkerCar(props) {
  const { size, accent, ...markerProps } = props;

  return (
    <Marker
      {...markerProps}
      icon={
        <MarkerIcon
          anchor={{ y: size, x: size / 2 }}
          size={{ width: 128, height: 128 }}
          scaledSize={{ width: 32 }}
          url={accent ? CarGreen : CarRed}
        />
      }
    />
  );
}

export default enhancer(BrandMarkerCar);
