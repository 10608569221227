import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withContext, withHandlers } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import {
  Card,
  Dialog,
  CardContent,
  CardHeader,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import FormCheckbox from "../form/FormCheckbox";
import FlexBox, { JUSTIFY_CENTER } from "../ui-core/FlexBox";
import ButtonForm from "../ui-core/ButtonForm";
import PageLoading from "../ui-core/PageLoading";
import { formatText } from "../../helpers/FormatUtils";
import { stringifyArray } from "../../helpers/SerializeUtils";
import { getMessages } from "../../reducers/LocalizationReducer";
import { CREATE_DYNAMIC_ORDER_CSV_URL } from "../../../shared/constants/FileProxyControllerConstants";

const valueSelector = formValueSelector("DynamicCSVForm");

const enhancer = compose(
  withTheme,
  useSheet({
    dialogTitle: {
      color: props => props.theme.palette.appBarTextColor,
      minWidth: "1000px",
      backgroundColor: props => props.theme.palette.primary.main,
    },
    paper: {
      minHeight: "auto",
    },
  }),
  withHandlers({
    onSubmit: props => values => {
      const columns = [];
      fromJS(values).forEach((value, key) =>
        value ? columns.push(fp.toUpper(key)) : null,
      );
      return props.onSubmit(stringifyArray(columns));
    },
  }),
  withContext(
    {
      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,
    },
    props => ({
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
    }),
  ),
  connect(state => {
    const columns = [];
    const values = valueSelector(state, "columns");
    if (values)
      fromJS(values).forEach((value, key) =>
        value ? columns.push(fp.toUpper(key)) : null,
      );
    return {
      columnsArray: columns,
      columns: stringifyArray(columns),
      i18n: getMessages(state),
    };
  }),
  reduxForm({
    form: "DynamicCSVForm",
  }),
);

DynamicCSVForm.propTypes = {
  classes: PropTypes.object,
  columns: PropTypes.string,
  onDismiss: PropTypes.func,
  submitting: PropTypes.bool,
  dirty: PropTypes.bool,
  reset: PropTypes.func,
  dynamicFields: PropTypes.instanceOf(List),
  createDownloadCSVHref: PropTypes.func,
  saveDefaultColumns: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),
  change: PropTypes.func,
  columnsArray: PropTypes.array,
};

function DynamicCSVForm(props) {
  const { columns, i18n, classes, columnsArray } = props;

  return (
    <Dialog open={true} onClose={props.onDismiss} maxWidth="auto">
      <DialogTitle className={classes.dialogTitle}>
        {i18n.get("download_dynamic_csv", "Download Dynamic Csv")}
      </DialogTitle>
      <DialogContent className={classes.paper}>
        <FlexBox
          element={<form autoComplete="off" />}
          style={{ flexWrap: "wrap" }}
          justify={JUSTIFY_CENTER}
        >
          <PageLoading isLoading={props.submitting} />
          {props.dynamicFields &&
            props.dynamicFields.map((groups, index) =>
              groups
                .map((group, key) => {
                  const groupArray = group.map(item => item.get("code"));
                  const newColumns = columnsArray.filter(column =>
                    groupArray.includes(column),
                  );
                  return (
                    <Card
                      key={`${key}-${index}`}
                      initiallyExpanded={true}
                      style={{
                        flex: "1 1 auto",
                        margin: ".5rem",
                        boxShadow:
                          "rgb(0 0 0 / 12%) 0px 1px 6px, rgb(0 0 0 / 12%) 0px 1px 4px",
                      }}
                    >
                      <CardHeader
                        title={formatText(key)}
                        actAsExpander={true}
                        showExpandableButton={true}
                      />
                      <CardContent expandable={true}>
                        <FlexBox direction="column">
                          <FormControlLabel
                            control={
                              <Checkbox
                                style={{
                                  paddingBottom: 15,
                                }}
                                checked={newColumns.length === group.size}
                                onChange={(e, x) =>
                                  group.map(field =>
                                    props.change(
                                      `columns.${fp.toLower(
                                        field.get("code"),
                                      )}`,
                                      x,
                                    ),
                                  )
                                }
                              />
                            }
                            label={i18n.get("all", "All")}
                          />
                          <hr />
                          {group &&
                            group.map(field => (
                              <FormCheckbox
                                key={field}
                                name={`columns.${fp.toLower(
                                  field.get("code"),
                                )}`}
                                label={formatText(field.get("text"))}
                              />
                            ))}
                        </FlexBox>
                      </CardContent>
                    </Card>
                  );
                })
                .toArray(),
            )}
        </FlexBox>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => props.saveDefaultColumns(columns)}
          primary={true}
        >
          {i18n.get("save_default", "Save as a Default")}
        </Button>
        <Button onClick={props.onDismiss} primary={true}>
          {i18n.get("dismiss", "Dismiss")}
        </Button>
        {props.dirty && (
          <Button onClick={props.reset} primary={true}>
            {i18n.get("reset", "Reset")}
          </Button>
        )}
        <ButtonForm
          url={CREATE_DYNAMIC_ORDER_CSV_URL}
          label={i18n.get("download", "Download")}
          target="_blank"
          containerElement="a"
          params={props.createDownloadCSVHref(columns)}
        />
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(DynamicCSVForm);
