import React from "react";
import { Iterable } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { componentFromStream, compose } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { connect } from "react-redux";
import { isEqualData, isEqualDataIn } from "../../helpers/DataUtils";
import { getIsRTL } from "../../reducers/LocalizationReducer";
import { Autocomplete } from "@material-ui/lab";
import { Chip, TextField } from "@material-ui/core";

const enhancer = compose(
  connect(state => ({
    isRTL: getIsRTL(state),
  })),
  useSheet({
    chipInputRTL: {
      "& svg": {
        marginRight: "-8px",
        marginLeft: "4px",
      },
      '& div[style*="float"]': {
        float: "right",
      },
    },
  }),
);
const ChipAutoComplete = componentFromStream(propsStream => {
  const valueStream = propsStream
    .distinctUntilChanged(isEqualDataIn("input.value"))
    .map(props => {
      const mapValues = fp.map(value => ({
        text: props.formatOption(value),
        value,
      }));

      return mapValues(props.input.value);
    });

  const dataSourceStream = propsStream
    .distinctUntilKeyChanged("options", isEqualData)
    .map(props =>
      props.options
        .map(value => ({ text: props.formatOption(value), value }))
        .toArray(),
    );

  return propsStream
    .map(fp.omit(["options", "formatOption"]))
    .combineLatest(
      valueStream,
      dataSourceStream,
      (
        {
          input,
          meta,
          maxHeight,
          canAutoPosition,
          hintText,
          popoverProps,
          ...props
        },
        value,
        dataSource,
      ) => {
        const options = () => {
          const arr = [];
          const values = value.map(item => item.value);
          dataSource.forEach(item => {
            if (!values.includes(item.value)) arr.push(item);
          });
          return arr;
        };

        return (
          <Autocomplete
            multiple={true}
            value={value}
            onChange={(_, newValue) => {
              input.onChange(newValue.map(v => v.value));
            }}
            fullWidth={true}
            options={options()}
            getOptionLabel={option => option.text}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip label={option.text} {...getTagProps({ index })} />
              ))
            }
            renderInput={params => (
              <TextField
                {...params}
                variant={props.variant}
                size={props.size}
                label={props.label}
                placeholder={props.hintText}
                margin={props.margin}
                error={!meta.active && meta.touched && meta.error}
                helperText={!meta.active && meta.touched && meta.error}
              />
            )}
          />
        );
      },
    );
});
FormChipAutoComplete.propTypes = {
  fullWidth: PropTypes.bool,
  autoWidth: PropTypes.bool,
  maxHeight: PropTypes.number,
  inputStyle: PropTypes.object,
  chipContainerStyle: PropTypes.object,
  openOnFocus: PropTypes.bool,
  clearOnBlur: PropTypes.bool,
  canAutoPosition: PropTypes.bool,
  maxSearchResults: PropTypes.number,
  formatOption: PropTypes.func,
  options: PropTypes.instanceOf(Iterable).isRequired,
  style: PropTypes.object,
  variant: PropTypes.oneOf(["standard", "outlined", "filled"]),
  size: PropTypes.oneOf(["medium", "small"]),
  margin: PropTypes.oneOf(["none", "normal"]),
  validate: PropTypes.func,
  placeholder: PropTypes.node,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
};

FormChipAutoComplete.defaultProps = {
  maxHeight: 320,
  openOnFocus: true,
  canAutoPosition: true,
  formatOption: fp.identity,
  variant: "outlined",
  size: "small",
};

function FormChipAutoComplete(props) {
  return <Field {...props} component={ChipAutoComplete} />;
}
export default enhancer(FormChipAutoComplete);
