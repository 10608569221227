import { Observable } from "rxjs";
import React, { useEffect, useState } from "react";
import { fromJS, List, Map, Set } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  createEventHandler,
  getContext,
  mapPropsStream,
} from "recompose";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { connect } from "react-redux";
import { blue, grey, red } from "@material-ui/core/colors";
import FlexBox from "../ui-core/FlexBox";
import LinkButton from "../ui-core/LinkButton";
import PageLoading from "../ui-core/PageLoading";
import SelectField from "../deprecated/SelectField";
import {
  getValue,
  isEqualData,
  isEqualWithoutFunctions,
} from "../../helpers/DataUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { pipeStreams } from "../../helpers/StreamUtils";
import { toCommunicationFilter } from "../../helpers/CommunicationFilterMapper";
import { getIsRTL, getMessage } from "../../reducers/LocalizationReducer";
import { getServiceTypes2 } from "../../api/admin/AdminServiceTypesApi";
import { getOrderedCities2 } from "../../api/shared/CountryApi";
import ResponseError from "../../../client/helpers/ResponseError";
import {
  deleteCommunicationMessage,
  doNotSentMessage,
  getCachedCommunicationsMeta,
} from "../../api/shared/CommunicationApi";
import _ from "lodash";
import TransportationType from "../../constants/TransportationType";
import IconButton from "@material-ui/core/IconButton";
import { Close } from "@material-ui/icons";
import { formatText } from "../../helpers/FormatUtils";

export const BY_EVENTS = "by_events";
export const BY_TARGET = "by_target";

export const CUSTOMER = "CUSTOMER";
export const SUPPLIER = "SUPPLIER";
export const ADMIN = "ADMIN";

const DEFAULT_TYPE = "Default";
const CUSTOM_TYPE = "Custom";
const DO_NOT_SEND_TYPE = "Do Not Send";

// const EventCourierTypes = OrderedSet.of(
//   DEFAULT,
//   SAVER,
//   NEXT_DAY,
//   SAME_DAY,
//   URGENT,
// );

export const notificationWarehouseType = [
  "TO_WAREHOUSE",
  "FROM_WAREHOUSE",
  "CURRENT_WAREHOUSE",
];

const filterOptions = [
  { value: BY_EVENTS, label: "Events" },
  { value: BY_TARGET, label: "Target" },
];

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    isRTL: getIsRTL(state),
  })),
  useSheet({
    container: { flex: 1, overflowY: "auto" },
    messageLink: { color: "#4169e1" },
  }),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const filterStream = propsStream
          .distinctUntilChanged(isEqualData)
          .map(props => ({
            ...props.location.query,
            entityType: props.entityType,
            entityID: props.entityId,
            courier_type: "default",
            notification_warehouse_type:
              props.location.query.notificationWarehouseType,
          }))
          .map(toCommunicationFilter)
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(filterStream, (props, filter) => ({
          ...props,
          filter,
        }));
      },
      propsStream => {
        const {
          handler: onChangeMessageType,
          stream: onChangeMessageTypeStream,
        } = createEventHandler();
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const changeMessageTypeStream = onChangeMessageTypeStream
          .withLatestFrom(propsStream)
          .do(([{ event, messageId, customData }, props]) => {
            switch (event) {
              case CUSTOM_TYPE: {
                if (messageId) {
                  props.onItemClick(messageId);
                } else {
                  props.onCreateClick(customData);
                }

                break;
              }

              case DO_NOT_SEND_TYPE:
                if (!messageId) {
                  doNotSentMessage({
                    ...toSnakeCase(customData),
                    entity_type: props.entityType,
                    entity_id: fp.toFinite(props.entityId),
                  })
                    .catch(ResponseError.throw)
                    .then(onRequestRefresh);
                }

                break;

              default:
                if (messageId) {
                  deleteCommunicationMessage(messageId).then(onRequestRefresh);
                }
            }
          })
          .startWith(null);

        const messagesListStream = propsStream
          .distinctUntilChanged(isEqualData)
          .filter(
            ({ filter }) =>
              filter.getValue("eventType") ||
              filter.getValue("toJurisdictionId") ||
              (filter.getValue("eventTarget") &&
                !filter.getValue("communicationItem")),
          )
          .switchMap(props =>
            props
              .getCommunicationList(props.filter.setPageSize(500))
              .repeatWhen(() => onRequestRefreshStream)
              .catch(ResponseError.throw),
          )
          .startWith({})
          .map(
            fp.flow(
              response => fromJS(response),
              response =>
                Map({
                  list: Map().withMutations(map => {
                    response
                      .getIn(["payload", "data", "list"], List())
                      .forEach(item => {
                        map
                          .setIn(
                            [
                              BY_EVENTS,
                              item.get("target"),
                              item.get("message_type"),
                            ],
                            item,
                          )
                          .setIn(
                            [
                              BY_TARGET,
                              item.get("type"),
                              item.get("message_type"),
                            ],
                            item,
                          );
                      });
                  }),
                  isLoading: response.get("pending", false),
                }),
            ),
          )
          .distinctUntilChanged(isEqualData);

        const communicationMetaResponseStream = propsStream
          .distinctUntilChanged(isEqualData)
          .switchMap(() =>
            getCachedCommunicationsMeta().catch(error =>
              Observable.of({ error }),
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(
            messagesListStream,
            communicationMetaResponseStream,
            changeMessageTypeStream,
            (props, messagesList, communicationMetaResponse) => ({
              ...props,
              eventTargets: communicationMetaResponse.get("targets"),
              eventTypes: communicationMetaResponse.get("eventTypes"),
              messageTypes: communicationMetaResponse.get("messageTypes"),
              onChangeMessageType,
              messages: messagesList.get("list"),
              isLoading: messagesList.get("isLoading"),
            }),
          )
          .distinctUntilChanged(isEqualWithoutFunctions);
      },
    ),
  ),
);

const CommunicationSettingsTable = props => {
  const {
    location: { query },
    classes,
    getLocalisationMessage,
  } = props;

  const filterType = getValue(query, "filterType");
  let filterSelect = List();
  let filterValue;
  let toCityValue;
  const warehouseTypeValue = query.notificationWarehouseType
    ? query.notificationWarehouseType
    : null;
  const transportationTypeQuery = query.transportationType
    ? query.transportationType
    : null;
  let isEventsFilter;
  let headersList = [];
  const isRTL = false;
  const tableList = [];
  const colCount = props.messageTypes.size + 1;
  const courierType = query.eventCourierType ? query.eventCourierType : null;
  const [serviceTypes, setServiceTypes] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(
    () =>
      getServiceTypes2().then(res => {
        setServiceTypes(_.get(res, "data"));
      }),
    [],
  );
  useEffect(
    () =>
      getOrderedCities2().then(res => {
        setCities(_.get(res, "data"));
      }),
    [],
  );

  if (filterType) {
    isEventsFilter = filterType === BY_EVENTS;

    filterSelect = isEventsFilter ? props.eventTypes : props.eventTargets;
    const filterTable = isEventsFilter ? props.eventTargets : props.eventTypes;

    toCityValue = query.toJurisdictionId ? query.toJurisdictionId : null;
    filterValue = isEventsFilter
      ? getValue(query, "eventType")
      : getValue(query, "eventTarget");

    if (props.messages && filterValue) {
      filterTable.forEach((item, index) => {
        const tableRow = [];

        headersList = [];

        tableRow.push(
          <TableCell key={index}>
            {(item.get("description") &&
              getLocalisationMessage(item.get("description").toLowerCase())) ||
              item.get("name")}
          </TableCell>,
        );

        props.messageTypes.forEach((itemEvent, indexEvent) => {
          const message = props.messages.getIn([
            filterType,
            item.get("description"),
            itemEvent.get("description"),
          ]);
          let messageType = DEFAULT_TYPE;

          if (message) {
            messageType = CUSTOM_TYPE;
          }

          if (message && message.get("do_not_sent")) {
            messageType = DO_NOT_SEND_TYPE;
          }

          tableRow.push(
            props.entityType !== CUSTOMER ? (
              <TableCell key={indexEvent}>
                {message ? (
                  <LinkButton
                    className={classes.messageLink}
                    onClick={() => props.onItemClick(message.get("id"))}
                  >
                    {message.get("name") ||
                      message.get("subject") ||
                      getLocalisationMessage("edit_message", "Edit Message")}
                  </LinkButton>
                ) : (
                  <LinkButton
                    onClick={() =>
                      props.onCreateClick({
                        type: isEventsFilter
                          ? filterValue
                          : item.get("description"),
                        target: isEventsFilter
                          ? item.get("description")
                          : filterValue,
                        messageType: itemEvent.get("description"),
                      })
                    }
                  >
                    {getLocalisationMessage("add_message", "Add Message")}
                  </LinkButton>
                )}
              </TableCell>
            ) : (
              <TableCell key={indexEvent}>
                <FormControl
                  fullWidth={true}
                  size="small"
                  variant="outlined"
                  style={{ minWidth: "120px" }}
                >
                  <SelectField
                    onChange={event => {
                      props.onChangeMessageType({
                        event,
                        messageId: message && message.get("id"),
                        customData: {
                          type: isEventsFilter
                            ? filterValue
                            : item.get("description"),
                          target: isEventsFilter
                            ? item.get("description")
                            : filterValue,
                          messageType: itemEvent.get("description"),
                        },
                      });
                    }}
                    fullWidth={true}
                    style={{
                      paddingLeft: !isRTL ? "0" : "56px",
                      paddingRight: isRTL ? "0" : "56px",
                      textAlign: isRTL ? "right" : "left",
                      height: "35px",
                      fontWeight: "500",
                      color:
                        messageType === CUSTOM_TYPE
                          ? blue[500]
                          : messageType === DO_NOT_SEND_TYPE
                          ? red[500]
                          : grey[500],
                    }}
                    menuItemStyle={{ textAlign: isRTL ? "right" : "left" }}
                    iconStyle={{
                      left: !isRTL ? "auto" : "0",
                      right: isRTL ? "auto" : "0",
                    }}
                    floatingLabelStyle={{
                      right: isRTL ? "0" : "auto",
                    }}
                    underlineStyle={{ display: "none" }}
                    value={messageType}
                    options={Set.of(
                      DEFAULT_TYPE,
                      CUSTOM_TYPE,
                      DO_NOT_SEND_TYPE,
                    )}
                    formatOption={option => {
                      if (option === DEFAULT_TYPE) {
                        return (
                          getLocalisationMessage("default", "Default") ||
                          DEFAULT_TYPE
                        );
                      }
                      if (option === CUSTOM_TYPE) {
                        return (
                          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                          <div
                            onClick={() => {
                              props.onItemClick(message && message.get("id"));
                            }}
                          >
                            {getLocalisationMessage("custom", "Custom") ||
                              CUSTOM_TYPE}
                          </div>
                        );
                      }
                      if (option === DO_NOT_SEND_TYPE) {
                        return (
                          getLocalisationMessage(
                            "do_not_send",
                            "Do Not Send",
                          ) || DO_NOT_SEND_TYPE
                        );
                      }
                      return option;
                    }}
                  />
                </FormControl>
              </TableCell>
            ),
          );

          headersList.push(
            <TableCell key={indexEvent}>
              {getLocalisationMessage(
                itemEvent.get("description").toLowerCase(),
              ) || itemEvent.get("name")}
            </TableCell>,
          );
        });

        tableList.push(<TableRow key={index}>{tableRow}</TableRow>);
      });
    }
  }

  return (
    <Card
      className={classes.container}
      style={props.customerIsLoading ? { paddingBottom: 30 } : {}}
    >
      {props.customerIsLoading && (
        <CardHeader
          subheader={
            props.customerIsLoading
              ? getLocalisationMessage(
                  "notification_set_up",
                  "Notification set up",
                )
              : getLocalisationMessage("notifications", "Notifications")
          }
        />
      )}
      <CardContent>
        {!props.customerIsLoading && (
          <PageLoading isLoading={props.isLoading} />
        )}
        <Table fixedHeader={false} fixedFooter={true} selectable={false}>
          <TableHead displaySelectAll={false} adjustForCheckbox={false}>
            <TableRow>
              <TableCell colSpan={colCount}>
                <FlexBox gutter={8}>
                  <FlexBox flex={true}>
                    <FlexBox gutter={16}>
                      <FlexBox flex={true} justify="center" align="center">
                        <FormControl
                          fullWidth={true}
                          size="small"
                          variant="outlined"
                          style={{ minWidth: "120px" }}
                        >
                          <InputLabel
                            style={{
                              maxWidth: "85px",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {getLocalisationMessage("filter_by")}
                          </InputLabel>
                          <Select
                            label={getLocalisationMessage("filter_by")}
                            value={filterType}
                            onChange={e => props.onFilterChange(e.target.value)}
                          >
                            {filterOptions.map((v, i) => (
                              <MenuItem key={i} value={_.get(v, "value")}>
                                {getLocalisationMessage(_.get(v, "label"))}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </FlexBox>
                      {filterType && (
                        <FlexBox flex={true} justify="center" align="center">
                          <FormControl
                            fullWidth={true}
                            size="small"
                            variant="outlined"
                            style={{ minWidth: "120px" }}
                          >
                            <InputLabel>
                              {getLocalisationMessage(
                                isEventsFilter ? "event" : "target",
                              )}
                            </InputLabel>
                            <Select
                              label={getLocalisationMessage(
                                isEventsFilter ? "event" : "target",
                              )}
                              fullWidth={true}
                              autoWidth={true}
                              value={filterValue}
                              onChange={e =>
                                props.onEventChange(filterType, e.target.value)
                              }
                            >
                              {filterSelect.map((v, i) => (
                                <MenuItem key={i} value={v.get("description")}>
                                  {getLocalisationMessage(
                                    v.get("description").toLowerCase(),
                                  )}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </FlexBox>
                      )}

                      {Boolean(
                        props.onChangeFilter && filterType && filterValue,
                      ) && (
                        <FlexBox
                          flex={true}
                          style={{ gap: 8 }}
                          direction="row"
                          justify="center"
                          align="center"
                        >
                          <FormControl
                            fullWidth={true}
                            size="small"
                            variant="outlined"
                            style={{ minWidth: "120px" }}
                          >
                            <InputLabel>
                              {getLocalisationMessage("courier_type")}
                            </InputLabel>
                            <Select
                              disabled={serviceTypes.length === 0}
                              label={getLocalisationMessage("courier_type")}
                              value={courierType}
                              onChange={e =>
                                props.onChangeFilter("courier", e.target.value)
                              }
                            >
                              {serviceTypes.map(i => (
                                <MenuItem key={i.id} value={i.code}>
                                  {getLocalisationMessage(i.name)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <IconButton
                            onClick={() =>
                              props.setLocationQuery(
                                fp.unset("eventCourierType"),
                              )
                            }
                          >
                            <Close />
                          </IconButton>
                        </FlexBox>
                      )}
                      {Boolean(
                        props.onChangeFilter && filterType && filterValue,
                      ) && (
                        <FlexBox
                          flex={true}
                          style={{ gap: 8 }}
                          direction="row"
                          justify="center"
                          align="center"
                        >
                          <FormControl
                            fullWidth={true}
                            size="small"
                            variant="outlined"
                            style={{ minWidth: "120px" }}
                          >
                            <InputLabel
                              style={{
                                maxWidth: "85px",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {getLocalisationMessage("transportation_type")}
                            </InputLabel>
                            <Select
                              label={getLocalisationMessage(
                                "transportation_type",
                              )}
                              value={transportationTypeQuery}
                              onChange={e =>
                                props.onChangeFilter(
                                  "transportationType",
                                  e.target.value,
                                )
                              }
                            >
                              {TransportationType.map(i => (
                                <MenuItem key={i} value={i}>
                                  {getLocalisationMessage(i)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <IconButton
                            onClick={() =>
                              props.setLocationQuery(
                                fp.unset("transportationType"),
                              )
                            }
                          >
                            <Close />
                          </IconButton>
                        </FlexBox>
                      )}
                      {Boolean(
                        props.onChangeFilter && filterType && filterValue,
                      ) && (
                        <FlexBox
                          flex={true}
                          style={{ gap: 8 }}
                          direction="row"
                          justify="center"
                          align="center"
                        >
                          <FormControl
                            fullWidth={true}
                            size="small"
                            variant="outlined"
                            style={{ minWidth: "120px" }}
                          >
                            <InputLabel>
                              {getLocalisationMessage("city")}
                            </InputLabel>
                            <Select
                              disabled={cities.length === 0}
                              label={getLocalisationMessage("city")}
                              value={toCityValue}
                              onChange={e =>
                                props.onChangeFilter(
                                  "toJurisdictionId",
                                  e.target.value,
                                )
                              }
                            >
                              {cities.map(i => (
                                <MenuItem key={i.id} value={i.id}>
                                  {i.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <IconButton
                            onClick={() =>
                              props.setLocationQuery(
                                fp.unset("toJurisdictionId"),
                              )
                            }
                          >
                            <Close />
                          </IconButton>
                        </FlexBox>
                      )}
                      {Boolean(
                        props.onChangeFilter && filterType && filterValue,
                      ) && (
                        <FlexBox
                          flex={true}
                          style={{ gap: 8 }}
                          direction="row"
                          justify="center"
                          align="center"
                        >
                          <FormControl
                            fullWidth={true}
                            size="small"
                            variant="outlined"
                            style={{ minWidth: "120px" }}
                          >
                            <InputLabel>
                              {getLocalisationMessage("warehouse_type")}
                            </InputLabel>
                            <Select
                              disabled={notificationWarehouseType.length === 0}
                              label={getLocalisationMessage("warehouse_type")}
                              value={warehouseTypeValue}
                              onChange={e =>
                                props.onChangeFilter(
                                  "notificationWarehouseType",
                                  e.target.value,
                                )
                              }
                            >
                              {notificationWarehouseType.map((i, index) => (
                                <MenuItem key={index} value={i}>
                                  {getLocalisationMessage(
                                    i.toLowerCase(),
                                    formatText(i),
                                  )}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <IconButton
                            onClick={() =>
                              props.setLocationQuery(
                                fp.unset("notificationWarehouseType"),
                              )
                            }
                          >
                            <Close />
                          </IconButton>
                        </FlexBox>
                      )}
                    </FlexBox>
                  </FlexBox>

                  {Boolean(
                    filterType &&
                      filterValue &&
                      (props.doNotSentAllEvents || props.makeDefaultAllEvents),
                  ) && (
                    <FlexBox>
                      <FlexBox gutter={8}>
                        {props.doNotSentAllEvents && (
                          <FlexBox align="center">
                            <Button
                              onClick={props.doNotSentAllEvents}
                              label={getLocalisationMessage(
                                "do_not_sent_all",
                                "Do Not Sent All",
                              )}
                            />
                          </FlexBox>
                        )}

                        {props.makeDefaultAllEvents && (
                          <FlexBox align="center">
                            <Button
                              onClick={props.makeDefaultAllEvents}
                              label={getLocalisationMessage(
                                "make_default_all",
                                "Make Default All",
                              )}
                            />
                          </FlexBox>
                        )}
                      </FlexBox>
                    </FlexBox>
                  )}
                </FlexBox>
              </TableCell>
            </TableRow>
            <TableRow>
              {props.messages && filterValue ? <TableCell /> : null}
              {headersList}
            </TableRow>
          </TableHead>
          <TableBody displayRowCheckbox={false}>{tableList}</TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};
CommunicationSettingsTable.propTypes = {
  classes: PropTypes.object,
  location: PropTypes.object,
  messages: PropTypes.instanceOf(Map),
  eventTypes: PropTypes.instanceOf(List),
  messageTypes: PropTypes.instanceOf(List),
  eventTargets: PropTypes.instanceOf(List),
  onFilterChange: PropTypes.func,
  onEventChange: PropTypes.func,
  onChangeMessageType: PropTypes.func,
  isLoading: PropTypes.bool,
  customerIsLoading: PropTypes.bool,
  entityType: PropTypes.oneOf([CUSTOMER, SUPPLIER]),
  onItemClick: PropTypes.func,
  onCreateClick: PropTypes.func,
  doNotSentAllEvents: PropTypes.func,
  makeDefaultAllEvents: PropTypes.func,
  onChangeFilter: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  setLocationQuery: PropTypes.func,
};
export default enhancer(CommunicationSettingsTable);
