import React from "react";
import { Map } from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Card, makeStyles, withTheme } from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox, {
  ALIGN_START,
  JUSTIFY_CENTER,
  JUSTIFY_SPACE_AROUND,
  JUSTIFY_SPACE_BETWEEN,
} from "../../ui-core/FlexBox";
import { getIsRTL, getMessage } from "../../../reducers/LocalizationReducer";
import UserAvatar from "../../avatars/UserAvatar";
import {
  CallMade,
  FitnessCenter,
  FlightTakeoff,
  LocalAtmOutlined,
  MailOutline,
  Notes,
  PhoneAndroid,
} from "@material-ui/icons";
import { money } from "../../orders-core/AdminPostForm";
import { toJS } from "../../../helpers/DataUtils";
import {
  COD,
  COD_FEE,
  CUSTOMS_FEE,
  CUSTOMS_FEE_PERCENTAGE,
  CUSTOMS_FEE_V2,
  FORWARDING_FEE,
  INSURANCE_FEE,
  NOTIFICATION_FEE,
  RTO,
  RTO_INTERNATI0NAL,
  SERVICE,
  SERVICE_ADDED,
} from "../../../constants/OrderChargeItemTypes";
import {
  isAndroid,
  isExcelImport,
  isIOS,
  isIPS,
  isMagentoV1,
  isMagentoV2,
  isOpencart,
  isShopify,
  isTelegram,
  isWeb,
  isWordpress,
} from "../../../helpers/OrderHelper";
import { SIMPLE } from "../../../constants/TransportationType";
import { formatText } from "../../../helpers/FormatUtils";

export function formatTransportationTypesLocalised(
  code,
  getLocalisationMessage,
  notAvailableValue = "N/A",
) {
  switch (code) {
    case SIMPLE:
      return (
        (getLocalisationMessage &&
          getLocalisationMessage("simple_type", "Simple")) ||
        "Simple"
      );

    default:
      return (
        (getLocalisationMessage && getLocalisationMessage(code)) ||
        formatText(code) ||
        notAvailableValue
      );
  }
}

const useStyles = makeStyles({
  container: {
    paddingBottom: "1.5rem",
    flex: "1 1 0%",
  },
  card: {
    color: "rgba(38, 50, 56, 0.7)",
    maxHeight: "550px",
    width: "100%",
    padding: "1.5rem",
    boxShadow: "0px 10px 30px #E2E2E2",
  },
  cardHeader: {
    fontSize: "1rem",
    color: "#263238",
  },
  cardWrap: {
    padding: "0 .5rem",
  },
  postcodeWrap: {
    backgroundColor: "rgba(243, 214, 109, 0.38)",
    padding: "0 .5rem",
    borderRadius: "4px",
    height: "max-content",
    maxWidth: "200px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
});
const checkDevice = (v, x) =>
  isIOS(v.get("device_details"))
    ? x("ios_app")
    : isAndroid(v.get("device_details"))
    ? x("android_app")
    : isExcelImport(v.get("device_details"))
    ? x("excel_import")
    : isMagentoV1(v.get("device_details"))
    ? x("magento_v1_extension")
    : isMagentoV2(v.get("device_details"))
    ? x("magento_v2_extension")
    : isOpencart(v.get("device_details"))
    ? x("opencart_extension")
    : isShopify(v.get("device_details"))
    ? x("shopify_app")
    : isWordpress(v.get("device_details"))
    ? x("wordpress_plugin")
    : isWeb(v.get("device_details"))
    ? x("web")
    : isTelegram(v.get("device_details"))
    ? x("telegram")
    : isIPS(v.get("device_details"))
    ? x("ips")
    : x("other");
const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    isRTL: getIsRTL(state),
  })),
  withTheme,
);

OrderDetailsDialogBriefV2.propTypes = {
  order: PropTypes.instanceOf(Map).isRequired,
  getLocalisationMessage: PropTypes.func,
  isCustomer: PropTypes.bool,
};

function OrderDetailsDialogBriefV2(props) {
  const { card, cardHeader, cardWrap, container, postcodeWrap } = useStyles();
  const { order, getLocalisationMessage, isCustomer } = props;
  const payType = order.get("payment_type");
  const customChargeItems = [];
  const addedServices = {
    sum: 0,
    payment_type: "na",
    paid: false,
  };
  order.get("charge_items").forEach(i => {
    if (i.get("charge") > 0) {
      if (
        i.get("charge_type") === INSURANCE_FEE ||
        i.get("charge_type") === CUSTOMS_FEE ||
        i.get("charge_type") === FORWARDING_FEE ||
        i.get("charge_type") === SERVICE ||
        i.get("charge_type") === RTO ||
        i.get("charge_type") === COD_FEE ||
        i.get("charge_type") === NOTIFICATION_FEE ||
        i.get("charge_type") === COD ||
        i.get("charge_type") === CUSTOMS_FEE_V2 ||
        i.get("charge_type") === CUSTOMS_FEE_PERCENTAGE ||
        i.get("charge_type") === RTO_INTERNATI0NAL
      ) {
        customChargeItems.push(toJS(i));
      } else if (i.get("charge_type") === SERVICE_ADDED) {
        addedServices.sum += i.get("charge");
        addedServices.payment_type = i.get("payment_type");
        addedServices.paid = i.get("paid");
      }
    }
  });
  return (
    <FlexBox className={container} justify={JUSTIFY_SPACE_BETWEEN}>
      <FlexBox style={{ width: "32%" }} className={cardWrap}>
        <Card className={card}>
          <FlexBox justify={JUSTIFY_SPACE_AROUND}>
            <FlexBox align={ALIGN_START}>
              <UserAvatar
                name={order.getIn(["sender_data", "name"])}
                style={{ width: "2rem", height: "2rem" }}
              />
              <span style={{ paddingLeft: "1rem" }}>
                {getLocalisationMessage("sender", "Sender")}
              </span>
            </FlexBox>
            <span className={postcodeWrap}>
              {getLocalisationMessage("postcode", "Postcode")}:
              {order.getIn(["sender_data", "postcode", "name"])}
            </span>
          </FlexBox>
          <FlexBox>
            <span
              style={{
                marginLeft: "3rem",
                fontWeight: "500",
                fontSize: "19px",
              }}
            >
              {order.getIn(["sender_data", "name"])}
            </span>
          </FlexBox>
          <FlexBox>
            <span style={{ marginLeft: "3rem" }}>
              {order.getIn(["sender_data", "phone"])}
            </span>
          </FlexBox>
          <FlexBox>
            <span style={{ marginLeft: "3rem" }}>
              <CallMade
                fontSize="small"
                style={{
                  color: "#A8ADAF",
                  fontSize: "1rem",
                  transform: "rotate(0deg)",
                  marginRight: ".5rem",
                }}
              />
              {`${order.getIn(["sender_data", "jurisdiction", "name"])} 
              (${getLocalisationMessage(
                order.getIn(["sender_data", "jurisdiction", "level_name"]),
              )})`}
            </span>
          </FlexBox>
          <FlexBox>
            <span style={{ marginLeft: "4.5rem", fontWeight: "500" }}>
              {order.getIn(["sender_data", "address"])}
            </span>
          </FlexBox>

          <FlexBox
            style={{ marginTop: "3.5rem" }}
            justify={JUSTIFY_SPACE_AROUND}
          >
            <FlexBox align={ALIGN_START}>
              <UserAvatar
                name={order.getIn(["recipient_data", "name"])}
                style={{ width: "2rem", height: "2rem" }}
              />
              <span style={{ paddingLeft: "1rem" }}>
                {getLocalisationMessage("recipient", "Sender")}
              </span>
            </FlexBox>
            <span className={postcodeWrap}>
              {getLocalisationMessage("postcode", "Postcode")}:
              {order.getIn(["recipient_data", "postcode", "name"])}
            </span>
          </FlexBox>
          <FlexBox>
            <span
              style={{
                marginLeft: "3rem",
                fontWeight: "500",
                fontSize: "19px",
              }}
            >
              {order.getIn(["recipient_data", "name"])}
            </span>
          </FlexBox>
          <FlexBox>
            <span style={{ marginLeft: "3rem" }}>
              {order.getIn(["recipient_data", "phone"])}
            </span>
          </FlexBox>
          <FlexBox>
            <span style={{ marginLeft: "3rem" }}>
              <CallMade
                fontSize="small"
                style={{
                  color: "#21C004",
                  fontSize: "1rem",
                  transform: "rotate(180deg)",
                  marginRight: ".5rem",
                }}
              />
              {`${order.getIn([
                "recipient_data",
                "jurisdiction",
                "name",
              ])} (${getLocalisationMessage(
                order.getIn(["recipient_data", "jurisdiction", "level_name"]),
              )})`}
            </span>
          </FlexBox>
          <FlexBox>
            <span style={{ marginLeft: "4.5rem", fontWeight: "500" }}>
              {order.getIn(["recipient_data", "address"])}
            </span>
          </FlexBox>
        </Card>
      </FlexBox>
      <FlexBox style={{ width: "21%" }} className={cardWrap}>
        <Card className={card}>
          <FlexBox justify={JUSTIFY_CENTER}>
            <span className={cardHeader}>
              {getLocalisationMessage("order_details_title")}
            </span>
          </FlexBox>
          <FlexBox direction="column">
            <FlexBox>
              <MailOutline />
              <span style={{ marginLeft: ".5rem" }}>
                {getLocalisationMessage("shipment_type", "shipment type")}
              </span>
            </FlexBox>
            <span
              style={{
                fontWeight: "500",
                color: "#FF9800",
                marginLeft: "2rem",
              }}
            >
              {isCustomer
                ? order.getIn(["service_type", "name"])
                : order.getIn(["package_type", "name"])}
            </span>
          </FlexBox>
          {!isCustomer && (
            <FlexBox direction="column">
              <FlexBox>
                <FitnessCenter />
                <span style={{ marginLeft: ".5rem" }}>
                  {getLocalisationMessage(
                    "chargeable_weight",
                    "Chargeable Weight",
                  )}
                </span>
              </FlexBox>
              <span
                style={{
                  fontWeight: "500",
                  color: "rgba(38, 50, 56, 0.7)",
                  marginLeft: "2rem",
                }}
              >
                {order.get("chargeable_weight") * 1000}{" "}
                {getLocalisationMessage("g")}
              </span>
            </FlexBox>
          )}
          <FlexBox direction="column">
            <FlexBox>
              <FitnessCenter />
              <span style={{ marginLeft: ".5rem" }}>
                {getLocalisationMessage("total_weight", "Total weight")}
              </span>
            </FlexBox>
            <span
              style={{
                fontWeight: "500",
                color: "rgba(38, 50, 56, 0.7)",
                marginLeft: "2rem",
              }}
            >
              {order.getIn(["dimensions", "weight"]) * 1000}{" "}
              {getLocalisationMessage("g")}
            </span>
          </FlexBox>
          <FlexBox direction="column">
            <FlexBox>
              <FlightTakeoff />
              <span style={{ marginLeft: ".5rem" }}>
                {getLocalisationMessage(
                  "transportation_type",
                  "Transportation Type",
                )}
              </span>
            </FlexBox>
            <span
              style={{
                fontWeight: "500",
                color: "rgba(38, 50, 56, 0.7)",
                marginLeft: "2rem",
              }}
            >
              {formatTransportationTypesLocalised(
                order.get("transportation_type"),
                getLocalisationMessage,
              )}
            </span>
          </FlexBox>
          <FlexBox direction="column">
            <FlexBox>
              <PhoneAndroid />
              <span style={{ marginLeft: ".5rem" }}>
                {getLocalisationMessage("accepted_through")}
              </span>
            </FlexBox>
            <span
              style={{
                fontWeight: "500",
                color: "rgba(38, 50, 56, 0.7)",
                marginLeft: "2rem",
              }}
            >
              <div style={{ color: "#8bc34a" }}>
                {checkDevice(order, getLocalisationMessage)}
              </div>
            </span>
          </FlexBox>
          <FlexBox direction="column">
            <FlexBox>
              <LocalAtmOutlined />
              <span style={{ marginLeft: ".5rem" }}>
                {getLocalisationMessage("declared_value")}
              </span>
            </FlexBox>
            <span
              style={{
                fontWeight: "500",
                color: "rgba(38, 50, 56, 0.7)",
                marginLeft: "2rem",
              }}
            >
              {`${money(order.get("parcel_value"))} ${getLocalisationMessage(
                "som",
                "so'm",
              )}`}
            </span>
          </FlexBox>
          <FlexBox direction="column">
            <FlexBox>
              <Notes />
              <span style={{ marginLeft: ".5rem" }}>
                {getLocalisationMessage("cds_status", "CDS status")}
              </span>
            </FlexBox>
            <span
              style={{
                fontWeight: "500",
                color: "rgba(38, 50, 56, 0.7)",
                marginLeft: "2rem",
              }}
            >
              {getLocalisationMessage(order.get("asm_pro_status") || "na")}
            </span>
          </FlexBox>
        </Card>
      </FlexBox>
      <FlexBox style={{ width: "46%" }} className={cardWrap}>
        <Card className={card}>
          <FlexBox justify={JUSTIFY_CENTER}>
            <span className={cardHeader}>
              {getLocalisationMessage("payment_info")}
            </span>
          </FlexBox>
          {customChargeItems.map(v => (
            <FlexBox
              style={{ marginBottom: "10px" }}
              justify={JUSTIFY_SPACE_AROUND}
            >
              <span
                style={{
                  width: "35%",
                  fontSize: v.charge_type === COD ? "1.25rem" : "inherit",
                  fontWeight:
                    v.charge_type === COD ||
                    v.charge_type === CUSTOMS_FEE_PERCENTAGE ||
                    v.charge_type === CUSTOMS_FEE_V2
                      ? "bold"
                      : "inherit",
                }}
              >
                {v.charge_type === RTO
                  ? "RTO"
                  : getLocalisationMessage(
                      v.charge_type === SERVICE
                        ? "package"
                        : v.charge_type === RTO_INTERNATI0NAL
                        ? "payment_for_rto"
                        : v.charge_type === CUSTOMS_FEE_PERCENTAGE
                        ? "customs_fee_commission"
                        : v.charge_type === CUSTOMS_FEE_V2
                        ? "customs_fee_amount"
                        : v.charge_type,
                    )}
              </span>
              <span style={{ width: "15%" }}>
                {`${money(v.charge)} ${getLocalisationMessage("som", "so'm")}`}
              </span>
              <span style={{ width: "15%" }}>
                {getLocalisationMessage(v.payment_type || "na")}
              </span>
              {v.paid ? (
                <span
                  style={{
                    backgroundColor: "rgba(33, 192, 4, 0.1)",
                    padding: "0 .5rem",
                    borderRadius: "4px",
                    color: "#21C004",
                    width: "20%",
                    height: "max-content",
                  }}
                >
                  {getLocalisationMessage("paid", "Paid")}
                </span>
              ) : (
                <span
                  style={{
                    backgroundColor: "rgba(255, 152, 0, 0.1)",
                    padding: "0 .5rem",
                    borderRadius: "4px",
                    color: "#FF9800",
                    width: "20%",
                    height: "max-content",
                  }}
                >
                  {getLocalisationMessage("not_paid", "Not paid")}
                </span>
              )}
            </FlexBox>
          ))}
          {addedServices.sum > 0 && (
            <FlexBox
              style={{ marginBottom: "10px" }}
              justify={JUSTIFY_SPACE_AROUND}
            >
              <span style={{ width: "35%" }}>
                {getLocalisationMessage(
                  "additional_services",
                  "Additional Services",
                )}
              </span>
              <span style={{ width: "15%" }}>
                {money(addedServices.sum) +
                  getLocalisationMessage("som", "so'm")}
              </span>
              <span style={{ width: "15%" }}>
                {getLocalisationMessage(addedServices.payment_type)}
              </span>
              {addedServices.paid ? (
                <span
                  style={{
                    backgroundColor: "rgba(33, 192, 4, 0.1)",
                    padding: "0 .5rem",
                    borderRadius: "4px",
                    color: "#21C004",
                    width: "20%",
                    height: "max-content",
                  }}
                >
                  {getLocalisationMessage("paid", "Paid")}
                </span>
              ) : (
                <span
                  style={{
                    backgroundColor: "rgba(255, 152, 0, 0.1)",
                    padding: "0 .5rem",
                    borderRadius: "4px",
                    color: "#FF9800",
                    width: "20%",
                    height: "max-content",
                  }}
                >
                  {getLocalisationMessage("not_paid", "Not paid")}
                </span>
              )}
            </FlexBox>
          )}
          <FlexBox
            style={{ marginTop: "1.5rem" }}
            justify={JUSTIFY_SPACE_AROUND}
          >
            <FlexBox direction="column">
              <FlexBox>
                <LocalAtmOutlined />
                <span style={{ marginLeft: ".5rem" }}>
                  {getLocalisationMessage("payment_type", "payment type")}
                </span>
              </FlexBox>
              <span
                style={{
                  fontWeight: "500",
                  color: "rgba(38, 50, 56, 0.7)",
                  marginLeft: "2rem",
                }}
              >
                {getLocalisationMessage(payType)}
              </span>
            </FlexBox>
            <FlexBox>
              <FlexBox direction="column">
                <span>{getLocalisationMessage("total")}:</span>
                <span>
                  {`${money(
                    order.get("total_charge"),
                  )} ${getLocalisationMessage("som", "so'm")}`}
                </span>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </Card>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(OrderDetailsDialogBriefV2);
