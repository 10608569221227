import React from "react";
import { differenceInDays } from "date-fns";
import { Map, List, OrderedMap } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import OrderListAddress from "./OrderListAddress";
import OrderListTimeSlot from "./OrderListTimeSlot";
import OrderListServiceType from "./OrderListServiceType";
import PaymentTypeAvatarAndInfo from "./PaymentTypeAvatarAndInfo";
import UserAvatar from "../avatars/UserAvatar";
import AvatarWithName from "../avatars/AvatarWithName";
import { BOLD } from "../ui-core/Text";
import LinkButton from "../ui-core/LinkButton";
import PriceWrapper from "../ui-core/PriceWrapper";
import DataList, {
  DataListColumn,
  DataListCheckbox,
} from "../data-list/DataList";
import DateTimeCell from "../data-list/DateTimeCell";
import MultiLineCell from "../data-list/MultiLineCell";
import { isEqualData } from "../../helpers/DataUtils";
import { safeParseDate } from "../../helpers/DateUtils";
import {
  formatNumber,
  formatDateDistanceToNow,
} from "../../helpers/FormatUtils";
import {
  getChargeItem,
  formatCODStatusCode,
  formatCODStatusCodeLocalised,
  formatShippingTypeLocalisation,
  formatOrderStatusCodeForLocalisation,
} from "../../helpers/OrderHelper";
import DataListFilter from "../../helpers/DataListFilter";
import { getUser } from "../../reducers/ProfileReducer";
import { isReverseLogisticEnabled } from "../../reducers/MarketplaceReducer";
import {
  getMessage,
  getCurrentLanguage,
} from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  COD_PENDING,
  COD_COLLECTED,
  COD_TO_BE_COLLECTED,
} from "../../constants/CODStatus";
import { RUSSIAN_LANGUAGE } from "../../constants/LocaleTypes";
import { REVERSE } from "../../constants/LogisticTypes";
import { COD, SERVICE } from "../../constants/OrderChargeItemTypes";
import { ROLE_ADMIN_VIEWER } from "../../../shared/constants/Authorities";
import TextWithLink from "../../../client/components/router/TextWithLink";
import { hasRole } from "../../helpers/RoleUtils";

export const SELECTION_PER_PAGE = "per-page";
export const SELECTION_ALL = "all";

const enhancer = compose(
  connect(
    state => {
      const userRoles = getUser(state).get("roles") || [];

      return {
        getLocalisationMessage: (code, defaultMessage) =>
          getMessage(state, code, defaultMessage),
        isAdminViewer: hasRole(userRoles, ROLE_ADMIN_VIEWER),
        currentLanguage: getCurrentLanguage(state),
        reverseLogisticEnabled: isReverseLogisticEnabled(state),
      };
    },
    { showErrorMessage, showSuccessMessage },
  ),
  useSheet({
    hoverable: { cursor: "pointer", textDecoration: "underline" },
    actionColumn: { paddingLeft: 0, justifyContent: "center" },
  }),
  mapPropsStream(propsStream => {
    const statsStream = propsStream
      .pluck("list")
      .distinctUntilChanged(isEqualData)
      .map(list =>
        Map().withMutations(items => {
          list.forEach(item => {
            const pickup = item.getIn(["locations", 0]);
            const dropoff = item.getIn(["locations", 1]);

            items.updateIn(["pickup", pickup], 0, fp.add(1));
            items.updateIn(["dropoff", dropoff], 0, fp.add(1));
          });

          items.update("pickup", Map(), fp.get("size"));
          items.update("dropoff", Map(), fp.get("size"));
        }),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .distinctUntilChanged(isEqualData)
      .combineLatest(statsStream, (props, stats) => ({
        ...props,
        stats,
      }));
  }),
);

const isOutdated = fp.memoize(
  value => differenceInDays(new Date(), safeParseDate(value)) > 3,
);

const isOrderOutdated = order =>
  (order.get("cod_status") === COD_PENDING ||
    order.get("cod_status") === COD_COLLECTED ||
    order.get("cod_status") === COD_TO_BE_COLLECTED) &&
  isOutdated(order.get("last_cod_status_date"));

const footerContent = (currentLanguage, pickup, dropoff, totalSize) => {
  switch (currentLanguage) {
    case RUSSIAN_LANGUAGE:
      return `Показано заздач: ${totalSize}. Забор посылки: ${pickup}. Доставка: ${dropoff}.`;

    default:
      return `Total ${pickup} Pickup AND ${dropoff} jobs in ${totalSize} Jobs`;
  }
};

OrderList.propTypes = {
  sheet: PropTypes.object,
  stats: PropTypes.instanceOf(Map),
  altHeader: PropTypes.node,
  cardActionIcons: PropTypes.node,
  withCustomer: PropTypes.bool,
  withLastDriver: PropTypes.bool,
  withAttempts: PropTypes.bool,
  withCreatedTime: PropTypes.bool,
  withPickupTime: PropTypes.bool,
  withEstDeliveryTime: PropTypes.bool,
  withCODStatus: PropTypes.bool,
  withFooter: PropTypes.bool,
  createCustomerHref: PropTypes.func,
  createOrderHref: PropTypes.func,
  createWarehouseHref: PropTypes.func,
  createAddressChangedNotificationHref: PropTypes.func,
  onStatusClick: PropTypes.func,
  onEstimatedDeliveryDateClick: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  list: PropTypes.instanceOf(List).isRequired,
  maxSearchItems: PropTypes.number,
  filter: PropTypes.instanceOf(DataListFilter),
  searchTypes: PropTypes.instanceOf(OrderedMap),
  onRowSelect: PropTypes.func,
  selectedItems: PropTypes.instanceOf(OrderedMap),
  onFilterChange: PropTypes.func,
  rowActionIconRenderer: PropTypes.func,
  selectionType: PropTypes.oneOf([SELECTION_ALL, SELECTION_PER_PAGE]),
  withService: PropTypes.bool,
  withLocation: PropTypes.bool,
  withWarehouse: PropTypes.bool,
  withAmount: PropTypes.bool,
  withShipmentNumber: PropTypes.bool,
  withReferenceId: PropTypes.bool,
  withCODAmount: PropTypes.bool,
  withPickupCollectible: PropTypes.bool,
  withDropOffCollectible: PropTypes.bool,
  withPickedUpBy: PropTypes.bool,
  withDeliveredBy: PropTypes.bool,
  chipTextHint: PropTypes.string,
  withMerchantPromiseDate: PropTypes.bool,
  withPieceCount: PropTypes.bool,
  isAdminViewer: PropTypes.bool,
  withTimeslot: PropTypes.bool,
  showPickUpAddress: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
  currentLanguage: PropTypes.string,
  withLogisticType: PropTypes.bool,
  reverseLogisticEnabled: PropTypes.bool,
  enableCheckbox: PropTypes.bool,
};

OrderList.defaultProps = {
  withCustomer: true,
  withLastDriver: true,
  withLocation: true,
  withAttempts: true,
  withAmount: true,
  withCODAmount: false,
  withLogisticType: false,
  withService: true,
  withWarehouse: true,
  withShipmentNumber: true,
  withReferenceId: true,
  withCreatedTime: true,
  withPickupTime: true,
  withEstDeliveryTime: true,
  withCODStatus: true,
  withFooter: true,
  withPieceCount: true,
  withTimeslot: true,
  showPickUpAddress: false,
  createCustomerHref: fp.noop,
  createOrderHref: fp.noop,
  createWarehouseHref: fp.noop,
  createAddressChangedNotificationHref: fp.noop,
  maxSearchItems: Infinity,
  selectionType: SELECTION_PER_PAGE,
  withMerchantPromiseDate: false,
  enableCheckbox: true,
};

function OrderList(props) {
  const {
    sheet: { classes },
    getLocalisationMessage,
    currentLanguage,
  } = props;

  const idNumberMap = props.list
    .toOrderedMap()
    .mapEntries(([, v]) => [v.get("id"), v.get("order_number")]);

  const NA = getLocalisationMessage("na", "N/A");

  return (
    <DataList
      filter={props.filter}
      chipTextHint={props.chipTextHint}
      searchTypes={props.searchTypes}
      maxSearchItems={props.maxSearchItems}
      onFilterChange={props.onFilterChange}
      altHeader={props.altHeader}
      selectedRowCount={props.selectedItems ? props.selectedItems.size : 0}
      cardActionIcons={props.cardActionIcons}
      totalCount={props.totalCount}
      rowCount={props.list.size}
      isLoading={props.isLoading}
      overscanRowCount={10}
      withFooter={props.withFooter}
      rowGetter={options => props.list.get(options.index)}
      footerContent={
        !props.list.isEmpty() &&
        //   <div>
        //     {getLocalisationMessage("total", "Total")}
        //     <strong>
        //       {props.stats.get("pickup")}{" "}
        //       {getLocalisationMessage("pickup_points", "pickup points")}
        //     </strong>{" "}
        //     {getLocalisationMessage("and", "And")}{" "}
        //     <strong>
        //       {props.stats.get("dropoff")}{" "}
        //       {getLocalisationMessage("dropoff_points", "dropoff points")}
        //     </strong>{" "}
        //     {getLocalisationMessage("in", "in")} {props.list.size}{" "}
        //     {getLocalisationMessage("orders", "Orders")}.
        //
        //   </div>
        // )
        footerContent(
          currentLanguage,
          props.stats.get("pickup"),
          props.stats.get("dropoff"),
          props.list.size,
        )
      }
    >
      {props.selectedItems && props.enableCheckbox && (
        <DataListCheckbox
          allRowsSelected={() => {
            if (props.list.size > 0) {
              switch (props.selectionType) {
                case SELECTION_ALL:
                  return props.selectedItems.isSuperset(idNumberMap);
                case SELECTION_PER_PAGE:
                  return props.selectedItems.size === props.list.size;
                // no default
              }
            }

            return false;
          }}
          onAllRowsSelect={value => {
            switch (props.selectionType) {
              case SELECTION_ALL:
                return props.onRowSelect(
                  value
                    ? props.selectedItems.merge(idNumberMap)
                    : props.selectedItems.filter(
                        (n, id) => !idNumberMap.has(id),
                      ),
                );
              case SELECTION_PER_PAGE:
                return props.onRowSelect(value ? idNumberMap : OrderedMap());

              default:
                return null;
            }
          }}
          rowSelected={row => props.selectedItems.has(row.cellData.get("id"))}
          onRowSelect={row => {
            const id = row.cellData.get("id");
            const orderNumber = row.cellData.get("order_number");

            switch (props.selectionType) {
              case SELECTION_ALL:
                return props.onRowSelect(
                  row.selected
                    ? props.selectedItems.set(id, orderNumber)
                    : props.selectedItems.delete(id),
                );

              case SELECTION_PER_PAGE: {
                const selectedItems = row.selected
                  ? props.selectedItems.set(id, orderNumber)
                  : props.selectedItems.delete(id);

                const sortedSelectedItems =
                  selectedItems.size > 1
                    ? idNumberMap.filter((v, k) => selectedItems.has(k))
                    : selectedItems;

                return props.onRowSelect(sortedSelectedItems);
              }

              default:
                return null;
            }
          }}
        />
      )}

      <DataListColumn
        width={150}
        label={getLocalisationMessage(
          "order_shipment_reference",
          "Order Shipment Reference",
        )}
        dataKey="order_number"
        justifyContent="center"
        cellRenderer={row => (
          <MultiLineCell
            firstLine={
              !props.isAdminViewer ? (
                <TextWithLink
                  to={props.createOrderHref(
                    row.cellData.get("id"),
                    row.cellData.get("version"),
                  )}
                >
                  {row.cellData.get("order_number")}
                </TextWithLink>
              ) : (
                <div> {row.cellData.get("order_number")}</div>
              )
            }
            secondLine={
              props.withShipmentNumber
                ? row.cellData.get("shipment_number") ||
                  getLocalisationMessage("na", NA)
                : null
            }
            thirdLine={
              props.withReferenceId
                ? row.cellData.get("reference_id") ||
                  getLocalisationMessage("na", NA)
                : null
            }
          />
        )}
      />

      {props.withCustomer && (
        <DataListColumn
          width={150}
          label={getLocalisationMessage("customer", "Customer")}
          dataKey="customer_name"
          cellRenderer={row =>
            !props.isAdminViewer ? (
              <div>
                <TextWithLink
                  to={props.createCustomerHref(
                    row.cellData.getIn(["customer", "id"]),
                  )}
                  style={{ textDecoration: "none" }}
                >
                  <AvatarWithName
                    name={row.cellData.getIn(["customer", "name"])}
                    classes={classes.avatar}
                    imagePath={row.cellData.getIn(["customer", "photo"])}
                  >
                    {row.cellData.getIn(["customer", "name"])}
                  </AvatarWithName>
                </TextWithLink>
              </div>
            ) : (
              <div>{row.cellData.getIn(["customer", "name"])}</div>
            )
          }
        />
      )}

      {Boolean(props.withLogisticType && props.reverseLogisticEnabled) && (
        <DataListColumn
          width={96}
          disableSort={true}
          label={getLocalisationMessage("shipping_type", "Shipping Type")}
          dataKey="logistic_type"
          justifyContent="center"
          cellRenderer={row =>
            row.cellData.get("logistic_type")
              ? formatShippingTypeLocalisation(
                  row.cellData.get("logistic_type"),
                  getLocalisationMessage,
                )
              : NA
          }
        />
      )}

      {props.withWarehouse && (
        <DataListColumn
          width={96}
          disableSort={true}
          label={getLocalisationMessage("warehouse", "Warehouse")}
          dataKey="warehouse"
          cellRenderer={row =>
            row.cellData.getIn(["warehouse", "id"]) > 0 ? (
              <TextWithLink
                to={props.createWarehouseHref(
                  row.cellData.getIn(["warehouse", "id"]),
                )}
              >
                {row.cellData.getIn(["warehouse", "name"])}
              </TextWithLink>
            ) : (
              getLocalisationMessage("na", NA)
            )
          }
        />
      )}

      <DataListColumn
        width={153}
        label={getLocalisationMessage("courier_or_driver", "Courier / Driver")}
        dataKey="supplier_name"
        cellRenderer={row => (
          <AvatarWithName
            name={
              row.cellData.getIn(["supplier", "name"]) ||
              getLocalisationMessage("na", NA)
            }
            classes={classes.avatar}
            imagePath={row.cellData.getIn(["supplier", "photo"])}
          >
            <MultiLineCell
              firstLine={
                <strong>
                  {row.cellData.getIn(["supplier", "name"]) ||
                    getLocalisationMessage("na", NA)}
                </strong>
              }
              secondLine={row.cellData.getIn(["driver", "name"])}
            />
          </AvatarWithName>
        )}
      />

      {props.withLocation && (
        <DataListColumn
          width={335}
          disableSort={true}
          label={`${
            props.showPickUpAddress
              ? getLocalisationMessage("pickup_address", "Pickup Address")
              : getLocalisationMessage("dropoff_address", "Dropoff Address")
          }`}
          dataKey="from"
          cellRenderer={row => (
            <OrderListAddress
              showPickUpAddress={props.showPickUpAddress}
              order={row.cellData}
              notificationHref={props.createAddressChangedNotificationHref(
                row.cellData.get("id"),
              )}
            />
          )}
        />
      )}

      {props.withService && (
        <DataListColumn
          width={180}
          disableSort={true}
          label={getLocalisationMessage("service", "Service")}
          dataKey="service"
          cellRenderer={row =>
            row.cellData.get("logistic_type") === REVERSE &&
            props.reverseLogisticEnabled ? (
              <OrderListServiceType order={row.cellData} />
            ) : (
              <AvatarWithName
                name={row.cellData.getIn(["package", "delivery_label"]) || NA}
                imagePath={row.cellData.getIn(["package", "courier_type_icon"])}
                classes={classes.avatar}
              >
                <MultiLineCell
                  firstLine={
                    <strong>
                      {row.cellData.getIn(["package", "delivery_label"]) || NA}
                    </strong>
                  }
                  secondLine={row.cellData.getIn(["package", "menu", "name"])}
                />
              </AvatarWithName>
            )
          }
        />
      )}

      {props.withTimeslot && (
        <DataListColumn
          width={180}
          disableSort={true}
          label={getLocalisationMessage(
            "timeslot_pickup_dropoff",
            "Timeslot (Pickup | Dropoff)",
          )}
          dataKey="timeslot"
          cellRenderer={row => <OrderListTimeSlot order={row.cellData} />}
        />
      )}

      {props.withPieceCount && (
        <DataListColumn
          width={96}
          disableSort={true}
          label={getLocalisationMessage("box_count", "Box Count")}
          dataKey="piece_count"
          justifyContent="center"
          cellRenderer={row => (
            <div>
              {`${row.cellData.get(
                "scanned_piece_count",
                0,
              )} / ${row.cellData.get("piece_count", 0)}`}
            </div>
          )}
        />
      )}

      {props.withAttempts && (
        <DataListColumn
          width={96}
          disableSort={true}
          label={getLocalisationMessage("attempts", "Attempts")}
          dataKey="attempts_count"
          justifyContent="center"
          cellRenderer={row => (
            <div style={{ width: "40px", height: "40px" }}>
              <UserAvatar
                size={40}
                name={formatNumber(
                  row.cellData.get("delivery_attempt_count", 0),
                )}
              />
            </div>
          )}
        />
      )}

      {props.withCreatedTime && (
        <DataListColumn
          width={80}
          label={getLocalisationMessage("created_time", "Created Time")}
          dataKey="created_date"
          justifyContent="center"
          cellRenderer={row => (
            <DateTimeCell date={row.cellData.get("created_date")} />
          )}
        />
      )}

      {props.withPickupTime && (
        <DataListColumn
          width={80}
          label={getLocalisationMessage("pickup_time", "Pickup Time")}
          dataKey="pickup_time"
          justifyContent="center"
          cellRenderer={row =>
            row.cellData.get("pickup_time") ? (
              <DateTimeCell date={row.cellData.get("pickup_time")} />
            ) : (
              <div>{getLocalisationMessage("asap", "ASAP")}</div>
            )
          }
        />
      )}

      {props.withEstDeliveryTime && (
        <DataListColumn
          width={80}
          label={getLocalisationMessage("est_delivery", "Est. Delivery")}
          dataKey="deadline_time"
          justifyContent="center"
          cellRenderer={row => (
            <LinkButton
              onClick={
                props.onEstimatedDeliveryDateClick &&
                (() => props.onEstimatedDeliveryDateClick(row.cellData))
              }
            >
              {row.cellData.get("deadline_time") ? (
                <DateTimeCell date={row.cellData.get("deadline_time")} />
              ) : (
                <div>{getLocalisationMessage("na", NA)}</div>
              )}
            </LinkButton>
          )}
        />
      )}

      {props.withMerchantPromiseDate && (
        <DataListColumn
          width={80}
          disableSort={true}
          label={getLocalisationMessage("promise_date", "Promise Date")}
          dataKey="merchant_promise_date"
          justifyContent="center"
          cellRenderer={row =>
            row.cellData.get("merchant_promise_date") ? (
              <DateTimeCell date={row.cellData.get("merchant_promise_date")} />
            ) : (
              getLocalisationMessage("na", NA)
            )
          }
        />
      )}

      {props.withPickedUpBy && (
        <DataListColumn
          width={120}
          disableSort={true}
          label={getLocalisationMessage("picked_up_by", "Picked Up By")}
          dataKey="pickup_supplier_name"
          justifyContent="center"
          cellRenderer={row => (
            <MultiLineCell
              firstLine={
                row.cellData.getIn(["pickup_supplier", "name"]) ||
                getLocalisationMessage("na", NA)
              }
              secondLine={
                row.cellData.getIn(["pickup_driver", "name"]) ||
                getLocalisationMessage("na", NA)
              }
            />
          )}
        />
      )}

      {props.withPickupCollectible && (
        <DataListColumn
          width={120}
          disableSort={true}
          justifyContent="end"
          label={getLocalisationMessage(
            "pickup_total_collectible",
            "Pickup Total Collectible",
          )}
          dataKey="pickup_collectible"
          headerClassName={classes.actionColumn}
          cellRenderer={row => (
            <PriceWrapper price={row.cellData.get("pickup_collectible")} />
          )}
        />
      )}

      {props.withDeliveredBy && (
        <DataListColumn
          width={120}
          disableSort={true}
          label={getLocalisationMessage("delivered_by", "Delivered By")}
          dataKey="delivery_supplier_name"
          justifyContent="center"
          cellRenderer={row => (
            <MultiLineCell
              firstLine={
                row.cellData.getIn(["delivery_supplier", "name"]) ||
                getLocalisationMessage("na", NA)
              }
              secondLine={
                row.cellData.getIn(["delivery_driver", "name"]) ||
                getLocalisationMessage("na", NA)
              }
            />
          )}
        />
      )}

      {props.withDropOffCollectible && (
        <DataListColumn
          width={120}
          disableSort={true}
          justifyContent="end"
          label={getLocalisationMessage(
            "dropoff_total_collectible",
            "Dropoff Total Collectible",
          )}
          dataKey="dropoff_collectible"
          headerClassName={classes.actionColumn}
          cellRenderer={row => (
            <PriceWrapper
              price={row.cellData.get(
                "dropoff_collectible",
                "Dropoff Collectible",
              )}
            />
          )}
        />
      )}

      <DataListColumn
        width={144}
        label={getLocalisationMessage("status", "Status")}
        dataKey="last_modified_date"
        justifyContent="center"
        cellRenderer={row => (
          <MultiLineCell
            firstLine={
              <LinkButton
                onClick={
                  props.onStatusClick &&
                  (() => props.onStatusClick(row.cellData))
                }
              >
                {formatOrderStatusCodeForLocalisation(
                  row.cellData.get("status"),
                  getLocalisationMessage,
                )}
              </LinkButton>
            }
            secondLine={formatDateDistanceToNow(
              row.cellData.get("last_status_date"),
              getLocalisationMessage("na", "N/A"),
              getLocalisationMessage,
            )}
          />
        )}
      />

      {props.withCODAmount && (
        <DataListColumn
          width={80}
          disableSort={true}
          label={getLocalisationMessage("cod", "COD")}
          dataKey="cod_charge"
          justifyContent="center"
          cellRenderer={row =>
            row.cellData.get("cod_charge") || getLocalisationMessage("na", NA)
          }
        />
      )}

      {props.withCODStatus && (
        <DataListColumn
          width={120}
          disableSort={true}
          label={getLocalisationMessage("cod_status")}
          dataKey="cod_status"
          justifyContent="center"
          cellRenderer={row => (
            <MultiLineCell
              firstLine={
                formatCODStatusCodeLocalised(
                  row.cellData.get("cod_status"),
                  getLocalisationMessage,
                  getLocalisationMessage("na", NA),
                ) || formatCODStatusCode(row.cellData.get("cod_status"))
              }
              secondLine={formatDateDistanceToNow(
                row.cellData.get("last_cod_status_date"),
                getLocalisationMessage("na", "N/A"),
                getLocalisationMessage,
              )}
            />
          )}
        />
      )}

      {props.withAmount && (
        <DataListColumn
          width={150}
          dataKey="amount"
          disableSort={true}
          label={getLocalisationMessage(
            "service_price_parcel_price_cod",
            "Service Price, Parcel Price, COD",
          )}
          cellRenderer={row => (
            <PaymentTypeAvatarAndInfo
              paymentType={row.cellData.get("payment_type", "name")}
            >
              <MultiLineCell
                style={{ paddingRight: "10px" }}
                firstLine={
                  <PriceWrapper
                    price={getChargeItem(row.cellData).getIn([
                      SERVICE,
                      "charge",
                    ])}
                    type={isOrderOutdated(row.cellData) ? [BOLD] : [BOLD]}
                  />
                }
                secondLine={
                  <PriceWrapper
                    price={row.cellData.get("parcel_value", 0)}
                    code={row.cellData.getIn(["currency", "code"])}
                  />
                }
                thirdLine={
                  <PriceWrapper
                    price={getChargeItem(row.cellData).getIn([COD, "charge"])}
                    code={row.cellData.getIn(["currency", "code"])}
                  />
                }
              />
            </PaymentTypeAvatarAndInfo>
          )}
        />
      )}

      {props.rowActionIconRenderer && (
        <DataListColumn
          width={61}
          maxWidth={61}
          label={getLocalisationMessage("action", "Action")}
          dataKey="action"
          className={classes.actionColumn}
          headerClassName={classes.actionColumn}
          cellRenderer={props.rowActionIconRenderer}
        />
      )}
    </DataList>
  );
}

export default enhancer(OrderList);
