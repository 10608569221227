import React from "react";
import useSheet from "react-jss";
import { compose, withState, withHandlers } from "recompose";
import PropTypes from "prop-types";
import { Tooltip } from "@material-ui/core";

// const cleanupProps = fp.omit([
//   "show",
//   "sheet",
//   "classes",
//   "style",
//   "label",
//   "children",
// ]);

// Overlay.propTypes = {
//   touch: PropTypes.bool,
//   style: PropTypes.object,
//   show: PropTypes.bool.isRequired,
//   label: PropTypes.string.isRequired,
//   classes: PropTypes.object.isRequired,
// };

// Overlay.defaultProps = { touch: false };

// function Overlay(props) {

//   return (
//     <Tooltip
//         // show={props.show}
//         // touch={props.touch}
//         title={props.label}
//         // className={props.classes.tooltip}
//       > { props.children } </Tooltip>
//   );
// }

const enhancer = compose(
  useSheet({
    tooltip: {
      top: 0,
      // transform: "translate3d(-50%, 0, 0)",
      right: "-40px",
    },
    wrapper: { position: "absolute", zIndex: 3000 },
  }),
  withState("state", "setState", false),
  withHandlers({
    onExit(props) {
      return () => props.setState(false);
    },
    onEnter(props) {
      return () => props.setState(true);
    },
  }),
);

TooltipOverlay.propTypes = {
  state: PropTypes.bool,
  classes: PropTypes.object,
  style: PropTypes.object,
  placement: PropTypes.string,

  touch: PropTypes.bool,
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

TooltipOverlay.defaultProps = { placement: "bottom" };

function TooltipOverlay(props) {
  // const rootProps = cleanupProps(props);

  // return (
  //   <OverlayTrigger
  //     {...rootProps}
  //     placement={props.placement}
  //     overlay={
  //       <Overlay
  //         show={props.state}
  //         touch={props.touch}
  //         label={props.label}
  //         classes={props.classes}
  //       />
  //     }
  //   >
  //     {!React.isValidElement(props.children)
  //       ? props.children
  //       : React.cloneElement(props.children, { style: props.style })}
  //   </OverlayTrigger>
  // );
  return (
    <Tooltip
      // show={props.show}
      // touch={props.touch}
      title={props.label}
      // className={props.classes.tooltip}
    >
      {!React.isValidElement(props.children)
        ? props.children
        : React.cloneElement(props.children, { style: props.style })}
    </Tooltip>
    // <Overlay
    //   // touch={props.touch}
    //   label={props.label}
    //   classes={props.classes}
    // >

    // </Overlay>
  );
}

export default enhancer(TooltipOverlay);
