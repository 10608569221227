import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { TextField as MUITextField } from "@material-ui/core";

export default class TextField extends React.Component {
  static propTypes = {
    value: PropTypes.any,
    error: PropTypes.string,
    active: PropTypes.bool,
    invalid: PropTypes.bool,
    touched: PropTypes.bool,
    visited: PropTypes.bool,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onChange: PropTypes.func,
    parse: PropTypes.func,
    format: PropTypes.func,
    focus: PropTypes.bool,
    rows: PropTypes.number,
    multiLine: PropTypes.bool,
    hintStyle: PropTypes.object,
    errorText: PropTypes.string,
    label: PropTypes.string,
    debounceTime: PropTypes.number,
    debounceChange: PropTypes.bool,
    refInput: PropTypes.func,
  };

  static defaultProps = {
    parse: _.identity,
    format: _.toString,
    debounceTime: 1000,
    debounceChange: false,
  };

  constructor(props, context) {
    super(props, context);

    this.debounceTimeout = null;
    this.debounceTime = props.debounceTime;
    this.debounceChange = props.debounceChange;

    this.state = { rawValue: props.value };
  }

  componentDidMount() {
    this.focus();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.value !== this.props.value ||
      (!this.debounceTimeout && this.state.rawValue !== nextProps.value)
    ) {
      this.cancelDebounce();

      this.setState({ rawValue: nextProps.value });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.focus !== prevProps.focus) {
      this.focus();
    }
  }

  componentWillUnmount() {
    this.cancelDebounce();
  }

  focus() {
    _.delay(() => {
      const input = this.getInput();

      if (this.props.focus && input) {
        input.focus();
      }
    }, 100);
  }

  cancelDebounce() {
    clearTimeout(this.debounceTimeout);

    this.debounceTimeout = null;
  }

  handleChange = event => {
    if (this.debounceChange) {
      this.emitAsyncChange(event);
    } else {
      this.emitSyncChange(event);
    }
  };

  handleKeyPress = event => {
    if (this.debounceChange && event.key === "Enter") {
      this.emitSyncChange(event);
    }
  };

  emitSyncChange(event) {
    this.cancelDebounce();

    if (this.props.onChange && this.props.value !== event.target.value) {
      this.props.onChange(this.props.parse(event.target.value), event);
    }
  }

  emitAsyncChange(event) {
    this.cancelDebounce();

    event.persist();

    this.setState({ rawValue: event.target.value });

    this.debounceTimeout = setTimeout(
      () => this.emitSyncChange(event),
      this.debounceTime,
    );
  }

  getInput() {
    return this.node && this.node.current && this.node.current.getInputNode();
  }

  handleRef = node => {
    this.node = node;

    if (this.props.refInput) {
      this.props.refInput(this.getInput());
    }
  };

  handleBlur = event => {
    this.cancelDebounce();

    if (this.props.onBlur) {
      this.props.onBlur(this.props.parse(event.target.value), event);
    }
  };

  handleFocus = () => {
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  };

  render() {
    const {
      value,
      format,
      error,
      active,
      invalid,
      touched,
      visited,
      errorText,
      rows,
      multiLine,
      hintStyle,
      label,
      ...restProps
    } = this.props;

    const { rawValue } = this.state;

    const visitedOrTouched = this.debounceChange ? touched || visited : touched;

    const errorMessage =
      (visitedOrTouched && !active && invalid && error) || errorText;
    const formattedValue = format(this.debounceChange ? rawValue : value);

    const styles = {
      hint:
        multiLine && rows > 1
          ? {
              top: label ? "36px" : "12px",
              bottom: "auto",
              ...hintStyle,
            }
          : hintStyle,
    };

    const inputProps = _.omit(restProps, [
      "focus",
      "parse",
      "refInput",
      "debounceTime",
      "debounceChange",
      "initialValue",
      "autofill",
      "onUpdate",
      "valid",
      "dirty",
      "pristine",
      "visited",
      "autofilled",
      "hintText",
    ]);

    return (
      <MUITextField
        {...inputProps}
        rows={rows}
        multiline={multiLine}
        placeholder={styles.hint}
        label={label}
        value={formattedValue}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        onChange={this.handleChange}
        onKeyPress={this.handleKeyPress}
        errorText={errorMessage}
        ref={this.handleRef}
      />
    );
  }
}
