import React from "react";
import { Map } from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TEXT_TYPE } from "./Text";
import Price from "./Price";
import {
  getMarketplaceCurrency,
  getMarketplaceDecimalPoint,
} from "../../reducers/MarketplaceReducer";
import { getMessages } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
    marketplaceDecimalPoint: getMarketplaceDecimalPoint(state),
    currency: getMarketplaceCurrency(state),
  })),
);

PriceWrapper.propTypes = {
  element: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  paid: PropTypes.bool,
  code: PropTypes.string, // TODO: `code` property should be required.
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: TEXT_TYPE,
  fallbackValue: PropTypes.node,
  fallbackType: TEXT_TYPE,
  refund: PropTypes.bool,
  withCode: PropTypes.bool,
  marketplaceDecimalPoint: PropTypes.number,
  currency: PropTypes.string,
  i18n: PropTypes.instanceOf(Map),
};

function PriceWrapper({ marketplaceDecimalPoint, currency, ...props }) {
  return (
    <Price
      {...props}
      decimalLength={marketplaceDecimalPoint}
      code={currency}
      style={{ textAlign: "right" }}
    />
  );
}

export default enhancer(PriceWrapper);
