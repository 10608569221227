import React from "react";
import fp from "lodash/fp";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { safeParseDate } from "../../helpers/DateUtils";
import { TimePicker } from "@material-ui/pickers";
import CustomButton, { SECONDARY } from "../ui-core/CustomButton";

export const AMPM_FORMAT = "ampm";
export const FULL_FORMAT = "hh";

TimeFieldComponent.propTypes = {
  meta: PropTypes.object,
  input: PropTypes.object,
  formatTime: PropTypes.oneOf([FULL_FORMAT, AMPM_FORMAT]),
};

export function TimeFieldComponent({ input, meta, formatTime, ...custom }) {
  return (
    <TimePicker
      {...input}
      {...custom}
      onBlur={fp.noop}
      onFocus={fp.noop}
      ampm={formatTime === AMPM_FORMAT}
      variant="dialog"
      openTo={custom.openTo || "seconds"}
      placeholder={custom.hintText}
      // format={formatTime}
      value={safeParseDate(input.value)}
      error={Boolean(meta.touched && meta.error)}
      // onShow={fp.flow(fp.noop, input.onFocus)}
      // onDismiss={fp.flow(fp.noop, input.onBlur)}
      onChange={fp.rest(
        fp.flow(fp.nth(0 /* get second argument */), input.onChange),
      )}
      helperText={meta.touched && meta.error}
      inputVariant={custom.variant}
      size={custom.size}
      cancelLabel={<CustomButton color={SECONDARY}>Cancel</CustomButton>}
      okLabel={<CustomButton color={SECONDARY}>OK</CustomButton>}
    />
  );
}

FormTimeField.propTypes = {
  name: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  hintText: PropTypes.node,
  formatTime: PropTypes.oneOf([FULL_FORMAT, AMPM_FORMAT]),
  openTo: PropTypes.oneOf(["minutes", "hours", "seconds"]),
  label: PropTypes.node,
  variant: PropTypes.string,
  size: PropTypes.string,
};

FormTimeField.defaultProps = {
  formatTime: FULL_FORMAT,
  variant: "outlined",
  size: "small",
};

export default function FormTimeField(props) {
  return <Field {...props} component={TimeFieldComponent} />;
}
