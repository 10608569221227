import sprintf from "sprintf";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "./BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const MARKETPLACE_URL = `${API_ROOT_URL}/marketplace`;

const MARKETPLACE_SETTINGS_URL = `${API_ROOT_URL}/marketplace/%s/settings`;
const MARKETPLACE_CREATE_SETTINGS_URL = `${API_ROOT_URL}/marketplace/settings`;

const MARKETPLACE_ITEM_URL = `${API_ROOT_URL}/marketplace/%s`;
const MARKETPLACES_URL = `${API_ROOT_URL}/admin/marketplaces`;
const MARKETPLACE_CHOOSE_URL = `${API_ROOT_URL}/admin/marketplace/choose`;
const MARKETPLACES_SAAS_URL = `${API_ROOT_URL}/admin/saas_marketplace/choose`;
const MARKETPLACE_UPLOAD_LOGO_URL = `${API_ROOT_URL}/marketplace/%s/upload_logo`;

const SETTINGS_URL = `${API_ROOT_URL}/settings`;
const MARKETPLACE_PUBLIC_SETTINGS_URL = `${API_ROOT_URL}/settings/public`;
const MARKETPLACE_MOBILE_SETTINGS_URL = `${API_ROOT_URL}/settings/public/mobile`;

const SHARED_MARKETPLACES_LIST_URL = `${API_ROOT_URL}/marketplaces`;
const ZONE_URL = `${API_ROOT_URL}/zone`;

const marketplaceCache = new ObservableCache({
  expireAfterWrite: 2,
  keyHasher: fp.method("hashCode"),
  loader: () => api.getStream(MARKETPLACE_URL),
});

const marketplaceItemCache = new ObservableCache({
  expireAfterWrite: 20000,
  loader: id => api.getStream(sprintf(MARKETPLACE_ITEM_URL, id)),
});

export const getMarketplaceCache = id =>
  marketplaceItemCache
    .get(id)
    .takeLast(1)
    .map(fp.flow(fp.get("payload.data"), fromJS));

export const getMarketplace = () => marketplaceCache.get();

const predictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: () => api.getStream(MARKETPLACES_URL),
});

const marketplacePredictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: request =>
    api.getStream(MARKETPLACE_CHOOSE_URL, {
      params: request.getDefinedValues(),
    }),
});

export const getMarketplacePredictions = (request: DataListFilter) =>
  marketplacePredictionsCache.get(request);

const parseResponse = fp.flow(fp.get("data"), fp.toPlainObject, fromJS);

export const getMarketplaces = () => predictionsCache.get();
export const getSaaSMarketplaces = () => api.getStream(MARKETPLACES_SAAS_URL);

const saasMarketplaceCache = new ObservableCache({
  loader: multiMarketplace =>
    api
      .get(MARKETPLACES_SAAS_URL, {
        params: { multi_marketplace: multiMarketplace },
      })
      .then(parseResponse),
});

export const getSaaSMarketplacesList = (multiMarketplace = true) =>
  saasMarketplaceCache.get(multiMarketplace);

// Shared Marketplaces API
export const getMarketplacesList = (request: DataListFilter) =>
  api.getStream(SHARED_MARKETPLACES_LIST_URL, {
    params: request.getDefinedValues(),
    headers: {
      "x-app-type": "customer-web",
    },
  });

export const getMarketplacesItem = (marketplaceId: Number) =>
  api.getStream(sprintf(MARKETPLACE_ITEM_URL, marketplaceId), {
    headers: {
      "x-app-type": "customer-web",
    },
  });

export const getZones = (countryCode: string) =>
  api.getStream(ZONE_URL, {
    params: {
      country_code: countryCode,
    },
    headers: {
      "x-app-type": "customer-web",
    },
  });

type CreateMarketplaceRequest = {
  country: {
    description: string,
    id: number,
    name: string,
    lat: number,
    lon: number,
  },
  currency: {
    code: string,
    name: string,
  },
  default_user: {
    email: string,
    first_name: string,
    last_name: string,
    last_name: string,
    login: string,
    password: string,
  },
  id: number,
  name: string,
  zone: {
    description: string,
    id: number,
    name: string,
  },
};

export const createMarketplace = (body: CreateMarketplaceRequest) =>
  api.post(MARKETPLACE_URL, { body });

export const updateMarketplace = (body: CreateMarketplaceRequest) =>
  api.put(MARKETPLACE_URL, { body });

export const getCachedSettings = () =>
  marketplaceCache
    .get()
    .takeLast(1)
    .map(fp.flow(fp.get("payload.data"), fromJS));

export const getSettings = () =>
  api.getStream(SETTINGS_URL, {
    headers: {
      "x-app-type": "customer-web",
    },
  });
export const getPublicSettings = () =>
  api.getStream(MARKETPLACE_PUBLIC_SETTINGS_URL, {
    headers: {
      "x-app-type": "customer-web",
    },
  });
export const getMobileSettings = () =>
  api.getStream(MARKETPLACE_MOBILE_SETTINGS_URL, {
    headers: {
      "x-app-type": "customer-web",
    },
  });

export const createMarketplaceSettings = body =>
  api.post(MARKETPLACE_CREATE_SETTINGS_URL, { body });

export const updateMarketplaceSettings = body =>
  api.put(MARKETPLACE_CREATE_SETTINGS_URL, { body });

export const getMarketplaceSettings = (id: Number) =>
  api.getStream(sprintf(MARKETPLACE_SETTINGS_URL, id), {
    headers: {
      "x-app-type": "customer-web",
    },
  });

export const uploadMarketplaceLogo = (id: Number, attachments) =>
  api.postStream(sprintf(MARKETPLACE_UPLOAD_LOGO_URL, id), {
    attachments,
    headers: {
      "x-app-type": "customer-web",
    },
  });
