import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import { getIsRTL } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    isRTL: getIsRTL(state),
  })),
  withTheme,
  useSheet({
    root: {
      display: "flex",
      flex: "1 1 0%",
      listStyle: "none",
      marginBottom: 0,
      paddingLeft: ({ isRTL }) => (!isRTL ? "12px" : "0"),
      paddingRight: ({ isRTL }) => (isRTL ? "12px" : "0"),
      borderWidth: "3px",
      borderColor: props => props.theme.palette.primary1Color,
    },
    vertical: {
      paddingTop: "18px",
      marginTop: "-12px",
      marginLeft: ({ isRTL }) => (!isRTL ? "96px" : "0"),
      marginRight: ({ isRTL }) => (isRTL ? "96px" : "0"),
      flexDirection: "column",
      borderLeftStyle: ({ isRTL }) => (!isRTL ? "solid" : "0"),
      borderRightStyle: ({ isRTL }) => (isRTL ? "solid" : "0"),
    },
    horizontal: {
      marginTop: 0,
      flexDirection: "row",
      borderTopStyle: "solid",
      justifyContent: "space-between",
    },
  }),
);

Timeline.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  horizontal: PropTypes.bool,
  isRTL: PropTypes.bool,
};

function Timeline(props) {
  const { classes } = props;

  return (
    <ul
      className={cx(classes.root, {
        [classes.vertical]: !props.horizontal,
        [classes.horizontal]: props.horizontal,
      })}
    >
      {props.children}
    </ul>
  );
}

export default enhancer(Timeline);
