import React from "react";
import _ from "lodash";
import { Collection } from "immutable";
import fp from "lodash/fp";
import { pure, compose, mapProps, withHandlers } from "recompose";
import PropTypes from "prop-types";
import { MenuItem, Select as MUISelectField } from "@material-ui/core";
import { getValue, isEqualData } from "../../helpers/DataUtils";

const cleanupProps = fp.omit([
  "getOptionValue",
  "getOptionText",
  "getOptionLabel",
  "unsetOnSame",
  "unsetValue",
  "initialValue",
  "autofill",
  "onUpdate",
  "valid",
  "dirty",
  "pristine",
  "active",
  "visited",
  "autofilled",
]);

const enhancer = compose(
  withHandlers({
    onBlur: props => () => props.onBlur,
    onFocus: props => () => props.onFocus,
    onChange: props => (event, index) =>
      props.onChange(_.get(index, "props.value")),
  }),
  mapProps(props => {
    const {
      error,
      invalid,
      touched,
      errorText,
      options,
      parseOption,
      formatOption,
      formatInput,
      nullable,
      ...restProps
    } = props;
    const nextProps = cleanupProps(restProps);

    nextProps.children = [];
    nextProps.errorText = fp.trim(
      (invalid && touched ? error : null) || errorText,
    );

    let foundValue;

    if (options) {
      if (nullable) {
        nextProps.children.push(<MenuItem key="null" value={null} />);
      }

      options.forEach((item, index) => {
        const text = formatOption(item, index);
        const value = parseOption(item, index);

        if (fp.isUndefined(foundValue) && isEqualData(value, props.value)) {
          foundValue = value;
        }

        nextProps.children.push(
          <MenuItem
            key={index}
            value={parseOption(item, index)}
            label={formatInput ? formatInput(item, index) : text}
          >
            {text}
          </MenuItem>,
        );
      });
    }

    if (!fp.isUndefined(foundValue)) {
      nextProps.value = foundValue;
    }

    return nextProps;
  }),
  pure,
);

const SelectField = enhancer(MUISelectField);

SelectField.propTypes = {
  value: PropTypes.any,
  style: PropTypes.object,
  labelStyle: PropTypes.object,
  error: PropTypes.any,
  invalid: PropTypes.bool,
  touched: PropTypes.bool,
  errorText: PropTypes.node,
  nullable: PropTypes.bool,
  options: PropTypes.instanceOf(Collection),
  parseOption: PropTypes.func,
  formatOption: PropTypes.func,
  formatInput: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
};

SelectField.defaultProps = {
  parseOption: fp.identity,
  formatOption: fp.identity,
  onBlur: fp.noop,
  onFocus: fp.noop,
  onChange: fp.noop,
};

SelectField.getOptionKey = fp.memoize(fp.rearg([1, 0], getValue));

export default SelectField;
