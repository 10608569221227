import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";

const badgeStyle = {
  color: "#000",
  minWidth: "24px",
  fontSize: "12px",
  padding: "0 4px",
  textAlign: "center",
  marginLeft: "8px",
  marginRight: "8px",
  borderRadius: "50px",
  backgroundColor: "#fff",
  display: "inline-block",
};

TabBadge.propTypes = {
  prefix: PropTypes.node,
  suffix: PropTypes.node,
  badge: PropTypes.node,
  label: PropTypes.node.isRequired,
  subLabel: PropTypes.string,
};

export default function TabBadge({ label, badge, prefix, suffix, subLabel }) {
  return (
    <div>
      <span>{label}</span>
      {_.isNil(badge) ? null : (
        <span style={badgeStyle}>
          {prefix}
          {badge}
          {suffix}
        </span>
      )}
      <small style={{ fontSize: 10, display: "block", color: "#3166A4" }}>
        {subLabel}
      </small>
    </div>
  );
}
