import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { grey } from "@material-ui/core/colors";
import FlexBox from "../ui-core/FlexBox";
import TextField from "../deprecated/TextField";
import { pureComponent } from "../../helpers/HOCUtils";
import { formatNumber } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getIsRTL, getLanguage } from "../../reducers/LocalizationReducer";
import { responsive } from "../../../shared/theme/jss-responsive";

const computeMetrics = (filter, totalCount) => {
  if (!filter) {
    return null;
  }

  const total = fp.toLength(totalCount);
  const size = filter.getSize();
  const maxPage = Math.ceil(total / size);

  const page = filter.getPage() + 1;
  const firstItem = (page - 1) * size + 1;
  const lastItem = Math.min(page * size, total);

  return { page, maxPage, size, firstItem, lastItem, total };
};

const enhancer = compose(
  connect(state => {
    const of = getLanguage(state, "of");
    const rowsPerPage = getLanguage(state, "rows_per_page");
    const isRTL = getIsRTL(state);

    return {
      of,
      rowsPerPage,
      isRTL,
    };
  }),
  useSheet({
    root: {
      height: "56px",
      overflow: "hidden",
      paddingLeft: "12px",
      backgroundColor: "#fff",
      borderTop: `1px solid ${grey[300]}`,
      [responsive("$xs or $sm")]: { justifyContent: "flex-end" },
    },
    sizeInput: {
      fontSize: "12px",
      maxWidth: "48px",
      marginLeft: "8px",
      marginRight: "8px",
      overflow: "hidden",
      "& input": { textAlign: "right" },
      "& input::-webkit-inner-spin-button, & input::-webkit-outer-spin-button": {
        margin: 0,
        WebkitAppearance: "none",
      },
    },
    muted: { color: "#000" },
    text: { fontSize: "12px", whiteSpace: "nowrap", padding: "15px" },
    hiddenXS: { [responsive("$xs or $sm")]: { display: "none" } },
    total: { padding: "15px" },
    navButton: {
      transform: props => (props.isRTL ? "rotate(180deg)" : "none"),
    },
  }),
  pureComponent(fp.pick(["filter", "children", "totalCount"])),
);

DataListFooter.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  totalCount: PropTypes.number,
  maxPageSize: PropTypes.number,
  onFilterChange: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(DataListFilter),
  rowsPerPage: PropTypes.string,
  of: PropTypes.string,
  isRTL: PropTypes.bool,
};

function DataListFooter(props) {
  const { filter, classes, rowsPerPage, of } = props;

  const metrics = computeMetrics(filter, props.totalCount);

  return (
    <FlexBox justify="space-between" className={classes.root}>
      <FlexBox align="center" className={cx(classes.muted, classes.hiddenXS)}>
        {props.children}
      </FlexBox>

      {Boolean(metrics) && (
        <FlexBox align="center" className={classes.muted}>
          <span className={cx(classes.text, classes.hiddenXS)}>
            {rowsPerPage}
          </span>

          <TextField
            id="DataListFooter-size"
            type="number"
            value={metrics.size}
            debounceTime={5000}
            debounceChange={true}
            className={classes.sizeInput}
            parse={fp.flow(fp.toFinite, fp.clamp(1, 10000))}
            onBlur={x =>
              props.onFilterChange(filter.setPageSize(x, props.maxPageSize))
            }
            onChange={x =>
              props.onFilterChange(filter.setPageSize(x, props.maxPageSize))
            }
          />

          {metrics.maxPage > 0 && (
            <FlexBox align="center">
              <span className={classes.text}>
                {metrics.firstItem}-{metrics.lastItem} {of}
                <span className={classes.total}>
                  {formatNumber(metrics.total)}
                </span>
              </span>

              <IconButton
                disabled={metrics.page <= 1}
                onClick={() => props.onFilterChange(filter.decrementPage())}
                className={classes.navButton}
              >
                <KeyboardArrowLeft className={classes.muted} />
              </IconButton>

              <IconButton
                disabled={metrics.page >= metrics.maxPage}
                onClick={() => {
                  // console.log("filter", toJS(filter));
                  props.onFilterChange(filter.incrementPage());
                }}
                className={classes.navButton}
              >
                <KeyboardArrowRight className={classes.muted} />
              </IconButton>
            </FlexBox>
          )}
        </FlexBox>
      )}
    </FlexBox>
  );
}

export default enhancer(DataListFooter);
