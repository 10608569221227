import React from "react";
import fp from "lodash/fp";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import Notification from "../notifications/Notification";
import { formatErrorMessage } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const getSizeError = fp.get("response.size");
const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

OrderListErrorNotification.propTypes = {
  error: PropTypes.instanceOf(Error),
  onFixSizeError: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
};

function OrderListErrorNotification(props) {
  const { error, getLocalisationMessage } = props;

  if (!fp.isError(error)) {
    return <Notification open={false} uid="OrderListError" />;
  }

  const sizeError = getSizeError(error);

  if (sizeError) {
    return (
      <Notification
        open={true}
        type="error"
        uid="OrderListError"
        action={
          <Button onClick={props.onFixSizeError}>
            {" "}
            {getLocalisationMessage("reset", "Reset")}{" "}
          </Button>
        }
      >
        Invalid Page Size: {sizeError}
      </Notification>
    );
  }

  return (
    <Notification open={true} type="error" uid="OrderListError">
      {formatErrorMessage(error)}
    </Notification>
  );
}
export default enhancer(OrderListErrorNotification);
