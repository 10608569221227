import React from "react";
import { OrderedMap } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { MenuItem, Select, Switch } from "@material-ui/core";
import { connect } from "react-redux";
import { grey } from "@material-ui/core/colors";
import FlexBox, { ALIGN_CENTER } from "../ui-core/FlexBox";
import ChipTextField from "../deprecated/ChipTextField";
import { isEqualData } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { parseString, stringifyArray } from "../../helpers/SerializeUtils";
import { getIsRTL, getMessage } from "../../reducers/LocalizationReducer";
import { responsive } from "../../../shared/theme/jss-responsive";
import { SECONDARY } from "../form/FormTextField";

const styles = {
  hint: { bottom: "11px" },
  input: { marginBottom: "16px" },
  chipContainer: {
    maxHeight: "56px",
    float: "left",
    overflowX: "hidden",
    overflowY: "auto",
  },
};

const enhancer = compose(
  connect((state) => {
    const searchBy = getMessage(state, "search_by", "Search By");
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);
    const isRTL = getIsRTL(state);
    return {
      searchBy,
      getLocalisationMessage,
      isRTL,
    };
  }),
  useSheet({
    root: {
      minHeight: "64px",
      padding: "0 14px",
      position: "relative",
      backgroundColor: "white",
      borderBottom: `1px solid ${grey[300]}`,
    },

    altHeader: {
      [responsive("$xs or $sm")]: {
        minWidth: "100%",
        borderTop: `1px solid ${grey[300]}`,
      },
    },

    altHeaderLabel: { lineHeight: "64px", whiteSpace: "nowrap" },
    altHeaderContent: { minHeight: "64px" },

    search: { minHeight: "48px" },
    searchType: {
      // marginLeft: 20,
      // marginTop: "-8px",
      marginRight: "14px",
    },
    actionIcons: {
      display: "flex",
      alignItems: "center",
      alignSelf: "center",
      marginRight: "12px",
    },
  }),
  mapPropsStream((propsStream) => {
    const chipsStream = propsStream
      .map((props) => props.filter && props.filter.getSearch())
      .distinctUntilChanged()
      .map(parseString)
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(chipsStream, (props, chips) => ({ ...props, chips }))
      .distinctUntilChanged(isEqualData);
  }),
);

DataListHeader.propTypes = {
  classes: PropTypes.object,
  chips: PropTypes.array,
  actionIcons: PropTypes.node,
  altHeader: PropTypes.node,
  selectedRowCount: PropTypes.number,
  maxSearchItems: PropTypes.number,
  onFilterChange: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
  searchTypes: PropTypes.instanceOf(OrderedMap),
  chipTextHint: PropTypes.string,
  searchBy: PropTypes.string,
  getLocalisationMessage: PropTypes.func.isRequired,
  isRTL: PropTypes.bool,
  withSwitch: PropTypes.bool,
};

DataListHeader.defaultProps = { maxSearchItems: 1, chipTextHint: "Search" };

function DataListHeader({
  actionIcons,
  altHeader,
  chipTextHint,
  chips,
  classes,
  filter,
  getLocalisationMessage,
  isRTL,
  maxSearchItems,
  onFilterChange,
  searchBy,
  searchTypes,
  selectedRowCount,
  withSwitch,
}) {
  const withSearch = Boolean(
    maxSearchItems > 0 && filter && !(altHeader && selectedRowCount > 0),
  );

  return (
    <FlexBox className={classes.root} wrap="reverse" justify="flex-end">
      {withSearch ? (
        <FlexBox className={classes.search} flex={true} align="center">
          {withSwitch && (
            <FlexBox
              align={ALIGN_CENTER}
              style={{ flex: "0 0 auto", marginRight: "1rem" }}
            >
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <span
                style={{ cursor: "pointer" }}
                onClick={() =>
                  onFilterChange(filter.setValue("search_type", "order_number"))
                }
              >
                {getLocalisationMessage("search_by_order_number")}
              </span>
              <Switch
                checked={filter.getValue("search_type") === "order_number"}
                onChange={(_, v) =>
                  v
                    ? onFilterChange(
                        filter.setValue("search_type", "order_number"),
                      )
                    : onFilterChange(filter.setValue("search_type", "all"))
                }
                color={SECONDARY}
                name="checkedB"
              />
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <span
                style={{ cursor: "pointer" }}
                onClick={() =>
                  onFilterChange(filter.setValue("search_type", "all"))
                }
              >
                {getLocalisationMessage("search_everything")}
              </span>
            </FlexBox>
          )}
          {!withSwitch && Boolean(searchTypes) && (
            <Select
              className={classes.searchType}
              // underlineStyle={{ display: "none" }}
              value={filter.getValue("search_type")}
              onChange={(event) =>
                onFilterChange(
                  filter.setValue("search_type", event.target.value),
                )
              }
              disableUnderline={true}
              iconStyle={{
                left: !isRTL ? "auto" : "16px",
                right: isRTL ? "auto" : "16px",
              }}
              labelStyle={{
                paddingLeft: !isRTL ? "24px" : "56px",
                paddingRight: isRTL ? "24px" : "56px",
                textAlign: isRTL ? "right" : "left",
              }}
            >
              {searchTypes
                .map((label, type) => (
                  <MenuItem key={type} value={type}>
                    {" "}
                    {`${searchBy} ${getLocalisationMessage(label)}`}{" "}
                  </MenuItem>
                ))
                .toArray()}
            </Select>
          )}
          <FlexBox style={{ flex: "1 1 auto" }}>
            <ChipTextField
              fullWidth={true}
              addOnBlur={false}
              clearOnBlur={false}
              value={chips}
              disableUnderline={true}
              hintStyle={styles.hint}
              inputStyle={styles.input}
              hintText={
                getLocalisationMessage("search", "Search") || chipTextHint
              }
              maxItems={maxSearchItems}
              chipContainerStyle={styles.chipContainer}
              onChange={(value) =>
                onFilterChange(filter.setSearch(stringifyArray(value)))
              }
            />
          </FlexBox>
        </FlexBox>
      ) : (
        Boolean(altHeader) && (
          <FlexBox
            flex={true}
            wrap={true}
            justify="flex-end"
            className={classes.altHeader}
          >
            {fp.isFinite(selectedRowCount) && (
              <FlexBox flex={true} className={classes.altHeaderLabel}>
                {selectedRowCount}{" "}
                {getLocalisationMessage("items_selected", "item(s) selected")}.
              </FlexBox>
            )}

            <FlexBox className={classes.altHeaderContent} align="center">
              {altHeader}
            </FlexBox>
          </FlexBox>
        )
      )}

      {React.isValidElement(actionIcons)
        ? React.cloneElement(actionIcons, {
            className: cx(actionIcons.className, classes.actionIcons),
          })
        : actionIcons}
    </FlexBox>
  );
}

export default enhancer(DataListHeader);
