import fp from "lodash/fp";
import DataListFilter, { getListFilterSchema } from "./DataListFilter";
import {
  mapSchema,
  queryTypes,
  types,
} from "../../shared/helpers/ObjectMapper";

export const baseFilter = {
  pickup_attempt_counts: queryTypes.floatSeq,
  delivery_attempt_counts: queryTypes.floatSeq,
  exclude_pickup_attempt_counts: queryTypes.floatSeq,
  exclude_delivery_attempt_counts: queryTypes.floatSeq,

  simple: types.boolean,
  sorting: types.boolean,
  include_dw: types.boolean,
  include_exception: types.boolean,
  auto_cancelled: types.boolean,
  requires_attention: types.boolean,
  multi_marketplace: types.boolean,
  scheduled_for_cancellation: types.boolean,

  cod_status: types.string,
  courier_type: types.string,
  payment_type: types.string,
  deliveryAddressType: types.string,
  merchantType: types.string,

  order_size: queryTypes.stringSeq,
  status: queryTypes.stringSeq,
  excludedStatuses: queryTypes.stringSeq,
  courier_types: queryTypes.stringSeq,

  vehicle_id: types.ID,
  company_id: types.ID,
  cashier_id: types.ID,
  contract_owner_id: types.ID,
  sales_id: types.ID,
  last_mile_driver_id: types.ID,
  from_jurisdiction_ids: queryTypes.IDSeq,
  to_jurisdiction_ids: queryTypes.IDSeq,
  excluded_from_jurisdiction_ids: queryTypes.IDSeq,
  excluded_to_jurisdiction_ids: queryTypes.IDSeq,
  operator_id: types.ID,
  source_warehouse_id: types.ID,
  warehouse_ids: types.ID,
  destination_warehouse_id: types.ID,
  marketplaceId: types.ID,

  driver_ids: queryTypes.IDSeq,
  exclude_driver_ids: queryTypes.IDSeq,
  supplier_ids: queryTypes.IDSeq,
  exclude_supplier_ids: queryTypes.IDSeq,

  customer_ids: queryTypes.IDSeq,
  exclude_customer_ids: queryTypes.IDSeq,
  parent_supplier_ids: queryTypes.IDSeq,
  exclude_parent_supplier_ids: queryTypes.IDSeq,
  parcel_heavy_types: queryTypes.stringSeq,
  exclude_parcel_heavy_types: queryTypes.stringSeq,
  pickupTimeslotIds: queryTypes.IDSeq,
  dropoffTimeslotIds: queryTypes.IDSeq,

  from_date_time: queryTypes.dateTime,
  to_date_time: queryTypes.dateTime,
  from_deadline_time: queryTypes.dateTime,
  to_deadline_time: queryTypes.dateTime,
  status_from_date_time: queryTypes.dateTime,
  status_to_date_time: queryTypes.dateTime,
  from_promise_date_time: queryTypes.dateTime,
  to_promise_date_time: queryTypes.dateTime,

  marketplaceIds: types.string,

  types: queryTypes.stringSeq,
  search: queryTypes.stringSeq,

  search_history: types.boolean,
  historyStatuses: queryTypes.stringSeq,

  history_supplier_ids: queryTypes.IDSeq,
  exclude_history_supplier_ids: queryTypes.IDSeq,
  history_parent_supplier_ids: queryTypes.IDSeq,
  exclude_history_parent_supplier_ids: queryTypes.IDSeq,
  history_warehouse_ids: queryTypes.IDSeq,
  exclude_history_warehouse_ids: queryTypes.IDSeq,
  history_driver_ids: queryTypes.IDSeq,
  exclude_history_driver_ids: queryTypes.IDSeq,

  from_country_ids: queryTypes.IDSeq,
  excluded_from_country_ids: queryTypes.IDSeq,
  to_country_ids: queryTypes.IDSeq,
  excluded_to_country_ids: queryTypes.IDSeq,
  from_city_ids: queryTypes.IDSeq,
  excluded_from_city_ids: queryTypes.IDSeq,
  to_city_ids: queryTypes.IDSeq,
  excluded_to_city_ids: queryTypes.IDSeq,

  from_neighborhood_ids: queryTypes.integerSeq,
  excluded_from_neighborhood_ids: queryTypes.integerSeq,
  to_neighborhood_ids: queryTypes.integerSeq,
  excluded_to_neighborhood_ids: queryTypes.integerSeq,

  badAddress: types.boolean,
  tag_ids: queryTypes.IDSeq,
  logistic_type: types.string,
};

export const toOrderFilter = fp.compose(
  DataListFilter.create,
  mapSchema({
    ...getListFilterSchema(),

    ...baseFilter,
  }),
);
