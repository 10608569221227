import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  withState,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DynamicCSVForm from "./DynamicCSVForm";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import ResponseError from "../../helpers/ResponseError";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  getDefaultDynamicFields,
  saveDefaultDynamicFields,
} from "../../api/shared/OrderApi";

const enhancer = compose(
  renderIf("open"),
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  withState("state", "setState", { init: true }),
  mapPropsStream(propsStream => {
    const { stream: onRequestRefreshStream } = createEventHandler();

    const csvFieldsStream = propsStream
      .distinctUntilKeyChanged("state", isEqualData)
      .switchMap(
        props =>
          props.getDynamicFields &&
          props
            .getDynamicFields()
            .repeatWhen(() => onRequestRefreshStream)
            .catch(() => Observable.of({})),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), fp.toArray)),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData)
      .startWith(null)
      .share();

    const defaultValuesStream = propsStream
      .distinctUntilKeyChanged("state", isEqualData)
      .filter(response => response)
      .switchMap(() =>
        getDefaultDynamicFields().catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), fp.toArray)),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData)
      .startWith(Map());

    const initialValuesStream = defaultValuesStream
      .distinctUntilChanged(isEqualData)
      .filter(response => response.get("payload", List()).size > 0)
      .map(response => {
        const columns = new Map().asMutable();
        response
          .get("payload")
          .forEach(column => columns.set(fp.toLower(column), true));
        return {
          columns: columns.asImmutable().toJS(),
        };
      })
      .distinctUntilChanged(isEqualData)
      .startWith(null);

    return propsStream
      .combineLatest(
        csvFieldsStream,
        defaultValuesStream,
        initialValuesStream,
        (props, csvFieldsResponse, defaultValues, initialValues) => ({
          ...props,
          initialValues,
          dynamicFields: csvFieldsResponse && csvFieldsResponse.get("payload"),
          isLoading: csvFieldsResponse && csvFieldsResponse.get("pending"),
          isDefaultValuesLoading: defaultValues.get("pending"),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

DownloadDynamicCSVDialog.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func,
  getDynamicFields: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isDefaultValuesLoading: PropTypes.bool,
  showSuccessMessage: PropTypes.func,
  dynamicFields: PropTypes.instanceOf(List),
  createDownloadCSVHref: PropTypes.func,
  initialValues: PropTypes.object,
  i18n: PropTypes.instanceOf(Map),
};

function DownloadDynamicCSVDialog(props) {
  if (props.isLoading || props.isDefaultValuesLoading) {
    return <PageLoading isLoading={true} />;
  }

  return (
    <DynamicCSVForm
      initialValues={props.initialValues}
      dynamicFields={props.dynamicFields}
      onDismiss={props.onRequestClose}
      createDownloadCSVHref={props.createDownloadCSVHref}
      saveDefaultColumns={columns => {
        Promise.resolve(
          saveDefaultDynamicFields({ columns }).catch(ResponseError.throw),
        )
          .then(response => response)
          .then(() =>
            props.showSuccessMessage(
              props.i18n.get(
                "the_columns_are_succcessfully_saved",
                "The columns are successfully saved",
              ),
            ),
          )
          .catch(() =>
            props.showErrorMessage(
              props.i18n.get(
                "there_is_error_while_saving_columns",
                "Oops, there is error while saving columns",
              ),
            ),
          );
      }}
    />
  );
}

export default enhancer(DownloadDynamicCSVDialog);
