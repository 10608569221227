import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router";
import MultiLineCell from "../data-list/MultiLineCell";
import { getIsRTL, getMessage } from "../../reducers/LocalizationReducer";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import IconPickUp from "./assets/pickup_icon.svg";
import IconDelivery from "./assets/delivery_icon.svg";
import IconInfoOutline from "./assets/ic_info_outline.svg";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
      isRTL: getIsRTL(state),
    };
  }),
  useSheet({
    container: {
      width: "200px",
      display: "table",
      padding: "1px",
      alignItems: "center",
      position: "relative",
    },
    avatar: {
      width: "36px",
      borderRadius: "50%",
      textAlign: "center",
      display: "table-cell",
      textTransform: "uppercase",
      color: "white",
      verticalAlign: "middle",
    },
    nameBox: {
      display: "table-cell",
      verticalAlign: "middle",
      textAlign: props => (!props.isRTL ? "left" : "right"),
      paddingLeft: props => (!props.isRTL ? "0.2em" : "0"),
      paddingRight: props => (props.isRTL ? "0.2em" : "0"),
      fontSize: "1em",
    },
    rescheduled: {
      position: "absolute",
      right: "5px",
      cursor: "pointer",
    },
  }),
);

OrderListTimeSlot.propTypes = {
  classes: PropTypes.object,
  element: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  order: PropTypes.object,
  location: PropTypes.object,
  getLocalisationMessage: PropTypes.func.isRequired,
  isRTL: PropTypes.bool,
};

OrderListTimeSlot.defaultProps = { element: "span" };

function OrderListTimeSlot({ classes, order, ...props }) {
  const NA = props.getLocalisationMessage("na", "N/A");

  return (
    <div>
      <MultiLineCell
        firstLine={
          <div className={classes.container}>
            <div className={classes.avatar}>
              <img
                src={IconDelivery}
                alt="pick_up"
                width={10}
                height={10}
                style={{ objectFit: "contain" }}
              />
              {/* <UserAvatar
                src={IconDelivery}
                name={name}
                alt={name}
                size={10}
                backgroundColor="transparent"
              /> */}
            </div>
            <div className={classes.nameBox}>
              {order.getIn(["pickup_timeslot", "id"])
                ? order.getIn(["pickup_timeslot", "description"])
                : NA}
            </div>
          </div>
        }
        secondLine={
          <div className={classes.container}>
            <div className={classes.avatar}>
              <img
                src={IconPickUp}
                alt="pick_up"
                width={10}
                height={10}
                style={{ objectFit: "contain" }}
              />
              {/* <UserAvatar
                src={IconPickUp}
                name={name}
                alt={name}
                size={10}
                backgroundColor="transparent"
              /> */}
            </div>
            <div className={classes.nameBox}>
              {order.getIn(["delivery_timeslot", "id"])
                ? order.getIn(["delivery_timeslot", "description"])
                : NA}
            </div>
          </div>
        }
      />
      {order.hasIn(["pickup_timeslot_history", "id"]) && (
        <Link
          to={updateQuery(
            props.location,
            fp.flow(fp.set("ts_view", order.get("id")), fp.set("ts_pickup", 0)),
          )}
        >
          <img
            src={IconInfoOutline}
            alt="pick_up"
            width={10}
            height={10}
            className={classes.rescheduled}
            style={{ objectFit: "contain", top: "13px" }}
          />
          {/* <Avatar
            style={{ top: "13px" }}
            size={10}
            backgroundColor="transparent"
            color={danger1}
            src={IconInfoOutline}
            className={classes.rescheduled}
          /> */}
        </Link>
      )}

      {order.hasIn(["delivery_timeslot_history", "id"]) && (
        <Link
          to={updateQuery(
            props.location,
            fp.flow(fp.set("ts_view", order.get("id")), fp.set("ts_pickup", 1)),
          )}
        >
          <img
            src={IconInfoOutline}
            alt="pick_up"
            width={10}
            height={10}
            className={classes.rescheduled}
            style={{ objectFit: "contain", bottom: "13px" }}
          />
          {/* <Avatar
            style={{ bottom: "13px" }}
            size={10}
            backgroundColor="transparent"
            color={danger1}
            src={IconInfoOutline}
            className={classes.rescheduled}
          /> */}
        </Link>
      )}
    </div>
  );
}

export default enhancer(OrderListTimeSlot);
