import React from "react";
import fp from "lodash";
import PropTypes from "prop-types";
import Redirect from "../router/Redirect";
import OverallStatus from "../../constants/OverallStatus";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

CheckOverallStatus.propTypes = { location: PropTypes.object };

export default function CheckOverallStatus(props) {
  return (
    <Redirect
      to={updateQuery(props.location, fp.unset("status"))}
      when={
        !fp.isEmpty(props.location.query.status) &&
        !OverallStatus.has(props.location.query.status)
      }
    />
  );
}
