import sprintf from "sprintf";
import { api } from "./BaseApi";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const DRIVER_LOCATION_URL = `${API_ROOT_URL}/driver/%s/location`;
const DRIVER_RUNSHEET_URL = `${API_ROOT_URL}/driver/dynamic_run_sheet/close/%s`;
const DRIVER_UPLOAD_URL = `${API_ROOT_URL}/drivers/upload_excel`;

export const getDriverLocation = id =>
  api.getStream(sprintf(DRIVER_LOCATION_URL, id));

export const closeDriverDynamicRunSheet = driverId =>
  api.post(sprintf(DRIVER_RUNSHEET_URL, driverId));

export const driverUploadFile = file =>
  api.postStream(DRIVER_UPLOAD_URL, { file });
