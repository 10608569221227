import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import UserAvatar from "./UserAvatar";
import { getIsRTL } from "../../reducers/LocalizationReducer";
import { NotificationsActiveOutlined } from "@material-ui/icons";

const enhancer = compose(
  connect((state) => ({
    isRTL: getIsRTL(state),
  })),
  useSheet({
    container: {
      // width: "200px",
      display: "table",
      paddingTop: "1em",
      paddingBottom: "1em",
      alignItems: "center",
    },
    avatar: {
      width: "36px",
      borderRadius: "50%",
      textAlign: "center",
      display: "table-cell",
      textTransform: "uppercase",
      color: "white",
      verticalAlign: "middle",
    },
    nameBox: {
      paddingLeft: ".5rem",
      display: "table-cell",
      verticalAlign: "middle",
      fontSize: "1em",
    },
  }),
);

AvatarWithName.propTypes = {
  sheet: PropTypes.object,
  imagePath: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.node,
  isNotification: PropTypes.bool,
  withNotification: PropTypes.bool,
};
AvatarWithName.defaultProps = {};

function AvatarWithName({
  sheet: { classes },
  name,
  imagePath,
  children,
  isNotification,
  withNotification,
}) {
  return (
    <div className={classes.container}>
      <div
        style={{
          position: "relative",
        }}
        className={classes.avatar}
      >
        {isNotification ? (
          <NotificationsActiveOutlined color="error" />
        ) : (
          <UserAvatar src={imagePath} name={name} alt={name} size={36} />
        )}
        {withNotification && (
          <NotificationsActiveOutlined
            style={{
              left: "-.5rem",
              bottom: "-.5rem",
              position: "absolute",
            }}
            color="error"
          />
        )}
      </div>
      <div className={classes.nameBox}>{children}</div>
    </div>
  );
}

export default enhancer(AvatarWithName);
