import React from "react";
import fp from "lodash/fp";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NavTabs from "./NavTabs";
import { getMessage } from "../../reducers/LocalizationReducer";
import { ACTIVE, INACTIVE } from "../../constants/OverallStatus";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

OverallStatusTabs.propTypes = {
  location: PropTypes.object,
  statusKey: PropTypes.string,
  getLocalisationMessage: PropTypes.func,
};

OverallStatusTabs.defaultProps = {
  statusKey: "status",
};

function OverallStatusTabs(props) {
  const { getLocalisationMessage } = props;
  const currentTab = updateQuery(props.location, fp.unset("page"));

  return (
    <NavTabs
      width={300}
      value={currentTab}
      tabs={[
        {
          label: getLocalisationMessage("all", "All"),
          value: updateQuery(currentTab, fp.unset(props.statusKey)),
        },
        {
          label: getLocalisationMessage("active", "Active"),
          value: updateQuery(currentTab, fp.set(props.statusKey, ACTIVE)),
        },
        {
          label: getLocalisationMessage("inactive", "Inactive"),
          value: updateQuery(currentTab, fp.set(props.statusKey, INACTIVE)),
        },
      ]}
    />
  );
}

export default enhancer(OverallStatusTabs);
