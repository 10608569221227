import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { Link } from "react-router";
import { Tab as MUITab, Tabs as MUITabs } from "@material-ui/core";

Tabs.propTypes = {
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf(["auto", "inherit"]),
  ]),
  inkBarStyle: PropTypes.object,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func,
  tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
  variant: PropTypes.oneOf(["fullWidth", "scrollable", "standard"]),
};

Tabs.defaultProps = { width: 1200, variant: "fullWidth" };

const getStyles = props => ({
  root: {
    width: "100%",
    minHeight: "54px",
    overflowX: _.isFinite(props.width) ? "auto" : "hidden",
  },
  container: {
    minWidth: _.isFinite(props.width) ? `${props.width}px` : "auto",
    height: "54px",
  },
  inkBarStyle: { height: "4px", marginTop: "-4px", ...props.inkBarStyle },
  tabItemContainerStyle: { color: "red", ...props.tabItemContainerStyle },
  itemStyle: { fontSize: "11px" },
});

export default function Tabs(props) {
  const styles = getStyles(props);
  const tabsProps = _.omit(props, ["tabs", "width"]);

  return (
    <div style={styles.root}>
      <MUITabs
        {...tabsProps}
        style={styles.container}
        // inkBarStyle={styles.inkBarStyle}
        // tabItemContainerStyle={styles.tabItemContainerStyle}
      >
        {_.map(props.tabs, (item, index) => (
          <MUITab
            {...item}
            id={`tab-id-${index}`}
            style={
              item.style
                ? { ...styles.itemStyle, ...item.style }
                : { ...styles.itemStyle }
            }
            key={_.isUndefined(item.key) ? index : item.key}
            component={Link}
            to={item.path}
          />
        ))}
      </MUITabs>
    </div>
  );
}
