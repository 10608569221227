import React, { useState } from "react";
import { List, Set } from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { LocationOn } from "@material-ui/icons";
import Timeline from "../timeline/Timeline";
import TimelineEvent from "../timeline/TimelineEvent";
import { formatText } from "../../helpers/FormatUtils";
import { formatOrderStatusCodeForLocalisation2 } from "../../helpers/OrderHelper";
import { getUser, getUserLanguage } from "../../reducers/ProfileReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  FIRST_PICKUP_ATTEMPT,
  IN_SORTING_FACILITY,
  IN_TRANSIT,
  LOST,
  MISROUTED,
  OUT_FOR_DELIVERY,
  PICKED_UP_AUTO,
  UNASSIGNED,
} from "../../constants/OrderStatusCodes";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { ROLE_ADMIN_VIEWER } from "../../../shared/constants/Authorities";
import { hasRole } from "../../helpers/RoleUtils";
import _ from "lodash";
import { toJS } from "../../helpers/DataUtils";
import { UZBEK_LANGUAGE } from "../../constants/LocaleTypes";
import { IS_DOWNLOADING, IS_PRINTING } from "./AdminPostForm";
import {
  ACCEPT_PDF_URL,
  HANDOVER_PDF_URL,
} from "../../api/v2/admin/AdminOrderSortingApi";
import { getShiftPDF } from "../../helpers/FormUtils";
import { showErrorMessage } from "../../reducers/NotificationsReducer";

const statusesWithWarehouse = Set.of(
  IN_TRANSIT,
  IN_SORTING_FACILITY,
  OUT_FOR_DELIVERY,
);

const GOOGLE_MAPS_URL = "https://www.google.com/maps/preview";
const enhancer = compose(
  connect(
    state => {
      const userRoles = (getUser(state) && getUser(state).get("roles")) || [];
      return {
        userLanguage: getUserLanguage(state),
        getLocalisationMessage: (code, defaultMessage) =>
          getMessage(state, code, defaultMessage),
        isAdminViewer: hasRole(userRoles, ROLE_ADMIN_VIEWER),
      };
    },
    { showErrorMessage },
  ),
);

function OrderActivitiesTimeline(props) {
  const [handoverPDF, setHandoverPDF] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: HANDOVER_PDF_URL,
  });

  const [acceptPDF, setAcceptPDF] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: ACCEPT_PDF_URL,
  });
  if (!props.activities) {
    return null;
  }
  const { getLocalisationMessage } = props;
  return (
    <Timeline>
      {props.activities
        .filter(v => {
          if (props.hideBatchDetails) {
            const statusCode = v.getIn(["status", "code"]);
            const hiddenStatuses = [
              LOST,
              MISROUTED,
              UNASSIGNED,
              PICKED_UP_AUTO,
              FIRST_PICKUP_ATTEMPT,
            ];
            return !hiddenStatuses.find(x => x === statusCode);
          }
          return v;
        })
        .map((item, key) => {
          const note = item.get("note");
          const status = item.get("status");
          const driverId = item.getIn(["driver", "id"]);
          const userName =
            item.getIn(["user", "name"]) || item.getIn(["user", "description"]);
          const lat = item.get("lat");
          const lon = item.get("lon");
          const data = toJS(item.get("data"));
          if (note) {
            return (
              <TimelineEvent
                key={key}
                date={item.get("date")}
                title={
                  <span>
                    {formatText(note.get("category"))}{" "}
                    {getLocalisationMessage(
                      "order_history_note_from",
                      "Note from",
                    )}{" "}
                    <strong>{userName}</strong>
                  </span>
                }
              >
                <div>
                  {note.get("reason") && (
                    <p>
                      {getLocalisationMessage(
                        "order_history_with_reason",
                        "With Reason",
                      )}
                      <strong>{note.get("reason")}</strong>
                    </p>
                  )}

                  <p>{note.get("content")}</p>
                </div>
              </TimelineEvent>
            );
          } else if (data) {
            const keyNames = Object.keys(data);
            return keyNames.map(v => {
              const to = _.get(data[v], "to");
              const toName = _.isString(to)
                ? getLocalisationMessage(to)
                : _.isObject(to)
                ? _.get(to, "name")
                : to;
              const from = _.get(data[v], "from");
              const fromName = _.isString(from)
                ? getLocalisationMessage(from)
                : _.isObject(from)
                ? _.get(from, "name")
                : from;
              if (props.userLanguage === UZBEK_LANGUAGE) {
                return (
                  <TimelineEvent
                    key={v}
                    date={item.get("date")}
                    title={
                      <span>
                        <em>
                          {getLocalisationMessage(
                            v === "total"
                              ? "total_amount"
                              : v === "service_type"
                              ? "order_type"
                              : v,
                          )}
                        </em>
                        <span>
                          <strong>
                            {" "}
                            {fromName}
                            {v === "weight"
                              ? "kg"
                              : v === "total"
                              ? getLocalisationMessage("som")
                              : ""}
                          </strong>
                          dan{" "}
                          <strong>
                            {toName}
                            {v === "weight"
                              ? "kg"
                              : v === "total"
                              ? getLocalisationMessage("som")
                              : ""}
                          </strong>
                          ga,{" "}
                          <strong>
                            {item.getIn(["created_by_user", "name"])}{" "}
                          </strong>
                          tomonidan o'zgartirildi
                        </span>
                      </span>
                    }
                  />
                );
              }
              return (
                <TimelineEvent
                  key={v}
                  date={item.get("date")}
                  title={
                    <span>
                      <em>
                        {getLocalisationMessage(
                          v === "total"
                            ? "total_amount"
                            : v === "service_type"
                            ? "shipment_type"
                            : v,
                        )}
                      </em>
                      <span>
                        {" "}
                        {getLocalisationMessage("changed_from")}{" "}
                        <strong>
                          {fromName}
                          {v === "weight"
                            ? getLocalisationMessage("kg")
                            : v === "total"
                            ? getLocalisationMessage("som")
                            : ""}{" "}
                        </strong>
                        {getLocalisationMessage("na_to")}{" "}
                        <strong>
                          {toName}
                          {v === "weight"
                            ? getLocalisationMessage("kg")
                            : v === "total"
                            ? getLocalisationMessage("som")
                            : ""}
                        </strong>
                        . {getLocalisationMessage("changed_to_user")}{" "}
                        <strong>
                          {item.getIn(["created_by_user", "name"])}{" "}
                        </strong>
                      </span>
                    </span>
                  }
                />
              );
            });
          } else if (status) {
            const statusCode = status && status.get("code");
            const userId = item.getIn(["user", "id"]);
            const driverName = item.getIn(["driver", "name"]);
            const warehouseId = item.getIn(["warehouse", "id"]);
            const warehouseName = item.getIn([
              statusCode === IN_TRANSIT ? "next_warehouse" : "warehouse",
              "name",
            ]);
            const tempBatchId = item.getIn(["batch", "id"]);
            const tempBatchShiftId = item.get("order_shift_batch_id");
            const tempBatchType = _.toLower(item.getIn(["batch", "type"]));
            if (props.userLanguage === UZBEK_LANGUAGE) {
              if (statusCode === "EXCEPTION") {
                return (
                  <TimelineEvent
                    key={key}
                    date={item.get("date")}
                    title={
                      <span>
                        <em>{userName} </em>
                        <span>
                          jo'natmaning holatini istisno tariqasida o'zgartirdi
                        </span>
                      </span>
                    }
                  />
                );
              }
              return (
                <TimelineEvent
                  key={key}
                  date={item.get("date")}
                  title={
                    <span>
                      <em>
                        {formatOrderStatusCodeForLocalisation2(
                          statusCode,
                          getLocalisationMessage,
                        )}
                      </em>
                      {Boolean(props.showDriver && driverId) && (
                        <span>
                          {" "}
                          {getLocalisationMessage(
                            "order_history_with",
                            "with",
                          )}{" "}
                          <strong>
                            {props.driverItemUrl && !props.isAdminViewer ? (
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={props.driverItemUrl(driverId)}
                              >
                                {driverName}
                              </a>
                            ) : (
                              driverName
                            )}
                          </strong>
                        </span>
                      )}
                      {Boolean(
                        props.showWarehouse &&
                          warehouseId &&
                          statusesWithWarehouse.has(statusCode),
                      ) && (
                        <span>
                          {" "}
                          {statusCode === IN_TRANSIT &&
                            getLocalisationMessage(
                              "order_history_to_warehouse",
                              "to",
                            )}
                          {statusCode === IN_SORTING_FACILITY &&
                            getLocalisationMessage(
                              "order_history_in_warehouse",
                              "in",
                            )}
                          {statusCode === OUT_FOR_DELIVERY &&
                            getLocalisationMessage(
                              "order_history_from_warehouse",
                              "from",
                            )}{" "}
                          <strong>{warehouseName}</strong>.
                        </span>
                      )}
                      {Boolean(props.showWarehouse && userId) && (
                        <span>
                          {" "}
                          <strong>
                            {lat && lon ? (
                              <span>
                                {userName}

                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={updateQuery(GOOGLE_MAPS_URL, {
                                    q: [lat, lon].join(","),
                                  })}
                                >
                                  <LocationOn
                                    style={{
                                      color: "cornflowerblue",
                                      marginTop: "-12px",
                                    }}
                                  />
                                </a>
                              </span>
                            ) : (
                              userName
                            )}
                          </strong>{" "}
                          tomonidan
                          {statusCode === UNASSIGNED
                            ? " yaratildi"
                            : " o'zgartirildi"}
                        </span>
                      )}
                      {!props.hideBatchDetails && tempBatchId && (
                        <span>
                          {" "}
                          (
                          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                          <strong
                            onClick={() => props.setBatchId(tempBatchId)}
                            style={{ color: "#9c0008", cursor: "pointer" }}
                          >
                            {getLocalisationMessage(tempBatchType)}
                            {" : "}
                            {tempBatchId}
                          </strong>
                          )
                        </span>
                      )}
                      {tempBatchShiftId && (
                        <span>
                          {" "}
                          (
                          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                          <strong
                            onClick={() =>
                              statusCode === "shift_handover"
                                ? getShiftPDF(
                                    handoverPDF,
                                    setHandoverPDF,
                                    IS_PRINTING,
                                    tempBatchShiftId,
                                    props.showErrorMessage,
                                  )
                                : statusCode === "shift_accept"
                                ? getShiftPDF(
                                    acceptPDF,
                                    setAcceptPDF,
                                    IS_PRINTING,
                                    tempBatchShiftId,
                                    props.showErrorMessage,
                                  )
                                : null
                            }
                            style={{
                              color: "#9c0008",
                              cursor: "pointer",
                              height: "10px",
                            }}
                          >
                            {tempBatchShiftId}
                          </strong>
                          )
                        </span>
                      )}
                    </span>
                  }
                />
              );
            }
            if (statusCode === "EXCEPTION") {
              return (
                <TimelineEvent
                  key={key}
                  date={item.get("date")}
                  title={
                    <span>
                      <em>{userName} </em>
                      <span>
                        {getLocalisationMessage("did_order_exception_in")}
                      </span>
                    </span>
                  }
                />
              );
            }
            return (
              <TimelineEvent
                key={key}
                date={item.get("date")}
                title={
                  <span>
                    <em>
                      {formatOrderStatusCodeForLocalisation2(
                        statusCode,
                        getLocalisationMessage,
                      )}
                    </em>
                    {Boolean(props.showDriver && driverId) && (
                      <span>
                        {" "}
                        {getLocalisationMessage(
                          "order_history_with",
                          "with",
                        )}{" "}
                        <strong>
                          {props.driverItemUrl && !props.isAdminViewer ? (
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={props.driverItemUrl(driverId)}
                            >
                              {driverName}
                            </a>
                          ) : (
                            driverName
                          )}
                        </strong>
                      </span>
                    )}
                    {Boolean(
                      props.showWarehouse &&
                        warehouseId &&
                        statusesWithWarehouse.has(statusCode),
                    ) && (
                      <span>
                        {" "}
                        {statusCode === IN_TRANSIT &&
                          getLocalisationMessage(
                            "order_history_to_warehouse",
                            "to",
                          )}
                        {statusCode === IN_SORTING_FACILITY &&
                          getLocalisationMessage(
                            "order_history_in_warehouse",
                            "in",
                          )}
                        {statusCode === OUT_FOR_DELIVERY &&
                          getLocalisationMessage(
                            "order_history_from_warehouse",
                            "from",
                          )}{" "}
                        <strong>{warehouseName}</strong>
                      </span>
                    )}
                    {Boolean(props.showWarehouse && userId) && (
                      <span>
                        {" "}
                        {getLocalisationMessage("order_history_by", "by")}{" "}
                        <strong>
                          {lat && lon ? (
                            <span>
                              {userName}

                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={updateQuery(GOOGLE_MAPS_URL, {
                                  q: [lat, lon].join(","),
                                })}
                              >
                                <LocationOn
                                  style={{
                                    color: "cornflowerblue",
                                    marginTop: "-12px",
                                  }}
                                />
                              </a>
                            </span>
                          ) : (
                            userName
                          )}
                        </strong>
                      </span>
                    )}
                    {tempBatchShiftId && (
                      <span>
                        {" "}
                        (
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                        <strong
                          onClick={() =>
                            statusCode === "shift_handover"
                              ? getShiftPDF(
                                  handoverPDF,
                                  setHandoverPDF,
                                  IS_PRINTING,
                                  tempBatchShiftId,
                                  props.showErrorMessage,
                                )
                              : statusCode === "shift_accept"
                              ? getShiftPDF(
                                  acceptPDF,
                                  setAcceptPDF,
                                  IS_PRINTING,
                                  tempBatchShiftId,
                                  props.showErrorMessage,
                                )
                              : null
                          }
                          style={{ color: "#9c0008", cursor: "pointer" }}
                        >
                          {tempBatchShiftId}
                        </strong>
                        )
                      </span>
                    )}
                    {!props.hideBatchDetails && tempBatchId && (
                      <span>
                        {" "}
                        (
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                        <strong
                          onClick={() => props.setBatchId(tempBatchId)}
                          style={{ color: "#9c0008", cursor: "pointer" }}
                        >
                          {getLocalisationMessage(tempBatchType)}
                          {" : "}
                          {tempBatchId}
                        </strong>
                        )
                      </span>
                    )}
                  </span>
                }
              />
            );
          }
          return null;
        })
        .toArray()}
    </Timeline>
  );
}

OrderActivitiesTimeline.propTypes = {
  showDriver: PropTypes.bool,
  showWarehouse: PropTypes.bool,
  driverItemUrl: PropTypes.func,
  hideBatchDetails: PropTypes.bool,
  isAdminViewer: PropTypes.bool,
  activities: PropTypes.instanceOf(List),
  getLocalisationMessage: PropTypes.func,
  userLanguage: PropTypes.string,
  setBatchId: PropTypes.func,
  showErrorMessage: PropTypes.func,
};

OrderActivitiesTimeline.defaultProps = {
  showDriver: false,
  showSupplier: false,
  showWarehouse: false,
  showCallLogUrl: true,
  userLanguage: UZBEK_LANGUAGE,
};

export default enhancer(OrderActivitiesTimeline);
