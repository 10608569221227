import React from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import PropTypes from "prop-types";
import Text, { ACTUAL, DANGER, SUCCESS, TEXT_TYPE } from "./Text";
import ExtendElement from "./ExtendElement";
import { formatNumber, formatPriceRegex } from "../../helpers/FormatUtils";

Price.propTypes = {
  element: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  paid: PropTypes.bool,
  code: PropTypes.string, // TODO: `code` property should be required.
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: TEXT_TYPE,
  fallbackValue: PropTypes.node,
  fallbackType: TEXT_TYPE,
  refund: PropTypes.bool,
  withCode: PropTypes.bool,
  decimalLength: PropTypes.number,
  dispatch: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),
};

Price.defaultProps = {
  element: "span",
  code: "AED",
  decimalLength: 2,
  withCode: true,
};

const formatPrice = (price, code, length, withCode) => {
  const regexFormat = formatPriceRegex(length);
  return withCode
    ? formatNumber(price, `${regexFormat} ${code}`)
    : formatNumber(price, `${regexFormat}`);
};

export default function Price({
  price,
  code,
  paid,
  fallbackValue,
  type,
  refund,
  fallbackType,
  decimalLength,
  dispatch,
  withCode,
  i18n,
  ...props
}) {
  return (
    <ExtendElement {...props}>
      {Boolean(price > 0 && fp.isBoolean(paid)) && (
        <span>
          {" "}
          {paid ? (
            <Text type={SUCCESS} style={{ fontSize: 11 }}>
              {i18n ? i18n.get("paid", "Paid") : "Paid"}
            </Text>
          ) : refund ? (
            <Text type={ACTUAL} style={{ fontSize: 11 }}>
              {i18n ? i18n.get("refunded", "Refunded") : "Refunded"}
            </Text>
          ) : (
            <Text type={DANGER} style={{ fontSize: 11 }}>
              {i18n ? i18n.get("not_paid", "Not Paid") : "Not Paid"}
            </Text>
          )}
        </span>
      )}
      <Text
        type={type}
        fallbackValue={fallbackValue}
        fallbackType={fallbackType}
        style={{ marginLeft: 10 }}
      >
        {formatPrice(price, code, decimalLength, withCode)}
      </Text>
    </ExtendElement>
  );
}
