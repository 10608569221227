import sprintf from "sprintf";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const GET_COMMUNICATION_EVENTS_URL = `${API_ROOT_URL}/events`;
const GET_ADMIN_COMMUNICATION_EVENTS_URL = `${API_ROOT_URL}/admin/events`;
const GET_CUSTOMER_COMMUNICATION_EVENTS_URL = `${API_ROOT_URL}/customer/events`;
const GET_SUPPLIER_COMMUNICATION_EVENTS_URL = `${API_ROOT_URL}/supplier/events`;

const GET_COMMUNICATION_EVENT_TARGETS_URL = `${API_ROOT_URL}/event/targets`;
const GET_COMMUNICATION_EVENT_ACTIONS_URL = `${API_ROOT_URL}/event/actions`;
const GET_COMMUNICATION_EVENT_TYPES_URL = `${API_ROOT_URL}/event/types`;
const GET_COMMUNICATION_MESSAGE_ATTRIBUTES_URL = `${API_ROOT_URL}/event/attributes`;
const DATE_DECLARED_METHODS_URL = `${API_ROOT_URL}/event/date_declared_methods`;
const GET_COMMUNICATION_MESSAGE_TYPES_URL = `${API_ROOT_URL}/event/messageTypes`;
const DO_NOT_SENT_EVENT_URL = `${API_ROOT_URL}/do_not_sent_event`;
const DO_NOT_SENT_ALL_EVENTS_URL = `${API_ROOT_URL}/admin/event/%s/set_all_event_do_not_sent`;

const COMMUNICATION_MESSAGE_URL = `${API_ROOT_URL}/event/%s`;

const SAVE_COMMUNICATION_MESSAGE_URL = `${API_ROOT_URL}/event`;
const ADMIN_SAVE_COMMUNICATION_MESSAGE_URL = `${API_ROOT_URL}/admin/event`;
const CUSTOMER_SAVE_COMMUNICATION_MESSAGE_URL = `${API_ROOT_URL}/customer/event`;
const SUPPLIER_SAVE_COMMUNICATION_MESSAGE_URL = `${API_ROOT_URL}/supplier/event`;

const WHATSAPP_TEMPLATE_URL = `${API_ROOT_URL}/whatsapp-template`;

const WHATSAPP_TEMPLATE_ITEM_URL = `${API_ROOT_URL}/whatsapp-template/%s`;

const GET_IS_TEMPLATE_BASED = `${API_ROOT_URL}/is-template_based`;

const mapResponseData = fp.flow(fp.get("data"), fromJS);

const communicationsMetaCache = new ObservableCache({
  expireAfterWrite: 20000,
  loader: () =>
    Promise.all([
      api.get(GET_COMMUNICATION_EVENT_TARGETS_URL),
      api.get(GET_COMMUNICATION_EVENT_ACTIONS_URL),
      api.get(GET_COMMUNICATION_EVENT_TYPES_URL),
      api.get(GET_COMMUNICATION_MESSAGE_ATTRIBUTES_URL),
      api.get(GET_COMMUNICATION_MESSAGE_TYPES_URL),
    ]).then(
      fp.flow(
        fp.map(mapResponseData),
        ([targets, actions, eventTypes, attributes, messageTypes]) => ({
          targets,
          actions,
          eventTypes,
          attributes,
          messageTypes,
        }),
        fromJS,
      ),
    ),
});

const adminDateDeclaredMethodsCache = new ObservableCache({
  expireAfterWrite: 20000,
  loader: () => api.getStream(DATE_DECLARED_METHODS_URL),
});

export const getCachedCommunicationsMeta = () => communicationsMetaCache.get();

export const getDateDeclaredMethods = () =>
  adminDateDeclaredMethodsCache.refresh();

export const getCommunicationEventsList = (request: DataListFilter) =>
  api.getStream(GET_COMMUNICATION_EVENTS_URL, {
    params: request.getDefinedValues(),
  });
export const getAdminCommunicationEventsList = (request: DataListFilter) =>
  api.getStream(GET_ADMIN_COMMUNICATION_EVENTS_URL, {
    params: request.getDefinedValues(),
  });
export const getCustomerCommunicationEventsList = (request: DataListFilter) =>
  api.getStream(GET_CUSTOMER_COMMUNICATION_EVENTS_URL, {
    params: request.getDefinedValues(),
  });
export const getSupplierCommunicationEventsList = (request: DataListFilter) =>
  api.getStream(GET_SUPPLIER_COMMUNICATION_EVENTS_URL, {
    params: request.getDefinedValues(),
  });

export const getCommunicationMessage = (id) =>
  api.getStream(sprintf(COMMUNICATION_MESSAGE_URL, id));
export const deleteCommunicationMessage = (id) =>
  api.delete(sprintf(COMMUNICATION_MESSAGE_URL, id));

export type CommunicationMessageRequest = {
  content: string,
  entity_id: number,
  to_jurisdiction_id: number,
  entity_type: string,
  id: number,
  message_type: string,
  module: string,
  name: string,
  subject: string,
  target: string,
  type: string,
};

export const saveCommunicationMessage = (data: CommunicationMessageRequest) =>
  api.post(SAVE_COMMUNICATION_MESSAGE_URL, { body: data });
export const saveAdminCommunicationMessage = (
  data: CommunicationMessageRequest,
) => api.post(ADMIN_SAVE_COMMUNICATION_MESSAGE_URL, { body: data });
export const saveCustomerCommunicationMessage = (
  data: CommunicationMessageRequest,
) => api.post(CUSTOMER_SAVE_COMMUNICATION_MESSAGE_URL, { body: data });
export const saveSupplierCommunicationMessage = (
  data: CommunicationMessageRequest,
) => api.post(SUPPLIER_SAVE_COMMUNICATION_MESSAGE_URL, { body: data });

export const doNotSentMessage = (data: CommunicationMessageRequest) =>
  api.post(DO_NOT_SENT_EVENT_URL, { body: data });

export const doNotSentAllEvents = (customerId) =>
  api.post(sprintf(DO_NOT_SENT_ALL_EVENTS_URL, customerId));

const predictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: () => api.getStream(WHATSAPP_TEMPLATE_URL),
});

export const getWhatsappTemplate = () => predictionsCache.get();

export const getIsTemplateBased = () => api.getStream(GET_IS_TEMPLATE_BASED);

export const getWhatsappTemplateItem = (id) =>
  api.getStream(sprintf(WHATSAPP_TEMPLATE_ITEM_URL, id));
