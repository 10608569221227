import { Observable } from "rxjs";
import React from "react";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Polyline } from "react-google-map-components";
import { isEqualData } from "../../helpers/DataUtils";
import { getDirectionRoute } from "../../helpers/GoogleMapsHelper";

const enhancer = compose(
  mapPropsStream(propsStream => {
    const pathStream = propsStream
      .map(fp.pick(["origin", "destination", "waypoints", "delay"]))
      .distinctUntilChanged(isEqualData)
      .delayWhen(props => Observable.timer(props.delay))
      .switchMap(props =>
        getDirectionRoute({
          origin: props.origin,
          destination: props.destination,

          waypoints: props.waypoints,
        })
          .map(fp.flow(fp.get(["routes", 0, "overview_path"]), fp.toArray))
          .catch(() => Observable.of(null)),
      );

    return propsStream.combineLatest(pathStream, (props, path) => ({
      ...props,
      path,
    }));
  }),
);

PolylineWrapper.propTypes = {
  path: PropTypes.array,

  strokeColor: PropTypes.string,
  strokeWeight: PropTypes.number,
  strokeOpacity: PropTypes.number,
  delay: PropTypes.number,

  origin: PropTypes.object.isRequired,
  destination: PropTypes.object.isRequired,
  waypoints: PropTypes.arrayOf(PropTypes.object),
};

PolylineWrapper.defaultProps = { strokeOpacity: 0.5, delay: 0 };

function PolylineWrapper(props) {
  return !props.path || !props.path.length ? null : <Polyline {...props} />;
}

export default enhancer(PolylineWrapper);
