import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import FormChipAutoComplete from "./FormChipAutoComplete";
import { isEqualData } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { ACTIVE } from "../../constants/OverallStatus";

export const baseFilter = new DataListFilter({
  page: 0,
  size: 50,
  status: ACTIVE,
});
const getHeaderOptions = headers => headers.map((key, value) => value).toSet();

const enhancer = compose(
  getContext({
    getCachedServiceType: PropTypes.func.isRequired,
    getServiceTypePredictions: PropTypes.func.isRequired,
  }),
  mapPropsStream(propsStream => {
    const dataSourceStream = propsStream
      .distinctUntilChanged(isEqualData)
      .filter(props => props.getServiceTypePredictions)
      .map(props => ({
        ...props,
        filterPayload: baseFilter.setValueMap(props.filterPayload),
      }))
      .switchMap(props =>
        props
          .getServiceTypePredictions(props.filterPayload)
          .map(fp.flow(fp.get("payload.data"), fp.toArray, fromJS))
          .catch(() => Observable.of(List())),
      )
      .distinctUntilChanged(isEqualData)
      .map(v =>
        Map().withMutations(map =>
          v.forEach(item => {
            map.set(item.get("code"), item.get("name"));
          }),
        ),
      )
      .startWith(List());

    return propsStream
      .combineLatest(dataSourceStream, (props, options) => ({
        ...props,
        options,
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

FormCourierTypeChips.propTypes = {
  getServiceTypePredictions: PropTypes.func,
  getCachedServiceType: PropTypes.func,

  fullWidth: PropTypes.bool,
  autoWidth: PropTypes.bool,
  canAutoPosition: PropTypes.bool,

  maxHeight: PropTypes.number,

  openOnFocus: PropTypes.bool,
  clearOnBlur: PropTypes.bool,
  maxItems: PropTypes.number,
  maxSearchResults: PropTypes.number,

  onGetValue: PropTypes.func,

  validate: PropTypes.func,

  hintText: PropTypes.node,
  label: PropTypes.node,
  chipContainer: PropTypes.object,
  options: PropTypes.object,
  filterPayload: PropTypes.object,

  name: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["standard", "outlined", "filled"]),
  size: PropTypes.oneOf(["medium", "small"]),
};
FormCourierTypeChips.defaultPropTypes = {
  filterPayload: {},
  variant: "outlined",
  size: "small",
};

function FormCourierTypeChips({
  getServiceTypePredictions,
  getCachedServiceType,
  options,
  chipContainer,
  ...props
}) {
  return options.size > 0 ? (
    <FormChipAutoComplete
      {...props}
      fullWidth={true}
      options={getHeaderOptions(options)}
      formatOption={code => options.get(code)}
      chipContainerStyle={chipContainer}
    />
  ) : (
    <div />
  );
}

export default enhancer(FormCourierTypeChips);
