import React, { useState } from "react";
import PropTypes from "prop-types";
import { IconButton, Menu } from "@material-ui/core";
import { MoreHoriz, MoreVert } from "@material-ui/icons";

MenuButtonMore.propTypes = {
  desktop: PropTypes.bool,
  color: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
  iconStyle: PropTypes.object,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  isHorizontal: PropTypes.bool,
};

MenuButtonMore.defaultProps = {
  isHorizontal: false,
};

// MenuButtonMore.muiName = IconMenu.muiName;

export default function MenuButtonMore(props) {
  const [anchor, setAnchor] = useState(null);

  const handleClose = () => {
    setAnchor(null);
  };

  return (
    <div>
      <IconButton
        onClick={e => {
          setAnchor(e.currentTarget);
        }}
      >
        {props.isHorizontal ? (
          <MoreHoriz className={props.iconClassName} />
        ) : (
          <MoreVert className={props.iconClassName} />
        )}
      </IconButton>
      <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={handleClose}>
        {props.children}
      </Menu>
    </div>
    // <IconMenu
    //   style={props.style}
    //   desktop={props.desktop}
    //   iconStyle={props.iconStyle}
    //   className={props.className}
    //   anchorOrigin={{ horizontal: "right", vertical: "top" }}
    //   targetOrigin={{ horizontal: "right", vertical: "top" }}
    //   iconButtonElement={

    //   }
    // >
    //   {props.children}
    // </IconMenu>
  );
}
